import React from 'react';
class Iconography extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
        <div class="container-fluid container-p">
    <div class="alert alert-info mb-0 col-12">
        <h1>ICONOGRAFIA</h1>
    </div>
    <div class="alert alert-light mb-0 pt-10 pb-0">
        <div class="alert alert-primary mb-0">
            <h3>Íconos</h3>
            <div class="alert alert-secondary mb-0">
                <div class="alert alert-light mb-0">
                    <img src="Combined Shape.svg" alt="Estrella SVG"/>
                </div>
            </div>
        </div>
    </div>
</div>
    );
  }
}
export default Iconography;