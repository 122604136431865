import React from "react";
class ProgressIndicators extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>PROGRESS INDICATORS</h1>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0" style={{marginBottom: '30px'}}>
                    <h3>Default</h3>
                    <div className="alert alert-texto mb-0" style={{height: '400px'}}>
                        <h4>
                        Un progress indicator es un elemento web que indica al usaurio el status de alguna tarea. Estos también pueden ser meramente ilustrativos como lo son las gráficas o un step by step que le da la pauta al usuario para entender los pasos que debe seguir al completar alguna tarea.
                        </h4>
                        <div className="alert mb-0 p-0">
                            <ul className="steps steps-web">
                                <li className="step step1 step-incomplete step-active">
                                    <span className="step-icon">1</span>
                                    <span className="step-label">
                                        <h5>Verificación de cuentas</h5>
                                        <h6>Un progress indicator es un elemento web que indica al usaurio el status de alguna tarea.</h6>
                                    </span>
                                </li>
                                <li className="step step2 step-incomplete step-inactive">
                                    <span className="step-icon">2</span>
                                    <span className="step-label">
                                        <h5>Verificación de identidad</h5>
                                        <h6>Un progress indicator es un elemento web que indica al usaurio el status de alguna tarea.</h6>
                                    </span>
                                </li>
                                <li className="step step3 step-incomplete step-inactive">
                                    <span className="step-icon">3</span>
                                    <span className="step-label">
                                        <h5>Verificación de las cuentas</h5>
                                        <h6>Un progress indicator es un elemento web que indica al usaurio el status de alguna tarea.</h6>
                                    </span>
                                </li>
                            </ul>
                            <div id="myCarousel" className="carousel car-card-pro mt-10 w-100 steps-mobile slide carousel-multiItems" data-ride="carousel">
                                <div className="carousel-inner" role="listbox">
                                    <ul className="steps">
                                        <div className="carousel-item active">
                                            <div className="col-12 col-md-6  columns mt-30 mt-md-0 text-center">
                                                <li className="step step1 step-m col-12 step-incomplete step-active card-item">
                                                    <span className="step-icon step-icon-m">1</span>
                                                    <span className="step-label step-label-m">
                                                        <h5>Verificación de cuentas</h5>
                                                    </span>
                                                    <span className="step-label step-label-m2">
                                                        <h6>Un progress indicator es un elemento web que indica al usaurio el status de alguna tarea.</h6>
                                                    </span>
                                                </li>
                                            </div> 
                                        </div> 
                                        <div className="carousel-item">
                                            <div className="col-12 col-md-6  columns mt-30 mt-md-0 text-center">
                                                <li className="step step1 step-m col-12 step-incomplete step-inactive card-item">
                                                    <span className="step-icon step-icon-m">2</span>
                                                    <span className="step-label step-label-m">
                                                        <h5>Verificación de identidad</h5>
                                                    </span>
                                                    <span className="step-label step-label-m2">
                                                        <h6>Un progress indicator es un elemento web que indica al usaurio el status de alguna tarea.</h6>
                                                    </span>
                                                </li>
                                            </div> 
                                        </div> 
                                        <div className="carousel-item">
                                            <div className="col-12 col-md-6  columns mt-30 mt-md-0 text-center">
                                                <li className="step step1 step-m col-12 step-incomplete step-inactive card-item">
                                                    <span className="step-icon step-icon-m">3</span>
                                                    <span className="step-label step-label-m">
                                                        <h5>Verificación de las cuentas</h5>
                                                    </span>
                                                    <span className="step-label step-label-m2">
                                                        <h6>Un progress indicator es un elemento web que indica al usaurio el status de alguna tarea.</h6>
                                                    </span>
                                                </li>
                                            </div> 
                                        </div> 
                                    </ul> 
                                </div>
                                <a className="carousel-control-prev w-auto" href="#myCarousel" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next w-auto" href="#myCarousel" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                        <div className="row mobileON" style={{paddingLeft: '15px', paddingRight: '15px', marginLeft: '-80px', marginRight: '-80px'}}>
                            <h5 className="h5-etiqueta2" style={{marginBottom: '-15px'}}>Version Mobile</h5>
                            <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div id="myCarousel" class="carousel car-card-pro mt-10 w-100 steps-mobile slide carousel-multiItems" data-ride="carousel"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;div class="carousel-inner" role="listbox"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ul class="steps"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="carousel-item active"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-12 col-md-6  columns mt-30 mt-md-0 text-center"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li class="step step1 step-m col-12 step-incomplete step-active card-item"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-icon step-icon-m"&gt;1&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-label step-label-m"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;Verificación de cuentas&lt;/h5&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-label step-label-m2"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h6&gt;Texto.&lt;/h6&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt; <br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt; <br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="carousel-item"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-12 col-md-6  columns mt-30 mt-md-0 text-center"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li class="step step1 step-m col-12 step-incomplete step-inactive card-item"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-icon step-icon-m"&gt;2&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-label step-label-m"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;Verificación de identidad&lt;/h5&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-label step-label-m2"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h6&gt;Texto.&lt;/h6&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt; <br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt; <br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="carousel-item"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-12 col-md-6  columns mt-30 mt-md-0 text-center"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li class="step step1 step-m col-12 step-incomplete step-inactive card-item"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-icon step-icon-m"&gt;3&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-label step-label-m"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;Verificación de las cuentas&lt;/h5&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-label step-label-m2"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h6&gt;Texto.&lt;/h6&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt; <br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt; <br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ul&gt; <br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;a class="carousel-control-prev w-auto" href="#myCarousel" role="button" data-slide="prev"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="carousel-control-prev-icon" aria-hidden="true"&gt;&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="sr-only"&gt;Previous&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/a&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;a class="carousel-control-next w-auto" href="#myCarousel" role="button" data-slide="next"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="carousel-control-next-icon" aria-hidden="true"&gt;&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="sr-only"&gt;Next&lt;/span&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/a&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code> 
                            </div> 
                        </div> 
                    </div>
                </div>
                <div className="row mobileOFF" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                    <h5 className="h5-etiqueta2" style={{marginBottom: '-15px'}}>Version Web</h5>
                    <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                        <code>
                            &lt;ul class="steps steps-web"&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&lt;li class="step step1 step-incomplete step-active"&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-icon"&gt;1&lt;/span&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-label"&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;Verificación de cuentas&lt;/h5&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h6&gt;Un progress indicator es un elemento web que indica al usaurio el status de alguna tarea.&lt;/h6&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&lt;li class="step step2 step-incomplete step-inactive"&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-icon"&gt;2&lt;/span&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-label"&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;Verificación de identidad&lt;/h5&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h6&gt;Un progress indicator es un elemento web que indica al usaurio el status de alguna tarea.&lt;/h6&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&lt;li class="step step3 step-incomplete step-inactive"&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-icon"&gt;3&lt;/span&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="step-label"&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;Verificación de las cuentas&lt;/h5&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h6&gt;Un progress indicator es un elemento web que indica al usaurio el status de alguna tarea.&lt;/h6&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                            &lt;/ul&gt;<br/>
                        </code> 
                    </div> 
                </div> 
            </div>
        </div>
    );
  }
}
export default ProgressIndicators;
