import React, {Fragment} from 'react';
import { Redirect } from 'react-router-dom'
import Header from './components/header';
import text from './password.json';
class ProtectedRoute extends React.Component {
    constructor(props){
        super(props);
        this.getPassword = this.getPassword.bind(this);
    }
    getPassword(autenticated){
        let correctPass = 0;
        text.map(function(item, i){
            if(autenticated === item.password){
               correctPass = 1;               
            }
        })
        return correctPass === 1? autenticated : "false"; 
    }
    render() {
        const Component = this.props.component;
        const localStorageVar = localStorage.getItem('token');    
        var autenticated = window.atob( localStorageVar );
        var passwordUser = this.getPassword(autenticated);
        return autenticated === passwordUser? (
            <Fragment>
            <Header />
            <Component />
            </Fragment>
        ) : (
            <Redirect to={{ pathname: '/login' }} />
        );
    }
}

export default ProtectedRoute;

