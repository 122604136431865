import React from "react";
class TablesAndCollapse extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>TABLES AND COLLAPSE</h1>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0">
                    <h3>Teble</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                            Una tabla es un elemento que nos permite la organización de información mediante filas y columnas.
                        </h4>
                        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Basica</h5>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                            <div className="alert alert-view col-12 p-30">
                                <div className="table-container">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <h6>Origen</h6>
                                                </th>
                                                <th scope="col">
                                                    <h6>Destino</h6>
                                                </th>
                                                <th scope="col">
                                                    <h6>Cabina</h6>
                                                </th>
                                                <th scope="col">
                                                    <h6>Cabina</h6>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="support_text">Ciudad de México</td>
                                                <td className="support_text">Guadalajara</td>
                                                <td className="support_text">Premier y turista</td>
                                                <td className="support_text">30%</td>
                                            </tr>
                                            <tr>
                                                <td className="support_text">Ciudad de México</td>
                                                <td className="support_text">Guadalajara</td>
                                                <td className="support_text">Premier y turista</td>
                                                <td className="support_text">30%</td>
                                            </tr>
                                            <tr>
                                                <td className="support_text">Ciudad de México</td>
                                                <td className="support_text">Guadalajara</td>
                                                <td className="support_text">Premier y turista</td>
                                                <td className="support_text">30%</td>
                                            </tr>
                                            <tr>
                                                <td className="support_text">Ciudad de México</td>
                                                <td className="support_text">Guadalajara</td>
                                                <td className="support_text">Premier y turista</td>
                                                <td className="support_text">30%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className="vigencia_promo">Vigencia de la Promoción: Del 13 al 19 de enero de 2020.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="alert alert-view-code col-12 mb-0 p-30" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="table-container"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;table class="table table-striped"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;thead&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;tr&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;th scope="col"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h6&gt;titulo&lt;/h6&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/th&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/tr&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/thead&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;tbody&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;tr&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;td class="support_text"&gt;texto&lt;/td&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;td class="support_text"&gt;texto&lt;/td&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;td class="support_text"&gt;texto&lt;/td&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;td class="support_text"&gt;texto&lt;/td&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/tr&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/tbody&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/table&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;p class="vigencia_promo"&gt;texto promocional.&lt;/p&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>            
                            </div> 
                        </div> 
                    <div className="alert alert-texto mb-0">
                        <div className="alert alert-texto mb-0">
                            <h4>
                                NOTA: Por cuestion de espacio la seccion de codigo del elemento "Basica" fue simplificada.
                            </h4>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3>Collapse</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                        El collapse es un elemento que te permite tener agrupada una gran cantidad de información en un espacio reducido. Es importante diseñarlo como un lelemnto en donde se le indique al usuario que debe hacer clic para desplegar el resto de la información.
                        </h4>
                        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Collapse primario</h5>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                            <div className="alert alert-view col-12 p-30">
                                <div className="accordion" id="accordionExample">
                                    <div className="card card-collapsePrimary">
                                        <div>
                                            <h6 className="mb-0 card-header" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                Collapsible Group Item #1
                                                <i className="fas fa-lg fa-angle-down ml-auto"></i>
                                            </h6>
                                        </div>

                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <hr className="dividerCollapse"/>
                                            <div className="card-body">
                                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="alert alert-view-code col-12 mb-0 p-30" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="accordion" id="accordionExample"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;div class="card card-collapsePrimary"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h6 class="mb-0 card-header" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"&gt; Collapsible Group Item #1<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class="fas fa-lg fa-angle-down ml-auto"&gt;&lt;/i&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/h6&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;hr class="dividerCollapse"/&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-body"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;TEXTO.&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>            
                            </div> 
                        </div>
                        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Collapse secundario</h5>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                            <div className="alert alert-view col-12 p-30">
                                <div className="accordion" id="accordionExample2">
                                    <div className="card card-collapseSecondary">
                                        <div>
                                            <h6 className="mb-0 card-header" data-toggle="collapse" data-target="#collapseOneSecondary" aria-expanded="false" aria-controls="collapseOneSecondary">
                                                Collapsible Group Item #1
                                                <i className="fas fa-lg fa-angle-down ml-auto"></i>
                                            </h6>
                                        </div>

                                        <div id="collapseOneSecondary" className="collapse" aria-labelledby="headingOne1" data-parent="#accordionExample2">
                                            <div className="card-body">
                                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="alert alert-view-code col-12 mb-0 p-30" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="accordion" id="accordionExample2"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;div class="card card-collapseSecondary"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h6 class="mb-0 card-header" data-toggle="collapse" data-target="#collapseOneSecondary" aria-expanded="false" aria-controls="collapseOneSecondary"&gt; Collapsible Group Item #1 <br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class="fas fa-lg fa-angle-down ml-auto"&gt;&lt;/i&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/h6&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div id="collapseOneSecondary" class="collapse" aria-labelledby="headingOne1" data-parent="#accordionExample2"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-body"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;TEXTO.&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>            
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default TablesAndCollapse;
