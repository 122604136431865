import React from "react";
class Buttons extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>BOTONES</h1>
                <div className="alert mb-0">
                    <h4>
                    Es importante que el uso de los botones radique en detonar acciones o redireccionar a información especializada. Los botones pueden contener una etiqueta específica o su variante con un ícono. Dependiendo el lugar en donde se encuentre y el objetivo que cumpla. 
                    </h4>
                </div>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0">
                    <h3>Botones primarios</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                            Los botones primarios son de uso exclusivo para detonar “Call To Actions”. A continuación se especifican las características que deben cumplir los botones de acciones críticas.
                        </h4>
                        <h5 className="h5-etiqueta">Estructura de botón primario</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                <div className="row row-basic">
                                    <button className="btn btn-primary">Lorem ipsum dolor sit am</button>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;button class="btn btn-primary"&gt;Texto&lt;/button&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <h5 className="h5-etiqueta">Estructura de botón primario con icono</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                <div className="row row-basic">
                                    <button className="btn btn-primary">Lorem ipsum dolor sit am<i className="fas fa-arrow-right"/></button>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;button class="btn btn-primary"&gt;Texto&lt;i class="fas fa-arrow-right"/&gt;&lt;/button&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <h5 className="h5-etiqueta">Estructura de botón primario outline</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                <div className="row row-basic">
                                    <button className="btn btn-outline-primary mb-0">Lorem ipsum dolor sit am<i className="fas fa-arrow-right"/></button>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;button class="btn btn-outline-primary"&gt;Texto&lt;i class="fas fa-arrow-right"/&gt;&lt;/button&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <div className="alert alert-subtitulo mb-0">
                            <h3>Estado de botones</h3>
                            <div className="alert alert-texto mb-0">
                                <h5 className="h5-etiqueta">Normal</h5>
                                <div className="row p-30-guia">
                                    <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                        <div className="row row-basic">
                                            <button className="btn btn-primary">Lorem ipsum dolor sit am</button>     
                                        </div>    
                                    </div>
                                    <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;button class="btn btn-primary"&gt;Texto&lt;/button&gt;
                                        </code>                  
                                    </div>                  
                                </div>  
                                <h5 className="h5-etiqueta">Hover</h5>
                                <div className="row p-30-guia">
                                    <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                        <div className="row row-basic">
                                            <button className="btn btn-primary hover" >Lorem ipsum dolor sit am</button>    
                                        </div>    
                                    </div>
                                    <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;button class="btn btn-primary"&gt;Texto&lt;/button&gt;
                                        </code>                  
                                    </div>                  
                                </div>  
                                <h5 className="h5-etiqueta">Desactivado</h5>
                                <div className="row p-30-guia">
                                    <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                        <div className="row row-basic">
                                            <button className="btn btn-primary" disabled>Lorem ipsum dolor sit am</button>  
                                        </div>    
                                    </div>
                                    <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;button class="btn btn-primary" disabled&gt;Texto&lt;/button&gt;
                                        </code>                  
                                    </div>                 
                                </div>                  
                            </div>  
                        </div>
                        <div className="alert alert-subtitulo mb-0">
                            <h3>Botones secundarios</h3>
                            <div className="alert alert-texto mb-0">
                                <h5 className="h5-etiqueta">Normal</h5>
                                <div className="row p-30-guia">
                                    <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                        <div className="row row-basic">
                                            <button className="btn btn-secondary">Lorem ipsum dolor sit am</button> 
                                        </div>    
                                    </div>
                                    <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;button class="btn btn-secondary"&gt;Texto&lt;/button&gt;
                                        </code>                  
                                    </div>                  
                                </div>  
                                <h5 className="h5-etiqueta">Hover</h5>
                                <div className="row p-30-guia">
                                    <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                        <div className="row row-basic">
                                            <button className="btn btn-secondary hover" >Lorem ipsum dolor sit am</button>
                                        </div>    
                                    </div>
                                    <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;button class="btn btn-secondary"&gt;Texto&lt;/button&gt;
                                        </code>                  
                                    </div>                  
                                </div>  
                                <h5 className="h5-etiqueta">Desactivado</h5>
                                <div className="row p-30-guia">
                                    <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                        <div className="row row-basic">
                                            <button className="btn btn-secondary" disabled>Lorem ipsum dolor sit am</button>
                                        </div>    
                                    </div>
                                    <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;button class="btn btn-secondary" disabled&gt;Texto&lt;/button&gt;
                                        </code>                  
                                    </div>                  
                                </div>               
                            </div>  
                        </div>
                        <div className="alert alert-subtitulo mb-0">
                            <h3>Links</h3>
                            <div className="alert alert-texto mb-0">
                            <h4>
                                El uso principal de los links es detonar acciones secundarias. A continuación se enlistan los ejemplos más representativos y sus usos.
                            </h4>
                                <h5 className="h5-etiqueta">Normal</h5>
                                <div className="row p-30-guia">
                                    <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                        <div className="row row-basic">
                                                <a href="#/" className="stretched-link">Link button</a> 
                                        </div>    
                                    </div>
                                    <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;a href="#" class="stretched-link""&gt;Texto&lt;/a&gt;
                                        </code>                  
                                    </div>                  
                                </div>  
                                <h5 className="h5-etiqueta">Normal con icono</h5>
                                <div className="row p-30-guia">
                                    <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                        <div className="row row-basic">
                                                <a href="#/" className="stretched-link">Link button<i className='fas fa-angle-right'></i></a>
                                        </div>    
                                    </div>
                                    <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;a href="#" class="stretched-link""&gt;Texto&lt;i class="fas fa-arrow-right"/&gt;&lt;/a&gt;
                                        </code>                  
                                    </div>                  
                                </div>  
                                <h5 className="h5-etiqueta">Hover con icono</h5>
                                <div className="row p-30-guia">
                                    <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                        <div className="row row-basic">
                                                <a href="#/" className="stretched-link hover">Link button<i className='fas fa-angle-right'></i></a>
                                        </div>    
                                    </div>
                                    <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;a href="#" class="stretched-link""&gt;Texto&lt;i class="fas fa-arrow-right"/&gt;&lt;/a&gt;
                                        </code>                  
                                    </div>                  
                                </div>  
                                <h5 className="h5-etiqueta">Desactivado con icono</h5>
                                <div className="row p-30-guia">
                                    <div className="alert alert-view col-12 col-md-4 mb-0 p-30-guia">
                                        <div className="row row-basic">
                                            <a href="#/" className="stretched-link-disabled">Link button<i className='fas fa-angle-right'></i></a>
                                        </div>    
                                    </div>
                                    <div className="alert alert-view-code col-12 col-md-8 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;a href="#" class="stretched-link-disabled"&gt;Texto&lt;i class="fas fa-arrow-right"/&gt;&lt;/a&gt;
                                        </code>                  
                                    </div>                  
                                </div>                 
                            </div>  
                        </div>
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3>Tabs</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                            El uso de los tabs facilita organizar el contenido mediante la agrupación de información similar dentro de la misma palabra. Esto te permite tener el contenido visualizado sin tener que navegar fuera del sitio.
                        </h4>
                        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Tab Basico</h5>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                            <div className="alert alert-view col-12 p-30-guia">
                                <div className="nav-tabs-wrapper">
                                    <ul className="nav nav-tabs" role="tablist" >
                                        <li className="nav-item col-sm p-0">
                                            <a className="nav-link active" data-toggle="tab" href="#ActiveTab">
                                                <h4 className="h4-nav-link">H4 - Active tab</h4>
                                            </a>
                                        </li>
                                        <hr/>
                                        <li className="nav-item col-sm p-0">
                                            <a className="nav-link" data-toggle="tab" href="#LinkTab">
                                                <h4 className="h4-nav-link">H4 - Link tab</h4>
                                            </a>
                                        </li>
                                        <hr/>
                                        <li className="nav-item col-sm p-0">
                                            <a className="nav-link disabled" data-toggle="tab" href="#DisableTab">
                                                <h4 className="h4-nav-link">H4 - Disable tab</h4>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content border-tab-content">
                                    <div className="tab-pane active " id="ActiveTab">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque convallis ut ac, non, justo at.Laoreet tortor quis interdum accumsan, amet. Diam ultrices blandit semper nibh scelerisque urna. Eget odio elit egestas et nec. Fringilla quis vel, blandit curabitur vitae dignissim in suscipit. Orci convallis tortor egestas urna, lectus consectetur aliquam placerat sed. Lobortis sed habitant ac tristique in condimentum laoreet. Elit magna volutpat consectetur pharetra, viverra egestas. Nulla a vestibulum rhoncus scelerisque vitae et, et felis.
                                        </p>
                                        <div className="row tab-footer">
                                            <div className="col-6 col-md-6"></div>
                                            <div className="col-12 col-md-6">
                                                <div className="row">
                                                    <div className="col-12 col-md-2"></div>
                                                    <a className="btn-cancel-link col-12 col-md-6" href="#/">Cancel link button <i className='fas fa-ban'></i></a>
                                                    <a className="btn-link col-12 col-md-4" href="#/">Link button</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane " id="DisableTab">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque convallis ut ac, non, justo at.
                                            Laoreet tortor quis interdum accumsan, amet. Diam ultrices blandit semper nibh scelerisque urna.
                                            Eget odio elit egestas et nec. Fringilla quis vel, blandit curabitur vitae dignissim in suscipit.
                                            Orci convallis tortor egestas urna, lectus consectetur aliquam placerat sed. Lobortis sed habitant
                                            ac tristique in condimentum laoreet. Elit magna volutpat consectetur pharetra, viverra egestas.
                                            Nulla a vestibulum rhoncus scelerisque vitae et, et felis.
                                        </p>
                                    </div>
                                    <div className="tab-pane " id="LinkTab">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque convallis ut ac, non, justo at.
                                            Laoreet tortor quis interdum accumsan, amet. Diam ultrices blandit semper nibh scelerisque urna.
                                            Eget odio elit egestas et nec. Fringilla quis vel, blandit curabitur vitae dignissim in suscipit.
                                            Orci convallis tortor egestas urna, lectus consectetur aliquam placerat sed. Lobortis sed habitant
                                            ac tristique in condimentum laoreet. Elit magna volutpat consectetur pharetra, viverra egestas.
                                            Nulla a vestibulum rhoncus scelerisque vitae et, et felis.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="nav-tabs-wrapper"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;ul class="nav nav-tabs" role="tablist" &gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li class="nav-item"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a class="nav-link active" data-toggle="tab" href="#ActiveTab"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4 class="h4-nav-link"&gt;H4 - Active tab&lt;/h4&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/a&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li class="nav-item"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a class="nav-link disabled" data-toggle="tab" href="#DisableTab"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4 class="h4-nav-link"&gt;H4 - Disable tab&lt;/h4&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/a&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li class="nav-item"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a class="nav-link" data-toggle="tab" href="#LinkTab"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4 class="h4-nav-link"&gt;H4 - Link tab&lt;/h4&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/a&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/ul&gt;<br/>
                                    &lt;/div&gt;<br/>
                                    &lt;div class="tab-content border-tab-content"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;div class="tab-pane active container" id="ActiveTab"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;texto&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="row tab-footer"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-6 col-md-6"&gt;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-12 col-md-6"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="row"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-12 col-md-2"&gt;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a class="btn-cancel-link col-12 col-md-6"&gt;Cancel link button &lt;i class='fas fa-ban'&gt;&lt;/i&gt;&lt;/a&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a class="btn-link col-12 col-md-4"&gt;Link button&lt;/a&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;div class="tab-pane container" id="DisableTab"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;texto&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;div class="tab-pane container" id="LinkTab"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;texto&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>            
                            </div> 
                        </div> 
                        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Tab con tabla</h5>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                            <div className="alert alert-view col-12 p-30-guia"><div className="nav-tabs-wrapper">
                                    <ul className="nav nav-tabs" role="tablist" >
                                        <li className="nav-item col-sm p-0">
                                            <a className="nav-link active" data-toggle="tab" href="#ActiveTab">
                                                <h4 className="h4-nav-link">H4 - Active tab</h4>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content border-tab-content">
                                    <div className="tab-pane tab-table active" id="ActiveTab">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <h6>Origen</h6>
                                                    </th>
                                                    <th scope="col">
                                                        <h6>Destino</h6>
                                                    </th>
                                                    <th scope="col">
                                                        <h6>Cabina</h6>
                                                    </th>
                                                    <th scope="col">
                                                        <h6>Cabina</h6>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="support_text">Ciudad de México</td>
                                                    <td className="support_text">Guadalajara</td>
                                                    <td className="support_text">Premier y turista</td>
                                                    <td className="support_text">30%</td>
                                                </tr>
                                                <tr>
                                                    <td className="support_text">Ciudad de México</td>
                                                    <td className="support_text">Guadalajara</td>
                                                    <td className="support_text">Premier y turista</td>
                                                    <td className="support_text">30%</td>
                                                </tr>
                                                <tr>
                                                    <td className="support_text">Ciudad de México</td>
                                                    <td className="support_text">Guadalajara</td>
                                                    <td className="support_text">Premier y turista</td>
                                                    <td className="support_text">30%</td>
                                                </tr>
                                                <tr>
                                                    <td className="support_text">Ciudad de México</td>
                                                    <td className="support_text">Guadalajara</td>
                                                    <td className="support_text">Premier y turista</td>
                                                    <td className="support_text">30%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="nav-tabs-wrapper"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;ul class="nav nav-tabs" role="tablist" &gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li class="nav-item"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a class="nav-link active" data-toggle="tab" href="#ActiveTab"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4 class="h4-nav-link"&gt;H4 - Active tab&lt;/h4&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/a&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li class="nav-item"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a class="nav-link disabled" data-toggle="tab" href="#DisableTab"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4 class="h4-nav-link"&gt;H4 - Disable tab&lt;/h4&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/a&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li class="nav-item"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a class="nav-link" data-toggle="tab" href="#LinkTab"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4 class="h4-nav-link"&gt;H4 - Link tab&lt;/h4&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/a&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/ul&gt;<br/>
                                    &lt;/div&gt;<br/>
                                    &lt;div class="tab-content border-tab-content"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;div class="tab-pane active container" id="ActiveTab"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="table-container"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;table class="table table-striped"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;thead&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;tr&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;th scope="col"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h6&gt;titulo&lt;/h6&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/th&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/tr&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/thead&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;tbody&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;tr&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;td class="support_text"&gt;texto&lt;/td&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;td class="support_text"&gt;texto&lt;/td&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;td class="support_text"&gt;texto&lt;/td&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;td class="support_text"&gt;texto&lt;/td&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/tr&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/tbody&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/table&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>            
                            </div> 
                        </div> 
                    </div>
                </div>
                    <div className="alert alert-texto mb-0">
                        <div className="alert alert-texto mb-0">
                            <h4>
                                NOTA: Por cuestion de espacio y logica de programacion la seccion de codigo del elemento "Tab con tabla" fue simplificada.
                            </h4>
                        </div>
                    </div>
            </div>
        </div>
    );
  }
}
export default Buttons;
