import React from 'react';

class EnrollmentForm extends React.Component {

  render() {
    return (    
      <div className="container-fluid py-0 container-enrollment">
        <div className="row d-flex flex-row-reverse">    
          <div className="col-12 col-md-6 bg-gradient container-form" >
            <div className="card col-md-8 mt-60 mb-30">   
              <div className="container-alert pt-15"> 
              </div>
              <form id="cpr-rgs-pda-frm-fcp" data-parsley-validate="" novalidate="" className="enrollment-form pl-30 pr-30 form-corp" action="#">    
                <div className="form-group step-one d-none" data-url="/">
                  <h4 className="card-title text-center p-30 m-0">Representante de la empresa</h4>
                  <p>Ingresa los datos de la persona que representa a tu empresa en el programa Aeroméxico Rewards Business</p>
                  <div id="cpr-rgs-pda-div-gan">
                    <div className="input-group input-basic py-15" >
                      <label>No. de Cuenta Aeroméxico Rewards</label>
                      <input 
                      className="form-control input-account"
                      name="accountNumber"
                      data-parsley-errors-container="#cpr-rgs-pda-div-gan"
                      data-parsley-trigger="input focus"
                      placeholder="123456789"
                
                      required
                      data-parsley-required-message="Campo requerido"
                      minlength="2"
                      maxlength="11"
                      data-parsley-length-message="Máximo 11 dígitos"
                      data-parsley-luhn
                      data-parsley-luhn-message="No. de cuenta inválido"
                      data-parsley-type="digits"
                      data-parsley-type-message="Sólo dígitos"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>      
                    </div>
                  </div>
                  <div id="cpr-rgs-pda-div-brt">
                    <div className="input-group input-basic py-15">
                      <label>Fecha de nacimiento</label>
                      <input 
                      name="birthday"
                      className="form-control input-birthday"
                      data-parsley-errors-container="#cpr-rgs-pda-div-brt"
                      data-parsley-trigger="input focus"
                      placeholder="dd/mm/aaaa"
                      
                      required
                      data-parsley-required-message="Campo requerido"
                      minlength="10"
                      maxlength="10"
                      data-parsley-length-message="Ingresa la fecha con formato dd/mm/aaaa"
                      data-parsley-valid-date=""
                      data-parsley-valid-date-message="No es una fecha válida"
                      data-parsley-not-adult=""
                      data-parsley-not-adult-message="Debes ser mayor de edad"
                      data-parsley-is-future=""
                      data-parsley-is-future-message="Es una fecha futura"
                      autocomplete="off"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>                   
                    </div>
                  </div>
                  <div id="cpr-rgs-pda-div-ecp">
                    <div className="input-group input-basic py-15" >
                      <label>Correo electrónico</label>
                      <input 
                      className="form-control input-email"
                      name="email"
                      type="email"
                      data-parsley-type-message="Correo inválido"
                      placeholder="correo@example.com"
                      data-parsley-errors-container="#cpr-rgs-pda-div-ecp"
                      data-parsley-trigger="input focus"
                      autocomplete="false"
                      required
                      data-parsley-required-message="Campo requerido"
                      />                     
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>
                      <div class="tooltip hint--bottom-left pr-30">
                        <i class="fas fa-question-circle"></i>
                        <div class="bottom">
                          <p>TEXTO.</p>
                          <i></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-30">      
                  <div className="box-btn-after col-12 text-right">
                    <button className="btn btn-primary btn-form-one" id="cpr-rgs-pda-btn-nxt"
                      disabled
                    >
                      Siguiente
                    </button>
                  </div>
                </div>  
                  <div className="box-recover text-left pb-30">
                  <a href="/" id="cpr-rgs-pda-hyp-rcv">Recuperar número de cuenta</a>
                </div>  
                </div>
       
                <div className="step-two d-none">
                  <h4 className="card-title text-center p-30 m-0">Representante de la empresa</h4>
                  <p className="other">Selecciona la opción que mejor se ajuste al perfil de la cuenta que estás por crear</p>
                  <div>                 
                    <div class="custom-control custom-radio text-left">
                      <input type="radio" class="radio-business custom-radio-input" id="cpr-rgs-pda-inp-rbn" name="radioOptionType" value="Empresa"/>
                      <label class="custom-radio-label" for="cpr-rgs-pda-inp-rbn">Soy una Empresa</label>
                    </div>
                    <div id="cpr-rgs-pda-div-ref">
                      <div className="input-group input-basic py-15" >
                        <label>Referencia</label>
                        <input 
                        className="input-account-business form-control"
                        placeholder="Número de Aeroméxico Rewards personal"
                        />    
                      </div>
                    </div>               
                    <div class="custom-control custom-radio text-left">
                      <input type="radio" class="radio-executive custom-radio-input" id="cpr-rgs-pda-inp-rex" name="radioOptionType" value="Ejecutivo"/>
                      <label class="custom-radio-label" for="cpr-rgs-pda-inp-rex">Soy un Ejecutivo</label>
                    </div>
                    <div class="row my-15" >                  
                      <div class="col-md-6" id="cpr-rgs-pda-div-exe">
                        <div class="form-group input-basic">
                            <label class="select-label">Ejecutivo</label>                         
                              <select
                                name="executiveType"
                                type="text" 
                                class="input-type-executive custom-select" 
                                data-parsley-errors-container="#cpr-rgs-pda-div-exe"
                                data-parsley-trigger="input focus"
                                data-parsley-required-message="Campo requerido"
                              >
                                <option value="" selected disabled>Selecciona una opción</option>
                                <option value="AM">AM</option>
                                <option value="AM">DL</option>
                                <option value="AM">AF</option>
                                <option value="AM">KL</option>
                              </select>                        
                        </div> 
                          <div className="input-group-append">
                            <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                            <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                          </div>    
                         
                      </div>
                      <div className="col-md-6" id="cpr-rgs-pda-div-gae">
                        <div className="input-group input-basic floating-label pt-20" >
                          <label>Referencia</label>
                          <input 
                          name="executiveNumber"
                          className="input-number-executive form-control mt-15"
                          placeholder="Número de empleado"
                          data-parsley-errors-container="#cpr-rgs-pda-div-gae"
                          data-parsley-trigger="input focus"
                          data-parsley-required-message="Campo requerido"
                          data-parsley-type="digits"
                          data-parsley-type-message="Sólo dígitos"
                          />
                          <label for="executiveNumber">Referencia</label>
                               
                        </div>
                        <div class="input-group-append">
                          <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                          <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                        </div> 
                      </div> 
                    </div>
                  </div>
                  <div className="row py-30">
                  <div className="box-btn col-md-6 text-left mb-10" id="cpr-rgs-pda-div-ane">
                    <a className="btn btn-secondary btn-back-one" href="#ant">Anterior</a> 
                  </div>          
                  <div className="box-btn-after col-md-6 text-right" id="cpr-rgs-pda-div-siu">
                    <button className="btn btn-primary btn-form-two"
                      disabled
                    >
                      Siguiente
                    </button>
                  </div>
                </div> 
                </div>
                 
                <div className="step-three">
                  <h4 className="card-title text-center p-30 m-0">Datos de la empresa</h4>
                  <p className="other">Ingresa a continuación los datos de la empresa, te recordamos que todos los campos son obligatorios</p>
                  <div id="cpr-rgs-pda-div-gnc">
                    <div className="input-group input-basic py-15" >
                      <label>Razón social</label>
                      <input 
                      name="businessName"
                      className="name-corporate form-control"
                      placeholder="Ejemplo SA de CV"
                      data-parsley-errors-container="#cpr-rgs-pda-div-gnc"
                      data-parsley-trigger="input focus"
                      data-parsley-required-message="Campo requerido"
                      data-parsley-pattern="/^[a-zA-Z ]+$/"
                      data-parsley-pattern-message="No se permiten números ni carateres especiales"
                      maxlength="35"  
                      data-parsley-length-message="Máximo 35 caracteres"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>      
                    </div>
                  </div>                
                  <div class="row my-15" >                  
                      <div class="country-url col-md-6" id="cpr-rgs-pda-div-gco" data-url="https://7is3a5dmjb.execute-api.us-east-1.amazonaws.com/app_club_premier_api_live/catalog/countries?language=es">
                        <div class="form-group form-group-dropdown mb-0">
                          <label class="select-label">País</label>                         
                            <select
                              name="country"
                              type="text" 
                              class="input-country custom-select" 
                              data-parsley-errors-container="#cpr-rgs-pda-div-gco"
                              data-parsley-trigger="input focus"
                              data-parsley-required-message="Campo requerido"
                            >
                            </select>                        
                        </div>   
                      </div>
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div> 
                      <div className="cp-url col-md-6 pt-10" id="cpr-rgs-pda-div-gcp" data-url="https://0xo55otzd1.execute-api.us-east-1.amazonaws.com/step1/postal_code/show/">
                        <div className="input-group input-basic" >
                          <label>Código Postal</label>
                          <input 
                          name="postalCode"
                          className="input-cp form-control mt-10"
                          placeholder="01234"
                          data-parsley-errors-container="#cpr-rgs-pda-div-gcp"
                          data-parsley-trigger="input focus"
                          data-parsley-required-message="Campo requerido"
                          data-parsley-pattern="/^[0-9]*$/"
                          data-parsley-type-message="Sólo dígitos"
                          data-parsley-length-message="Excede el número de caracteres"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                            <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                          </div>      
                        </div>
                      </div> 
                    </div> 
                  <div id="cpr-rgs-pda-div-grf">
                    <div className="input-group input-basic py-15">
                      <label>RFC o Número de identificación</label>
                      <input className="input-rfc form-control"
                      name="rfc"
                      placeholder="EJEM01234"
                      data-parsley-trigger="input focus"
                      data-parsley-errors-container="#cpr-rgs-pda-div-grf"
                      data-parsley-required-message="Campo requerido"
                      data-parsley-length-message="Formato no válido"
                      data-parsley-pattern=""
                      data-parsley-pattern-message="Formato no válido"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>                   
                    </div>
                  </div>
                  <div class="row my-15" >                                       
                      <div className="col-md-6 pt-10" id="cpr-rgs-pda-div-gtr">
                        <div className="input-group input-basic" >
                          <label>Nombre Comercial</label>
                          <input 
                          name="tradeName"
                          className="input-tradename form-control mt-10"
                          placeholder="Ejemplo El Viajero"
                          data-parsley-pattern="/^[a-zA-Z ]+$/"
                          data-parsley-pattern-message="No se permiten números ni carateres especiales"
                          data-parsley-errors-container="#cpr-rgs-pda-div-gtr"
                          data-parsley-trigger="input focus"
                          data-parsley-required-message="Campo requerido"
                          maxlength="35"  
                          data-parsley-length-message="Máximo 35 caracteres"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                            <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                          </div>      
                        </div>
                      </div> 
                      <div class="col-md-6" id="cpr-rgs-pda-div-gct">
                        <div class="form-group form-group-dropdown mb-0">
                        <div class="form-group form-group-dropdown mb-0">
                          <label class="select-label">Giro de la empresa</label>                         
                            <select
                              name="commercialBusiness"
                              type="text" 
                              class="input-company-type custom-select" 
                              data-parsley-errors-container="#cpr-rgs-pda-div-gct"
                              data-parsley-trigger="input focus"
                              data-parsley-required-message="Campo requerido"
                            >
                              <option value="" selected disabled>Selecciona una opción</option>
                              <option>Tecnología</option>
                            </select>                        
                        </div> 
                          <div className="input-group-append">
                            <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                            <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                          </div> 
                        </div>   
                      </div>
                    </div> 
                    <div class="row my-15" >                  
                      <div class="col-md-6" id="cpr-rgs-pda-div-gte">
                        <div className="input-group input-basic" >
                          <label>Estado</label>
                          <input 
                          name="state"
                          placeholder="Selecciona una opción"
                          className="input-state form-control mt-10"
                          data-parsley-errors-container="#cpr-rgs-pda-div-gte"
                          data-parsley-trigger="change input focus"
                          data-parsley-required-message="Campo requerido"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                            <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                          </div>      
                        </div>
                      </div>
                      <div class="col-md-6" id="cpr-rgs-pda-div-gtw">
                        <div className="input-group input-basic" >
                          <label>Municipio o delegación</label>
                          <input 
                          name="township"
                          placeholder="Selecciona una opción"
                          className="input-town form-control mt-10"
                          data-parsley-errors-container="#cpr-rgs-pda-div-gtw"
                          data-parsley-trigger="input focus"
                          data-parsley-required-message="Campo requerido"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                            <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                          </div>      
                        </div>                         
                      </div>                    
                    </div>
                    <div class="row my-15" >                  
                      <div class="col-md-6 pt-10" id="cpr-rgs-pda-div-gci">
                        <div className="input-group input-basic" >
                          <label>Ciudad</label>
                          <input 
                            name="city"
                            className="input-city form-control mt-10"
                            data-parsley-errors-container="#cpr-rgs-pda-div-gci"
                            data-parsley-trigger="input focus change"
                            data-parsley-required-message="Campo requerido"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                            <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                          </div>      
                        </div>  
                      </div>
                      <div class="col-md-6" id="cpr-rgs-pda-div-gsb">                      
                        <div class="form-group form-group-dropdown mb-0">
                          <label class="select-label">Colonia</label>                         
                            <select
                              name="colony"
                              type="text" 
                              class="input-suburb custom-select" 
                              data-parsley-errors-container="#cpr-rgs-pda-div-gsb"
                              data-parsley-trigger="input focus"
                              data-parsley-required-message="Campo requerido"
                            >
                              <option value="" disabled selected>Selecciona una opción</option>
                            </select>                     
                        </div> 
                        </div>  
                        <div className="input-group-append">
                          <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                          <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                        </div>
                        <div class="col-md-6 pt-10" id="cpr-rgs-pda-div-gsu">
                        <div className="input-group input-basic" >
                          <label>Colonia</label>
                          <input
                            name="suburb"
                            className="input-suburb form-control mt-10"
                            data-parsley-errors-container="#cpr-rgs-pda-div-gsu"
                            data-parsley-trigger="input focus change"
                            data-parsley-required-message="Campo requerido"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                            <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  <div class="form-group row">  
                    <div className="col-md-6 pt-20" id="cpr-rgs-pda-div-gtn">
                      <div className="input-group input-basic floating-label py-15" >
                        
                        <span
                        className="input-group-text"
                        >
                          <input 
                            name="phoneCode"
                            className="telephone-code form-control" readonly/>
                        </span>
                        <input 
                        name="phoneNumber"
                        className="telephone-number form-control"
                        placeholder="123 456 78 90"
                        data-parsley-errors-container="#cpr-rgs-pda-div-gtn"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        maxlength="10"  
                        data-parsley-length-message="Máximo 10 dígitos"
                        data-parsley-type="digits"
                        data-parsley-type-message="Sólo dígitos"
                        />
                        <label for="phoneNumber">Número Telefónico</label>
                        </div>
                        <div class="input-group-append">
                          <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                          <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                        </div>      
                      
                    </div>                               
                    <div className="col-md-6 pt-20" id="cpr-rgs-pda-div-gst">
                      <div className="input-group input-basic floating-label py-15">
                        
                        <input 
                        name="street"
                        className="input-street form-control"
                        placeholder="Calle Sin Nombre #123"
                        data-parsley-errors-container="#cpr-rgs-pda-div-gst"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        />
                        <label for="stret">Calle y número</label>
                        </div>
                        <div class="input-group-append">
                          <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                          <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                        </div>      
                     
                    </div>  
                  </div> 
                  <div className="row py-30">
                  <div className="box-btn col-md-6 text-left mb-10" id="cpr-rgs-pda-div-ant">
                    <a className="btn btn-secondary btn-back-two" href="/">Anterior</a> 
                  </div>          
                  <div className="box-btn-after col-md-6 text-right" id="cpr-rgs-pda-div-sig">
                    <button className="btn btn-primary btn-form-three"
                      disabled
                    >
                      Siguiente
                    </button>
                  </div>
                </div>                     
                </div>
                
                <div className="step-four d-none">
                  <h4 className="card-title text-center p-30 m-0">Datos de contacto</h4>
                  <p className="other">Ingresa los datos de la persona que representa a tu empresa en el programa Aeroméxico Rewards Business</p>
                  <div className="text-left">
                    <h5>Representante de la cuenta</h5>
                  </div>
                  <div class="form-group row py.10">  
                    <div className="col-md-6">
                      <div className="input-group input-basic" id="cpr-rgs-pda-div-nom">
                        <label>Nombre y Apellido</label>
                        <input 
                        className="input-agent-name form-control"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                          <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                        </div>      
                      </div>
                    </div>                            
                    <div className="col-md-6">
                      <div className="input-group input-basic" id="cpr-rgs-pda-div-ncp">
                        <label>No. de Cuenta Aeroméxico Rewards</label>
                        <input 
                        className="input-agent-account form-control"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                          <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                        </div>      
                      </div>
                    </div>  
                  </div> 
                  <div id="cpr-rgs-pda-div-anu">
                    <div className="input-group input-basic" >
                      <label>Correo electrónico institucional</label>
                      <input 
                      name="emailId"
                      data-parsley-errors-container="#cpr-rgs-pda-div-anu"
                      className="input-agent-email form-control"
                      data-parsley-trigger="input focus"
                      placeholder="correo@example.com"
                      type="email"
                      data-parsley-type-message="Correo inválido"
                      data-parsley-required-message="Campo requerido"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>      
                    </div>
                  </div>  
                  <div className="text-left pt-30">
                    <h5>Contacto de la Cuenta 1</h5>
                  </div>
                  <div class="form-group row py.10">  
                    <div className="col-md-6" id="cpr-rgs-pda-div-con">
                      <div className="input-group input-basic" >
                        <label>Nombre y Apellido</label>
                        <input 
                        name="contacts[0].name"
                        placeholder="Nombres y Apellido"
                        className="contact-one-name form-control"
                        data-parsley-errors-container="#cpr-rgs-pda-div-con"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        data-parsley-pattern="/^[a-zA-Z ]+$/"
                        data-parsley-pattern-message="No se permiten números ni carateres especiales"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                          <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                        </div>      
                      </div>
                    </div>                        
                    <div className="col-md-6" id="cpr-rgs-pda-div-coa">
                      <div className="input-group input-basic" >
                        <label>No. de Cuenta Aeroméxico Rewards</label>
                        <input 
                        name="contacts[0].accountNumber"
                        className="contact-one-account form-control"
                        placeholder="1234567"
                        data-parsley-errors-container="#cpr-rgs-pda-div-coa"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        minlength="2"
                        maxlength="11"
                        data-parsley-length-message="Máximo 11 dígitos"
                        data-parsley-luhn
                        data-parsley-luhn-message="No. de cuenta inválido"
                        data-parsley-type="digits"
                        data-parsley-type-message="Sólo dígitos"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                          <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                        </div>      
                      </div>
                    </div>  
                  </div> 
                  <div id="cpr-rgs-pda-div-coe">
                    <div className="input-group input-basic" >
                      <label>Correo electrónico institucional</label>
                      <input 
                      name="contacts[0].email"
                      placeholder="correo@example.com"
                      className="contact-one-email form-control input-account"
                      type="email"
                      data-parsley-type-message="Correo inválido"
                      data-parsley-errors-container="#cpr-rgs-pda-div-coe"
                      data-parsley-trigger="input focus"
                      data-parsley-required-message="Campo requerido"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>      
                    </div>
                  </div>  
                  <div className="text-left pt-30">
                    <h5>Contacto de la Cuenta 2</h5>
                  </div>
                  <div class="form-group row py.10">  
                    <div className="col-md-6" id="cpr-rgs-pda-div-ctn">
                      <div className="input-group input-basic" >
                        <label>Nombre y Apellido</label>
                        <input 
                        name="contacts[1].name"
                        placeholder="Nombre y Apellido"
                        className="contact-two-name form-control"
                        data-parsley-errors-container="#cpr-rgs-pda-div-ctn"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        data-parsley-pattern="/^[a-zA-Z ]+$/"
                        data-parsley-pattern-message="No se permiten números ni carateres especiales"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                          <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                        </div>      
                      </div>
                    </div>                                       
                    <div className="col-md-6" id="cpr-rgs-pda-div-cta">
                      <div className="input-group input-basic" >
                        <label>No. de Cuenta Aeroméxico Rewards</label>
                        <input 
                        name="contacts[1].accountNumber"
                        className="contact-two-account form-control"
                        placeholder="1234567"
                        data-parsley-errors-container="#cpr-rgs-pda-div-cta"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        minlength="2"
                        maxlength="11"
                        data-parsley-length-message="Máximo 11 dígitos"
                        data-parsley-luhn
                        data-parsley-luhn-message="No. de cuenta inválido"
                        data-parsley-type="digits"
                        data-parsley-type-message="Sólo dígitos"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                          <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                        </div>      
                      </div>
                    </div>  
                  </div> 
                  <div id="cpr-rgs-pda-div-cte">
                    <div className="input-group input-basic" >
                      <label>Correo electrónico institucional</label>
                      <input 
                      name="contacts[1].email"
                      placeholder="correo@example.com"
                      className="contact-two-email form-control input-account"
                      type="email"
                      data-parsley-errors-container="#cpr-rgs-pda-div-cte"
                      data-parsley-type-message="Correo inválido"
                      data-parsley-trigger="input focus"
                      data-parsley-required-message="Campo requerido"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>      
                    </div>
                  </div>
                  <div className="new-contact d-none">
                    <div className="row">
                      <div className="col-8 text-left pt-30">
                        <h5>Contacto de la Cuenta 3</h5>
                      </div>
                      <div className="col-4 text-right pt-30">
                        <a href="#/" className="icon-delete-new-contact">
                          Borrar
                          <i className="fas fa-trash-alt"></i>
                        </a>
                      </div>
                    </div>
                    <div class="form-group row py.10">  
                      <div className="col-md-6" id="cpr-rgs-pda-div-ctm">
                        <div className="input-group input-basic" >
                          <label>Nombre y Apellido</label>
                          <input 
                          name="contacts[2].name"
                          placeholder="Nombre y Apellido"
                          className="contact-three-name form-control"
                          data-parsley-errors-container="#cpr-rgs-pda-div-ctm"
                          data-parsley-trigger="input focus"
                          data-parsley-required-message="Campo requerido"
                          data-parsley-pattern="/^[a-zA-Z ]+$/"
                          data-parsley-pattern-message="No se permiten números ni carateres especiales"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                            <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                          </div>      
                        </div>
                      </div>                                       
                      <div className="col-md-6" id="cpr-rgs-pda-div-ctc">
                        <div className="input-group input-basic" >
                          <label>No. de Cuenta Aeroméxico Rewards</label>
                          <input 
                          name="contacts[2].accountNumber"
                          className="contact-three-account form-control"
                          placeholder="1234567"
                          data-parsley-errors-container="#cpr-rgs-pda-div-ctc"
                          data-parsley-trigger="input focus"
                          data-parsley-required-message="Campo requerido"
                          minlength="2"
                          maxlength="11"
                          data-parsley-length-message="Máximo 11 dígitos"
                          data-parsley-luhn
                          data-parsley-luhn-message="No. de cuenta inválido"
                          data-parsley-type="digits"
                          data-parsley-type-message="Sólo dígitos"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text valid"><i class="fas fa-check-circle"></i></span>
                            <span class="input-group-text invalid"><i class="fas fa-times-circle"></i></span>
                          </div>      
                        </div>
                      </div>  
                    </div> 
                    <div id="cpr-rgs-pda-div-ctl">
                      <div className="input-group input-basic" >
                        <label>Correo electrónico institucional</label>
                        <input 
                        name="contacts[2].email"
                        placeholder="correo@example.com"
                        className="contact-three-email form-control input-account"
                        type="email"
                        data-parsley-errors-container="#cpr-rgs-pda-div-ctl"
                        data-parsley-type-message="Correo inválido"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                          <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                        </div>      
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-right pt-15" id="cpr-rgs-pda-div-agr">
                      <a href="#/" className="icon-add-new-contact">
                        Agregar otro contacto
                        <i className="fas fa-plus"></i>
                      </a>
                    </div>
                  </div>
                  <div className="row py-30">
                  <div className="box-btn col-md-6 text-left mb-10" id="cpr-rgs-pda-div-ann">
                    <a className="btn btn-secondary btn-back-three" href="/">Anterior</a> 
                  </div>          
                  <div className="box-btn-after col-md-6 text-right" id="cpr-rgs-pda-div-sii">
                    <button className="btn btn-primary btn-form-four"
                      disabled
                    >
                      Siguiente
                    </button>
                  </div>
                </div>                
              </div>
                
              </form> 
              <div className="step-five d-none"> 
              <div className="alert alert-info alert-campaign mt-30 d-none" role="alert">
                <div className="row row-basic pl-15">
                  <i className='fas fa-times-circle'/>
                  <h5>Información incorrecta</h5>
                  <i className="close fas faF-times" data-dismiss="alert"/>
                </div>
                <div className="row row-campaign row-basic pl-15">
                  <p className="text"></p>
                </div>
              </div>               
                <h4 className="card-title text-center pt-30 title-text"></h4>
                <p className="card-text text-center paragraph-text"></p> 
                <div className="text-center py-30" id="cpr-rgs-pda-div-iss">
                  <a className="btn btn-primary link-login" href="/">Iniciar Sesión</a>
                </div>
                <div className="box-inscription py-30" id="cpr-rgs-pda-div-snv">
                  <a className="link-inscription" href="/">Seguir navegando</a>
                </div>           
              </div>   
            </div>                             
          </div> 
          <div className="col-12 col-md-6 mb-0 py-15">
            <article className="col-8 text-center m-center pb-30">
              <h2 className="my-30">
                Descubre Aeroméxico Rewards Business
              </h2>
              <p className="body-text pl-15 pr-15">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna 
              aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
              </p>
              <a className="btn btn-primary" href="/">Saber más<i className="fas fa-arrow-right"/></a>   
            </article>
            <section className="section-corporativo auto">
              <div className="row grid-descubre section-corp">
                <div className="col-md-12">
                  <div className="row h-100">
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-big-stone">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo_Aeromexico.png" alt="Logo de Aeroméxico"/>
                      </div>
                    </div>
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-chathams-blue">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo_delta.png" alt="Logo de Delta"/>
                      </div>
                    </div>
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-cardinal">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo-Airfrance.png" alt="Logo de AirFrance"/>
                      </div>
                    </div>
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-cerulean">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo-KLM.png" alt="Logo de KLM"/>
                      </div>           
                    </div>
                  </div>
                </div>     
              </div>   
            </section>  
          </div>
        </div>    
      </div>  
    );
  }
}
export default EnrollmentForm;