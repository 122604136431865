import React from 'react'
import SideBar from './sidebar';

class CorporateProfile extends React.Component {
  render() {
    return (  
      <div className="container-fluid  bg-alabaster">
        <div className="container-corporate-profile row py-0 " data-text="EN" data-user="false"> 
          <SideBar />
          <div className="col-12 col-md-9 container-body mt-15 p-0">
            <div className="col-12 mt-30"> 
              <h2 className="d-inline"><i className="fas fa-arrow-left icon-dashboard d-none"></i>Perfil Corporativo</h2>
            </div> 
            {/* <div className="box-btn-responsive row w-100 mb-15 mt-15 d-md-none d-sm-block">
              <div className="col-12 text-center">
                <button className="col-5 btn btn-primary button-general-data mr-15 btn-sm">
                  Datos generales
                </button>
                <button className="col-5 btn btn-outline-primary button-contact-data btn-sm">
                  Datos de contacto
                </button>
              </div> 
            </div>  */}
            <div className="col-12 row container-form p-0" >            
              <div className="card card-general col-12 col-md-6 mt-15 mb-30 pl-md-15">  
                <h3 className="card-title text-left p-10 m-0">Datos generales</h3>
               
                <div className="alert alert-info alert-info-files d-none" role="alert">
                  <div className="row row-basic">
                    <i className='fas fa-info-circle'/>
                    <h5>Importante</h5>
                    <i className='fas fa-times'/>
                  </div>
                  <div className="row row-basic">
                    <p>Para actualizar los datos generales envía los siguientes documentos:
                      *Carta solicitud de actualización membretada y firmada por el Representante
                      Empresarial indicanco el número de Cuenta Corporativa. *Acta constitutiva. *Cédula de RFC</p>
                  </div>
                </div>

                <div className="alert alert-success alert-success-files d-none" role="alert">
                  <div className="row row-basic">
                    <i className='fas fa-check-circle'/>
                    <h5>Envío exitoso</h5>
                    <i className='close fas fa-times'/>
                  </div>
                  <div className="row row-basic text-success">
                    <p className="text"></p>
                  </div>
                </div>

                <div className="alert alert-danger alert-error-files d-none" role="alert">
                  <div className="row row-basic">
                    <i className='fas fa-times-circle'/>
                    <h5>Ocurrió un error</h5>
                    <i className='close fas fa-times'/>
                  </div>
                  <div className="row row-basic text-error" data-text="debe ser un archivo jpg, png, o pdf">
                    <p className="text"></p>
                  </div>
                </div>

                <div className="row my-15" >                  
                  <div className="col-md-6" id="cpr-pcp-pda-div-gcm">
                    <div className="input-group input-basic floating-label" >  
                      <input 
                        className="form-control mt-15"
                        data-parsley-errors-container="#cpr-pcp-pda-div-gcm"
                        data-parsley-trigger="input"
                        placeholder="Razón social"
                        readOnly
                        id="cpr-pcp-pda-inp-icm"
                      />
                      <div className="tooltip hint--bottom-left">
                        <i className="fas fas-basic fa-lock"></i>
                      </div>
                      <label htmlFor="cpr-pcp-pda-inp-icm">Razón social</label>      
                    </div> 
                    <div className="input-group-append">
                      <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                      <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                    </div> 
                  </div>
                  <div className="col-md-6" id="cpr-pcp-pda-div-grf" >
                    <div className="input-group input-basic floating-label" >  
                      <input 
                        className="form-control mt-15"
                        data-parsley-errors-container="#cpr-pcp-pda-div-grf"
                        data-parsley-trigger="input"
                        placeholder="RFC"
                        readOnly
                        id="cpr-pcp-pda-inp-rfc"
                      />
                      <div className="tooltip hint--bottom-left">
                        <i className="fas fas-basic fa-lock"></i>
                      </div>
                      <label htmlFor="cpr-pcp-pda-inp-rfc">RFC</label>      
                    </div> 
                    <div className="input-group-append">
                      <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                      <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                    </div>
                  </div>
                </div>
                <div className="row my-15" >                  
                  <div className="col-md-6" id="cpr-pcp-pda-div-gtc">
                    <div className="input-group input-basic floating-label" >  
                      <input 
                        className="form-control mt-15"
                        data-parsley-errors-container="#cpr-pcp-pda-div-gtc"
                        data-parsley-trigger="input"
                        placeholder="Giro"
                        readOnly
                        id="cpr-pcp-pda-inp-tcm"
                      />
                      <div className="tooltip hint--bottom-left">
                        <i className="fas fas-basic fa-lock"></i>
                      </div>
                      <label htmlFor="cpr-pcp-pda-inp-tcm">Giro</label>      
                    </div> 
                    <div className="input-group-append">
                      <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                      <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                    </div> 
                  </div>
                  
                  <div className="col-md-6" id="cpr-pcp-pda-div-gco">
                    <div className="input-group input-basic floating-label" >  
                      <input 
                        className="form-control mt-15"
                        data-parsley-errors-container="#cpr-pcp-pda-div-gco"
                        data-parsley-trigger="input"
                        placeholder="Nombre comercial"                     
                        readOnly
                        id="cpr-pcp-pda-inp-cnm"
                      />
                      <div className="tooltip hint--bottom-left">
                        <i className="fas fas-basic fa-lock"></i>
                      </div>
                      <label htmlFor="cpr-pcp-pda-inp-cnm">Nombre comercial</label>      
                    </div> 
                    <div className="input-group-append">
                      <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                      <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                    </div>       
                  </div>  
                </div>

                <div className="alert alert-danger alert-error-type d-none" role="alert">
                  <div className="row row-basic">
                    <i className='fas fa-times-circle'/>
                    <h5>Archivo no permitido</h5>
                    <i className='close fas fa-times'/>
                  </div>
                  <div className="row row-basic text-type" data-text="es un archivo inválido. Sólo se permite subir archivos jpg, pfg y png" data-error="El archivo excede 10 MB">
                    <p className="text"></p>
                  </div>
                </div>

                <div className="row my-15 uploader-files-container d-none" data-url="#">
                  <div className='upload-dropzone'>
                    <p>Arrastra los archivos de la solicitud y referencia (.jpg, .png, .pdf) ó</p>

                    <input
                        type="file"
                        className= "input-file d-none"
                        accept="image/png, image/jpeg,.pdf"
                    />
                    <button className='btn btn-secondary select-files'>Selecciona archivos</button>

                    <div className='file-requirements'>
                      <p>Tamaño máximo de archivo: 10MB</p>
                      <p>Solo se permiten 2 archivos</p>
                    </div>
                  </div>

                  <div className='files-list'>
                    <ul>
                    </ul>
                  </div>

                  <div className='update-controls w-100'>
                    <button className='btn btn-secondary cancel-update-data'>Cancelar</button>
                    <button className='btn btn-secondary send-files' disabled>Enviar</button>
                  </div>

                  <div className="upload-status" id="cpr-pcp-pda-div-ust"></div>
                </div>

                <div className="row py-30 w-100">         
                  <div className="box-btn-after col-12 text-right" id="cpr-pcp-pda-div-act">
                    <button className="btn btn-primary btn-form-three update-data">
                      Actualizar datos
                    </button>
                  </div>                  
                </div>
              </div> 
          
              <div className="card card-contact col-12 col-md-6 mt-15 mb-30 pl-md-15 opacity">          
                <form className="mb-30 form-corp"id="cpr-pcp-pda-frm-fcp" data-parsley-validate="" noValidate=""  action="/other"> 
                  <h3 className="card-title text-left p-10 m-0">Datos de contacto</h3> 
                  <div className="alert alert-success alert-success-information d-none" role="alert">
                    <div className="row row-basic">
                      <i className='fas fa-check-circle'/>
                      <h5>Envío exitoso</h5>
                      <i className='close fas fa-times'/>
                    </div>
                    <div className="row row-basic text-success-info">
                      <p className="text"></p>
                    </div>
                  </div>

                  <div className="alert alert-danger alert-error-information d-none" role="alert">
                    <div className="row row-basic">
                      <i className='fas fa-times-circle'/>
                      <h5>Ocurrió un error</h5>
                      <i className='close fas fa-times'/>
                    </div>
                    <div className="row row-basic text-error-info">
                      <p className="text"></p>
                    </div>
                  </div>  

                  <h4 className="text-left p-15">Dirección</h4>       
                  <div className="row my-15 country-url-profile" data-url="https://7is3a5dmjb.execute-api.us-east-1.amazonaws.com/app_club_premier_api_live/catalog/countries?language=es">                    
                    {/* Group Country */}
                    <div className="col-md-6 group-country" id="cpr-pcp-pda-div-gcn" data-url="https://7is3a5dmjb.execute-api.us-east-1.amazonaws.com/app_club_premier_api_live/catalog/states?countryCode=">
                      <div className="form-group form-group-dropdown mb-0">
                        <label className="select-label">País</label>                         
                          <select
                            type="text"
                            data-text="MX" 
                            className="input-country-profile custom-select" 
                            data-parsley-errors-container="#cpr-pcp-pda-div-gcn"
                            data-parsley-trigger="input focus"
                            data-parsley-required-message="Campo requerido"
                            name="country"
                            required
                          >
                          </select>                        
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div> 
                    </div>
                    {/* Group Postal Code  */}
                    <div className="cp-url-profile col-md-6" id="cpr-pcp-pda-div-gcp" data-url="https://0xo55otzd1.execute-api.us-east-1.amazonaws.com/step1/postal_code/show/">
                      <div className="input-group input-basic floating-label py-15" >  
                        <input 
                          className="input-cp-profile form-control mt-15"
                          data-parsley-errors-container="#cpr-pcp-pda-div-gcp"
                          data-parsley-trigger="change input focus"
                          placeholder="Código Postal"
                          data-text="04800"
                          data-parsley-required-message="Campo requerido"
                          id="cpr-pcp-pda-inp-pcd"
                          name="postalCode"
                          required
                        />
                        <label htmlFor="postalCode">Código Postal</label>      
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>       
                    </div>  
                  </div> 
                  <div className="row my-15" >   
                    {/* Group State (select)*/}
                    <div className="col-md-6 group-state" id="cpr-pcp-pda-div-gsa">
                      <div className="form-group form-group-dropdown mb-0">
                        <label className="select-label">Estado</label>                         
                          <select   
                            defaultValue={'DEFAULT'}                         
                            type="text" 
                            className="select-state-profile custom-select" 
                            data-text="DISTRITO FEDERAL"
                            data-parsley-errors-container="#cpr-pcp-pda-div-gsa"
                            data-parsley-trigger="input focus"
                            data-parsley-required-message="Campo requerido"
                            name="state"
                            required
                          >
                            <option value="DEFAULT" disabled >Selecciona una opción</option>
                          </select>                        
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div> 
                    </div>
                    {/* Group State (open input) */}
                    <div className="col-md-6 input-state-two d-none" id="cpr-pcp-pda-div-gst">
                      <div className="input-group input-basic floating-label py-15" >  
                        <input 
                          className="input-state-two form-control mt-15"
                          data-parsley-errors-container="#cpr-pcp-pda-div-gst"
                          data-parsley-trigger="input"
                          data-parsley-required-message="Campo requerido"
                          placeholder="Estado"
                          id="cpr-pcp-pda-inp-sit"
                          name="state"
                          disabled
                        />
                        <label htmlFor="stateInputTwo">Estado</label>      
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div> 
                    </div>
                    {/* Group Town/Delegation (select) */}
                    <div className="col-md-6 group-town" id="cpr-pcp-pda-div-gtw">
                      <div className="form-group form-group-dropdown mb-0">
                        <label className="select-label">Municipio o delegación</label>                         
                          <select
                            defaultValue={'DEFAULT'}
                            type="text" 
                            className="delegation-profile custom-select" 
                            data-text="Cuauhtémoc"
                            data-parsley-errors-container="#cpr-pcp-pda-div-gtw"
                            data-parsley-trigger="input focus"
                            data-parsley-required-message="Campo requerido"
                            name="delegation"
                            required
                          >
                            <option value="DEFAULT" disabled>Selecciona una opción</option>
                          </select>                        
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div> 
                    </div>
                    {/* Group Town/Delegation (open input)*/}
                    <div className="col-md-6 input-town-two d-none" id="cpr-pcp-pda-div-gtt">
                      <div className="input-group input-basic floating-label py-15" >  
                        <input 
                          className="input-delegation-profile form-control mt-15"
                          data-parsley-errors-container="#cpr-pcp-pda-div-gtt"
                          data-parsley-trigger="input"
                          placeholder="Municipio o delegación"
                          data-parsley-required-message="Campo requerido"
                          id="cpr-pcp-pda-inp-tnp"
                          name="delegation"
                          disabled
                        />
                        <label htmlFor="cpr-pcp-pda-inp-tnp">Municipio o delegación</label>      
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div> 
                    </div>
                  </div>   
                  <div className="row my-15" >
                    {/* Group Suburb/Colony (select) */}         
                    <div className="col-md-6 group-suburb" id="cpr-pcp-pda-div-gsb">
                      <div className="container-suburb form-group form-group-dropdown mb-0">
                        <label htmlFor="inputCp" className="select-label">Colonia</label>                         
                          <select
                            type="text" 
                            className="input-suburb-profile custom-select" 
                            data-text="Centro"
                            data-parsley-errors-container="#cpr-pcp-pda-div-gsb"
                            data-parsley-trigger="input focus"
                            data-parsley-required-message="Campo requerido"
                            name="colony"
                            text="Siempreviva"
                            required
                          >
                          </select>                        
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div> 
                    </div>
                    {/* Group Suburb/Colony (Open Input) */}
                    <div className="col-md-6 input-colony-two d-none" id="cpr-pcp-pda-div-gct">
                      <div className="input-group input-basic floating-label py-15" >  
                        <input 
                          className="input-colony-profile form-control mt-15"
                          data-parsley-errors-container="#cpr-pcp-pda-div-gct"
                          data-parsley-trigger="input"
                          placeholder="Colonia"
                          data-parsley-required-message="Campo requerido"
                          id="cpr-pcp-pda-inp-cnp"
                          name="colony"
                          disabled
                        />
                        <label htmlFor="cpr-pcp-pda-inp-cnp">Colonia</label>      
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div> 
                    </div>
                    {/* Group Street */}
                    <div className="col-md-6" id="cpr-pcp-pda-div-gsr">
                      <div className="input-group input-basic floating-label py-15" >  
                        <input 
                          className="input-street-profile form-control mt-15"
                          data-parsley-errors-container="#cpr-pcp-pda-div-gsr"
                          data-parsley-trigger="input"
                          placeholder="Calle y número"
                          data-text="República del salvador 52"
                          data-parsley-required-message="Campo requerido"
                          id="cpr-pcp-pda-inp-ist"
                          name="address"
                          required
                        />
                        <label htmlFor="cpr-pcp-pda-inp-ist">Calle y número</label>      
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>       
                    </div>                
                  </div>
                  <div className="row my-15">
                    {/* Group City */}
                    <div className="col-md-6" id="cpr-pcp-pda-div-gci">
                      <div className="input-group input-basic floating-label py-15" >  
                        <input 
                          className="input-city-profile form-control mt-15"
                          data-parsley-errors-container="#cpr-pcp-pda-div-gci"
                          data-parsley-trigger="input"
                          placeholder="Ciudad"
                          data-text="Distrito Federal"
                          data-parsley-required-message="Campo requerido"
                          id="cpr-pcp-pda-inp-ict"
                          name="address"
                          required
                        />
                        <label htmlFor="cpr-pcp-pda-inp-ict">Ciudad</label>      
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div> 
                    </div> 
                  </div>
                  <h4 className="text-left p-15">Correo y Teléfono</h4>
                  <div className="row my-15" >
                    {/* Group email */}
                    <div className="col-md-6" id="cpr-pcp-pda-div-gem">
                      <div className="input-group input-basic floating-label py-15" >  
                        <input 
                          className="email-profile form-control mt-15"
                          data-text="ag@gmail.com"
                          type="email"
                          data-parsley-type-message="Correo inválido"
                          data-parsley-errors-container="#cpr-pcp-pda-div-gem"
                          data-parsley-trigger="input"
                          placeholder="Correo electrónico"
                          data-parsley-required-message="Campo requerido"
                          id="cpr-pcp-pda-inp-eac"
                          name="individualEmail"
                          required
                        />
                        <label htmlFor="cpr-pcp-pda-inp-eac">Correo electrónico</label>      
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>       
                    </div>
                    {/* Group Email Company */}                 
                    <div className="col-md-6" id="cpr-pcp-pda-div-gec">
                      <div className="input-group input-basic floating-label py-15" >  
                        <input 
                          className="email-company-profile form-control mt-15"
                          data-text="ab@company.com"
                          type="email"
                          data-parsley-type-message="Correo inválido"
                          data-parsley-errors-container="#cpr-pcp-pda-div-gec"
                          data-parsley-trigger="input"
                          placeholder="Correo electrónico institucional"
                          data-parsley-required-message="Campo requerido"
                          id="cpr-pcp-pda-inp-ecm"
                          name="email"
                          required
                        />
                        <label htmlFor="cpr-pcp-pda-inp-ecm">Correo electrónico institucional</label>      
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>       
                    </div>  
                  </div> 

                  <div className="row my-15 contact-telephone-one" >
                    {/* Group Tel type */}
                    <div className="col-md-6" id="cpr-pcp-pda-div-gtn">
                      <div className="form-group form-group-dropdown mb-0">
                        <label className="select-label">Tipo</label>                         
                          <select
                            defaultValue={'DEFAULT'}
                            type="text" 
                            className="type-number-profile custom-select" 
                            data-text="Móvil"
                            data-parsley-errors-container="#cpr-pcp-pda-div-gtn"
                            data-parsley-trigger="input focus"
                            data-parsley-required-message="Campo requerido"
                            required
                          >
                            <option value="DEFAULT" disabled>Selecciona una opción</option>
                            <option>Móvil</option>
                          </select>                        
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div> 
                    </div>
                    {/* Group Tel Number */}
                    <div className="col-md-6 group-number-one" id="cpr-pcp-pda-div-gno" data-text="Seleccionar una opción">
                      <div className="input-group input-basic floating-label py-15" >  
                        <span className="input-group-text">
                          <input 
                            className="telephone-code-profile form-control" readOnly/>
                        </span>
                        <input 
                          className="input-number-profile form-control mt-15"
                          data-text="5520202020"
                          data-parsley-errors-container="#cpr-pcp-pda-div-gno"
                          data-parsley-trigger="input"
                          placeholder="Número Telefónico"
                          data-parsley-required-message="Campo requerido"
                          maxLength="10"  
                          minLength="10"  
                          data-parsley-length-message="Máximo 10 dígitos"
                          data-parsley-type="digits"
                          data-parsley-type-message="Sólo dígitos"
                          id="cpr-pcp-pda-inp-tno"
                          name="phoneWork"
                          required
                        />
                        <label htmlFor="cpr-pcp-pda-inp-tno">Numero Telefónico</label>      
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>       
                    </div>  
                  </div>
                  {/* Group Tel 2 - type & number */}
                  <div className="row my-15 contact-telephone-two" >
                    {/* Group Tel type 2 */}
                    <div className="col-md-6" id="cpr-pcp-pda-div-gtm">
                      <div className="form-group form-group-dropdown mb-0">
                        <label className="select-label">Tipo</label>                         
                          <select
                            defaultValue={'DEFAULT'}
                            type="text" 
                            className="type-number-two-profile custom-select" 
                            data-text="Oficina"
                            data-parsley-errors-container="#cpr-pcp-pda-div-gtm"
                            data-parsley-trigger="input focus"
                            data-parsley-required-message="Campo requerido"
                          >
                            <option value="DEFAULT" disabled>Selecciona una opción</option>
                            <option>Oficina</option>
                          </select>                        
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div> 
                    </div>
                    {/* Group Tel number 2 */}
                    <div className="col-md-6" id="cpr-pcp-pda-div-gnt">
                      <div className="input-group input-basic floating-label py-15" >  
                        <span className="input-group-text">
                          <input 
                            className="telephone-code-profile form-control" readOnly/>
                        </span>
                        <input 
                          className="input-number-two-profile form-control mt-15"
                          data-text="1234567890"
                          data-parsley-errors-container="#cpr-pcp-pda-div-gnt"
                          data-parsley-trigger="input"
                          placeholder="Número Telefónico"
                          data-parsley-required-message="Campo requerido"
                          maxLength="10" 
                          minLength="10"   
                          data-parsley-length-message="Máximo 10 dígitos"
                          data-parsley-type="digits"
                          data-parsley-type-message="Sólo dígitos"
                          id="cpr-pcp-pda-inp-tnt"
                          name="phoneMobil"
                        />
                        <label htmlFor="cpr-pcp-pda-inp-tnt">Número Telefónico</label>      
                      </div> 
                      <div className="input-group-append">
                        <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                        <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                      </div>       
                    </div>  
                  </div>
                  <div className="row my-15 telephone-contact d-none" id="cpr-pcp-pda-div-cte">
                    <div className="col-12 w-100 text-right">
                      <a className="add-telephone-contact" href="#">Agregar otro contacto telefónico</a>
                    </div>  
                  </div>
                  <div className="row my-15 remove-contact d-none" id="cpr-pcp-pda-div-can">
                    <div className="col-12 w-100 text-right">
                      <a className="remove-telephone-contact" href="#">Cancelar</a>
                    </div>  
                  </div>
                  <div className="row container-message-ue py-30 d-none">  
                    <p>He leído y acepto los términos y condiciones para las actividades secundarias y de esta forma recibir notificaciones de estos temas</p>       
                    <div className="custom-control custom-radio text-left ml-md-15">
                      <input type="radio" className="custom-radio-input custom-radio1" id="cpr-pcp-pda-inp-crd" name="isCountryEuropeanUnion" value="true" data-parsley-required-message="Campo requerido"/>
                      <label className="custom-radio-label" htmlFor="cpr-pcp-pda-inp-crd">Sí acepto</label>
                    </div>  
                    <div className="custom-control custom-radio text-left ml-15">
                      <input type="radio" className="custom-radio-input" id="cpr-pcp-pda-inp-cra" name="isCountryEuropeanUnion" value="false" data-parsley-required-message="Campo requerido"/>
                      <label className="custom-radio-label" htmlFor="cpr-pcp-pda-inp-cra">No acepto</label>
                    </div>              
                  </div>
                  <div className="row buttons-row py-30 d-none">         
                    <div className="box-btn-after col-12 text-right">
                      <a className="btn btn-secondary btn-profile-cancel mr-15 mb-15 mb-md-0" href="/" data-text="Eliminar" id="cpr-pcp-pda-hyp-can">
                        Cancelar
                      </a>
                      <button className="btn btn-primary btn-profile-update" data-text="Actualizar" id="cpr-pcp-pda-btn-actte">
                        Actualizar
                      </button>
                    </div>                  
                  </div>
                </form>
              </div>                                                      
            </div> 
          </div>    
        </div> 
      </div>       
    );
  }
}
export default CorporateProfile;