import React from "react";
class TitlesAndtext extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>TITULOS Y TEXTO</h1>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0">
                    <h3>Tipografía</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                        Para fines de cualquier componente que se desprenda de la marca, Aeroméxico Rewards Business, haremos uso de la tipografía Roboto y sus diferentes pesos. A continuación se presentan los diferentes usos para web.
                        </h4>
                        <h5 className="h5-etiqueta" text-transform = "uppercase">700</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <div className="row">
                                    <p className="boldText" style={{marginLeft: '1.5rem', marginTop: '0', marginBottom: '0'}}>Roboto Bold</p>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;p class="boldText"&gt; Roboto Bold&lt;/p&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <h5 className="h5-etiqueta">500</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <div className="row">
                                    <p className="mediumText" style={{marginLeft: '1.5rem', marginTop: '0', marginBottom: '0'}}>Roboto Medium</p>
                                </div>
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;p class="boldText"&gt; Roboto Bold&lt;/p&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <h5 className="h5-etiqueta">400</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row">
                                    <p className="regularText" style={{marginLeft: '1.5rem', marginTop: '0', marginBottom: '0'}}>Roboto Regular</p>
                                </div>  
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;p class="boldText"&gt; Roboto Bold&lt;/p&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <h5 className="h5-etiqueta">300</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <div className="row">
                                    <p className="lightText" style={{marginLeft: '1.5rem', marginTop: '0', marginBottom: '0'}}>Roboto Light</p>
                                </div>   
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;p class="boldText"&gt; Roboto Bold&lt;/p&gt;
                                </code>                  
                            </div>      
                        </div>
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3 text-transform = "uppercase">Headline</h3>
                    <div className="alert alert-texto mb-0">
                        <h4 text-transform = "uppercase">
                        A continuación se describen los usos de la tipografía para Aeroméxico Rewards Business. Se describen las propiedades para uso web y mobile.
                        </h4>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <h1>Headline h1</h1>   
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;h1&gt;Headline h1&lt;/h1&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <h2>Headline h2</h2>   
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;h2&gt;Headline h2&lt;/h2&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <h3>Headline h3</h3>   
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;h3&gt;Headline h3&lt;/h3&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <h4>Headline h4</h4>   
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;h4&gt;Headline h4&lt;/h4&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <h5>Headline h5</h5>   
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;h5&gt;Headline h5&lt;/h5&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <h6>Headline h6</h6>   
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;h6&gt;Headline h6&lt;/h6&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <h4 text-transform = "uppercase">Headline h7</h4>   
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;h7&gt;Headline h7&lt;/h7&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <h4 text-transform = "uppercase">Headline h8</h4> 
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;h8&gt;Headline h8&lt;/h8&gt;
                                </code>                  
                            </div>                  
                        </div>  
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <p className="body-text">Body text</p> 
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;p class="body-text"&gt;Body text&lt;/p&gt;
                                </code>                  
                            </div>                  
                        </div> 
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <p className="Links-and-inputs">Links and inputs</p> 
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;p class="Links-and-inputs"&gt;Links and inputs&lt;/p&gt;
                                </code>                  
                            </div>                  
                        </div> 
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <p className="support_text">Suport text</p> 
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;p class="support_text"&gt;Suport text&lt;/p&gt;
                                </code>                  
                            </div>                  
                        </div> 
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia">
                                <p className="label-text">label</p> 
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;p class="label-text"&gt;label&lt;/p&gt;
                                </code>                  
                            </div>                  
                        </div> 
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3>Listas</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                        Las listas nos permiten crear conjuntos de elementos  que generalmente se encuentra precedidos por un número o viñeta. Generalmente son utilizados para visualizar una serie de pasos de manera ordenada. Para fines de uso dentro de Club Prmier Corporativo, deberán usarse los siguientes tipos de listas según sea el caso y necesidad.
                        </h4>
                        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Bullets</h5>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                            <div className="alert alert-view p-30-guia col-12">
                                <div className="row row-basic">
                                    <ul>
                                        <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                        <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                        <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;ul&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &lt;/ul&gt;<br/>
                                </code>            
                            </div> 
                        </div>  
                        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Bullets y subtítulo</h5>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                            <div className="alert alert-view p-30-guia col-12">
                                <div className="row row-basic">
                                    <ul>
                                        <li className="bullet-basic">
                                            <p className="bullet-basic-title">Subtítulo - H6</p>
                                            <p>Sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin sit amet, elit consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                                        </li>
                                        <li className="bullet-basic">
                                            <p className="bullet-basic-title">Subtítulo - H6</p>
                                            <p>Sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin sit amet, elit consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                                        </li>
                                        <li className="bullet-basic">
                                            <p className="bullet-basic-title">Subtítulo - H6</p>
                                            <p>Sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin sit amet, elit consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;ul&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="bullet-basic-title"&gt;Subtítulo - H6&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="bullet-basic-title"&gt;Subtítulo - H6&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="bullet-basic-title"&gt;Subtítulo - H6&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &lt;/ul&gt;
                                </code>            
                            </div> 
                        </div>
                        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Números</h5>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                            <div className="alert alert-view p-30-guia col-12">
                                <div className="row row-basic">
                                    <ol>
                                        <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                        <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                        <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;ol&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &lt;/ol&gt;<br/>
                                </code>            
                            </div> 
                        </div>
                        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Números y subtítulo</h5>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                            <div className="alert alert-view p-30-guia col-12">
                                <div className="row row-basic">
                                <ol>
                                    <li className="bullet-basic">
                                        <p className="bullet-basic-title">Subtítulo - H6</p>
                                        <p>Sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin sit amet, elit consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                                    </li>
                                    <li className="bullet-basic">
                                        <p className="bullet-basic-title">Subtítulo - H6</p>
                                        <p>Sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin sit amet, elit consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                                    </li>
                                    <li className="bullet-basic">
                                        <p className="bullet-basic-title">Subtítulo - H6</p>
                                        <p>Sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin sit amet, elit consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                                    </li>
                                </ol>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;ol&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="bullet-basic-title"&gt;Subtítulo - H6&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="bullet-basic-title"&gt;Subtítulo - H6&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="bullet-basic-title"&gt;Subtítulo - H6&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                    &lt;/ol&gt;<br/>
                                </code>            
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3>Tipos de Listas</h3>
                    <div className="alert alert-texto mb-0">
                        <div className="alert mb-0 p-0">   
                        <h5 className="h5-etiqueta">Bullets</h5>         
                            <div className=" row p-30-guia">
                                <div className="alert alert-view col-12 col-md-6 p-30-guia">
                                    <div className="row row-basic">
                                        <ul>
                                            <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                    <code>
                                        &lt;ul&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO.&lt;/p&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                        &lt;/ul&gt;<br/>
                                    </code>            
                                </div> 
                            </div>
                            <h5 className="h5-etiqueta">Bullets cuadrados</h5>   
                            <div className=" row p-30-guia">
                                <div className="alert alert-view col-12 col-md-6 p-30-guia">
                                    <div className="row row-basic">
                                    <ul className="b-square">
                                            <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                    <code>
                                        &lt;ul class="b-square"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO.&lt;/p&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                        &lt;/ul&gt;<br/>
                                    </code>            
                                </div> 
                            </div>
                            <h5 className="h5-etiqueta">Números</h5>   
                            <div className=" row p-30-guia">
                                <div className="alert alert-view col-12 col-md-6 p-30-guia">
                                    <div className="row row-basic">
                                        <ol>
                                            <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                    <code>
                                        &lt;ol&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                        &lt;/ol&gt;<br/>
                                    </code>            
                                </div> 
                            </div>
                            <h5 className="h5-etiqueta">Letras mayúsculas</h5>  
                            <div className=" row p-30-guia">
                                <div className="alert alert-view col-12 col-md-6 p-30-guia">
                                    <div className="row row-basic">
                                        <ul className="b-upper-alpha">
                                            <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                    <code>
                                        &lt;ul class="b-upper-alpha"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                        &lt;/ul&gt;<br/>
                                    </code>            
                                </div> 
                            </div>
                            <h5 className="h5-etiqueta">Letras minúsculas</h5>  
                            <div className=" row p-30-guia">
                                <div className="alert alert-view col-12 col-md-6 p-30-guia">
                                    <div className="row row-basic">
                                        <ul className="b-lower-alpha">
                                            <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                    <code>
                                        &lt;ul class="b-lower-alpha"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                        &lt;/ul&gt;<br/>
                                    </code>            
                                </div> 
                            </div>
                            <h5 className="h5-etiqueta">Números romanos mayúsculas</h5>  
                            <div className=" row p-30-guia">
                                <div className="alert alert-view col-12 col-md-6 p-30-guia">
                                    <div className="row row-basic">
                                        <ul className="b-upper-roman">
                                            <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                    <code>
                                        &lt;ul class="b-upper-roman"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                        &lt;/ul&gt;<br/>
                                    </code>            
                                </div> 
                            </div>
                            <h5 className="h5-etiqueta">Números romanos minúsuclas</h5>  
                            <div className=" row p-30-guia">
                                <div className="alert alert-view col-12 col-md-6 p-30-guia">
                                    <div className="row row-basic">
                                        <ul className="b-lower-roman">
                                            <li className="bullet-basic"><p>Paragraph lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore, sed do eiusmod tempor incididunt ut labore et dolore adipisicin.</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                    <code>
                                        &lt;ul class="b-lower-roman"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;li class="bullet-basic"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt; TEXTO. &lt;/p&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;/li&gt;<br/>
                                        &lt;/ul&gt;<br/>
                                    </code>            
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alert alert-texto mb-0">
                    <div className="alert alert-texto mb-0">
                        <h4>
                            NOTA: Cualquier tipo de lista puede tener la opción de llevar o no subtítulo dependiendo de las necesidades de cada implementación. Es importante respetar la estructura que a continuación se detalla.
                         
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default TitlesAndtext;
