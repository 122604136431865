import React from 'react';

class EnrollmentInit extends React.Component {

  render() {
    return (    
      <div className="container-fluid container-enrollment py-0">
        <div className="row d-flex flex-row-reverse">    
          <div className="col-12 col-md-6 bg-gradient container-form" >
            <div className="card col-md-8 mt-60 mb-30">   
              <a href="/"><i className='fas fa-times redirect-icon'/></a>            
                <h4 className="card-title text-center p-0 m-0">¡Regístrate en Aeroméxico Rewards!</h4>
                <p className="card-text text-center">Recuerda que el representante legal de cada empresa deberá estar registrado en Aeroméxico Rewards</p> 
                <div className="text-center py-30">
                  <a className="btn btn-primary" href="/" id="cpr-rgs-pda-hyp-cnt">Continuar<i className="fas fa-arrow-right"/></a>
                </div>
                <div className="box-inscription py-30">
                  <p className="m-0">Si aun no eres Socio del programa invidual,</p>  
                  <a className="link-inscription" href="/" id="cpr-rgs-pda-hyp-rgs">¡Inscríbete gratis!</a>
                </div>           
            </div>                             
          </div> 
          <div className="col-12 col-md-6 mb-0 py-15">
            <article className="col-8 text-center m-center pb-30">
              <h2 className="my-30">
                Descubre Aeroméxico Rewards Business
              </h2>
              <p className="body-text pl-15 pr-15">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna 
              aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
              </p>
              <a className="btn btn-primary" href="/" id="cpr-rgs-ida-hyp-kmr">Saber más<i className="fas fa-arrow-right"/></a>   
            </article>
            <section className="section-corporativo auto">
              <div className="row grid-descubre section-corp">
                <div className="col-md-12">
                  <div className="row h-100">
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-big-stone">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo_Aeromexico.png" alt=""/>
                      </div>
                    </div>
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-chathams-blue">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo_delta.png" alt=""/>
                      </div>
                    </div>
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-cardinal">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo-Airfrance.png" alt=""/>
                      </div>
                    </div>
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-cerulean">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo-KLM.png" alt=""/>
                      </div>           
                    </div>
                  </div>
                </div>     
              </div>   
            </section>  
          </div>
        </div>    
      </div>  
    );
  }
}
export default EnrollmentInit;