import React from 'react';
import SideBar from './sidebar';

class RecoveryPoints extends React.Component {

  render() {
    return (    
      <div className="bg-alabaster">
        <div className="row pl-15 pr-15 recovery-points" data-text="ES" data-transactions="true" data-can-buy="true" data-account-text="Cuenta Aeroméxico Rewards" data-account="3333333" data-text-points-success="Puntos Aeroméxico Rewards Recuperados" data-textbutton-add="Recuperar Puntos" data-text-error-general="Porfavor, intenta nuevamente." data-country="MX" data-address="Reforma 2501" data-postal-code="06500" data-colony="Cuauhtémoc, Cuauhtémoc" data-state="Distrito Federal" data-city="México">
					<input className="base-url" type="hidden" id="cpr-rkm-prp-inp-bsu" value="https://d1f25111-d53b-4697-a6f5-360bfc6bab3b.mock.pstmn.io"/>
          <input className="country-url" type="hidden" id="cpr-rkm-prp-inp-ctu" value="https://7is3a5dmjb.execute-api.us-east-1.amazonaws.com/app_club_premier_api_live/catalog/countries?language=es"/>
          <input className="state-url" type="hidden" id="cpr-rkm-prp-inp-stu" value="https://7is3a5dmjb.execute-api.us-east-1.amazonaws.com/app_club_premier_api_live/catalog/states?countryCode"/>
          <input className="cp-url" type="hidden" id="cpr-rkm-prp-inp-cpu" value="https://7is3a5dmjb.execute-api.us-east-1.amazonaws.com/app_club_premier_api_live/catalog/postalCode?postalCode"/>
					<input className="image-error" type="hidden" id="cpr-rkm-prp-inp-ier" value="https://clubpremierstyleguide.s3.amazonaws.com/corporativoinfo/assets/i-sorry.png"/>
					<input className="image-success-add" type="hidden" id="cpr-rkm-prp-inp-isa" value="https://clubpremierstyleguide.s3.amazonaws.com/corporativoinfo/assets/success-points.png"/>
					<SideBar />
          <div className="col-12 col-lg-9 container-body p-0 mb-15">
            <div className="col-12 mt-30"> 
              <h2 className="d-inline">
                <i className="fas fa-arrow-left icon-dashboard"></i>
                Recupera tus Puntos Aeroméxico Rewards
              </h2>
            </div>
            <p className="subtitle m-15">
              Recupera tus Puntos Aeroméxico Rewards Corporativos expirados con una tarifa preferencial, más $400.00 MXN por cuota de recuperación.
            </p>

            <div className="row">
              <div className="col-12 col-md-12 col-lg-10 col-xl-2 mt-15 mb-15 d-block d-lg-none d-xl-none">
              <div className="row">
                  <div className="col-6 col-md-6 p-1">
                    <div className="panel-info variant">
                      <p>Puntos expirados - 12/08/2020</p>
                      <p className="text-points">
                        <span>123,456</span>
                        <img src="https://clubpremierstyleguide.s3.amazonaws.com/corporativoinfo/assets/icon-points.svg" alt="icon-points"/>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-left">
                <form id="cpr-rkm-prp-frm-rpf" className="recovery-points-form card-white bg-white">
                  <div className="row mt-15 p-15">
                    <h4>Recupera Puntos Aeroméxico Rewards</h4>
                  </div>
                  <div class="alert alert-warning m-15 d-none" role="alert">
                    <div class="row row-basic">
                        <i class='fas fa-exclamation-triangle'/>
                        <h5>Por el momento no puedes recuperar Puntos Aeroméxico Rewards</h5>
                        <i class='fas fa-times'/>
                    </div>
                    <div class="row row-basic">
                      <p>No tienes Puntos Aeroméxico Rewards expirados que puedas recuperar.</p>
                    </div>
                  </div>
                  <div class="alert alert-danger m-15 d-none" role="alert">
                    <div class="row row-basic">
                      <i class='fas fa-times-circle'/>
                      <h5>Ha ocurrido un error</h5>
                      <i class='fas fa-times'/>
                    </div>
                    <div class="row row-basic">
                      <p>El servicio no se encuentra disponible. Por favor intenta más tarde.</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <h4>¿Cuántos Puntos Aeroméxico Rewards deseas recuperar?</h4>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-pts">
                      <div className="input-group">
                        <label className="select-label">Puntos Aeroméxico Rewards a recuperar</label>                         
                        <select
                          type="text"
                          className="input-points custom-select"
                          defaultValue={'DEFAULT'}
                          required
                          data-parsley-trigger="input focus"
                          data-parsley-required-message="Campo requerido"
                        >
                          <option value="DEFAULT" disabled>Puntos Aeroméxico Rewards a recuperar</option>
                          <option value="1000" data-bonuskmps="">1,000</option>
                          <option value="2000" data-bonuskmps="500">2,000</option>
                        </select>
                        <div className="input-group-append">
                          <span className="input-group-text inputBasic pl-0">
                            <i className="fas fa-lg fa-angle-down"/>
                          </span>
                        </div>
                      </div> 
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-tpe">
                      <div className="input-group" >
                        <label>Tarifa preferencial en Pesos</label>
                        <input 
                          className="input-subtotal-mxn form-control"
                          placeholder="Costo en Pesos"
                          value=""
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-tpa">
                      <div className="input-group" >
                        <label>Total a pagar (Cuota de recuperación incluida)</label>
                        <input 
                          className="input-total-mxn form-control"
                          placeholder="Cuota en Pesos"
                          value=""
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 mt-4">
                      <h4>Datos bancarios</h4>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-tit">
                      <div className="input-group input-basic" >
                        <label>Nombre del titular</label>
                        <input 
                        className="input-name form-control"
                        required
                        placeholder="Nombre del titular"
                        data-parsley-trigger="input focus"
                        data-parsley-pattern="/^[a-zA-Z ]+$/"
                        data-parsley-pattern-message="No se permiten números ni carateres especiales"
                        data-parsley-required-message="Campo requerido"
                        />    
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-nta">
                      <div className="input-group input-basic" >
                        <label>Número de tarjeta</label>
                        <input 
                        className="input-number-card form-control"
                        maxlength="19"
                        required
                        data-type-card=""
                        placeholder="Número de tarjeta"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        data-parsley-type="digits"
                        data-parsley-type-message="Sólo dígitos"
                        data-parsley-credit-card
                        data-parsley-credit-card-message="Tarjeta de crédito/débito no valida"
                        />    
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-mve">
                      <div className="input-group">
                        <label className="select-label">Mes de Vencimiento</label>                         
                        <select
                          name="contactType"
                          type="text"
                          className="input-month-card custom-select"
                          defaultValue={'DEFAULT'}
                          required
                          data-parsley-trigger="input focus change"
                          data-parsley-required-message="Campo requerido"
                          data-parsley-exp-card=""
                          data-parsley-exp-card-message=""
                        >
                          <option value="DEFAULT" disabled>Mes de Vencimiento</option>
                          <option value="01">Enero</option>
                          <option value="02">Febrero</option>
                          <option value="03">Marzo</option>
                          <option value="04">Abril</option>
                          <option value="05">Mayo</option>
                          <option value="06">Junio</option>
                          <option value="07">Julio</option>
                          <option value="08">Agosto</option>
                          <option value="09">Septiembre</option>
                          <option value="10">Octubre</option>
                          <option value="11">Noviembre</option>
                          <option value="12">Dicembre</option>
                        </select>
                        <div className="input-group-append">
                          <span className="input-group-text inputBasic pl-0">
                            <i className="fas fa-lg fa-angle-down"/>
                          </span>
                        </div>
                      </div> 
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-ave">
                      <div className="input-group">
                        <label className="select-label">Año de vencimiento</label>                         
                        <select
                          name="contactType"
                          type="text"
                          className="input-year-card custom-select"
                          defaultValue={'DEFAULT'}
                          required
                          data-parsley-trigger="input focus change"
                          data-parsley-required-message="Campo requerido"
                          data-parsley-exp-card=""
                          data-parsley-exp-card-message="Fecha invalida, selecione un mes y año validos"
                        >
                          <option value="DEFAULT" disabled>Año de vencimiento</option>
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                        </select>
                        <div className="input-group-append">
                          <span className="input-group-text inputBasic pl-0">
                            <i className="fas fa-lg fa-angle-down"/>
                          </span>
                        </div>
                      </div> 
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-cse">
                      <div className="input-group input-basic" >
                        <label>Código de seguridad</label>
                        <input 
                        className="input-ccv-card form-control"
                        required
                        maxlength="4"
                        placeholder="Código de seguridad"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        data-parsley-type="digits"
                        data-parsley-type-message="Sólo dígitos"
                        />    
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 mt-4">
                      <h4>Domicilio</h4>
                    </div>
                    <div className="col-12 col-md-12" id="cpr-rkm-prp-div-dom">
                      <div class="row row-basic">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input check-address"
                            id="cpr-rkm-prp-chk-cad"
                          />
                          <label class="custom-control-label font-weight-bold ft-14" for="cpr-rkm-prp-chk-cad">Usar domicilio registrado con tarjeta</label>
                        </div>
                      </div>  
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-pai">
                      <div className="input-group">
                        <label className="select-label">País</label>                         
                        <select
                          name="contactType"
                          type="text"
                          className="input-country custom-select"
                          defaultValue={'DEFAULT'}
                          required
                          data-parsley-trigger="input focus"
                          data-parsley-required-message="Campo requerido"
                        >
                          <option value="DEFAULT" disabled>País</option>
                        </select>
                        <div className="input-group-append">
                          <span className="input-group-text inputBasic pl-0">
                            <i className="fas fa-lg fa-angle-down"/>
                          </span>
                        </div>
                      </div> 
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-cal">
                      <div className="input-group input-mx">
                        <label>Calle y Número</label>
                        <input 
                        className="input-address form-control"
                        placeholder="Calle y Número"
                        required
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        data-parsley-pattern="/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/"
                        data-parsley-pattern-message="No se permiten carateres especiales"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fas fa-lock-alt"></i>
                          </span>
                        </div>    
                      </div>
                      <div className="input-group input-other-country d-none" >
                        <label>Dirección 1</label>
                        <input 
                        className="input-address-other form-control"
                        placeholder="Dirección 1"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        data-parsley-pattern="/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/"
                        data-parsley-pattern-message="No se permiten carateres especiales"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-cod">
                      <div className="input-group" >
                        <label>Código Postal</label>
                        <input 
                        className="input-cp form-control"
                        placeholder="Código Postal"
                        required
                        maxlength="5"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        data-parsley-type="digits"
                        data-parsley-type-message="Sólo dígitos"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fas fa-lock-alt"></i>
                          </span>
                        </div>    
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-cod">
                      <div className="input-group input-mx">
                        <label className="select-label">Colonia y Delegación</label>                         
                        <select
                          name="contactType"
                          type="text"
                          className="input-colony custom-select"
                          defaultValue={'DEFAULT'}
                          required
                          data-parsley-trigger="input focus"
                          data-parsley-required-message="Campo requerido"
                        >
                          <option value="DEFAULT" disabled>Colonia y Delegación</option>
                        </select>
                        <div className="input-group-append">
                          <span className="input-group-text inputBasic pl-0">
                            <i className="fas fa-lg fa-angle-down"/>
                          </span>
                        </div>
                      </div>
                      <div className="input-group input-other-country d-none" >
                        <label>Dirección 2</label>
                        <input 
                        className="input-address-2-other form-control"
                        placeholder="Dirección 2"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        data-parsley-pattern="/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/"
                        data-parsley-pattern-message="No se permiten carateres especiales"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-est">
                      <div className="input-group input-state-select">
                        <label className="select-label">Estado</label>                         
                        <select
                          name="contactType"
                          type="text"
                          className="input-state custom-select"
                          defaultValue={'DEFAULT'}
                          required
                          data-parsley-trigger="input focus"
                          data-parsley-required-message="Campo requerido"
                        >
                          <option value="DEFAULT" disabled>Estado</option>
                        </select>
                        <div className="input-group-append">
                          <span className="input-group-text inputBasic pl-0">
                            <i className="fas fa-lg fa-angle-down"/>
                          </span>
                        </div>
                      </div>
                      <div className="input-group input-other-country-state d-none" >
                        <label>Estado</label>
                        <input 
                        className="input-state-other form-control"
                        placeholder="Estado"
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        data-parsley-pattern="/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/"
                        data-parsley-pattern-message="No se permiten carateres especiales"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4" id="cpr-rkm-prp-div-ciu">
                      <div className="input-group" >
                        <label>Ciudad</label>
                        <input 
                        className="input-city form-control"
                        placeholder="Ciudad"
                        required
                        data-parsley-trigger="input focus"
                        data-parsley-required-message="Campo requerido"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fas fa-lock-alt"></i>
                          </span>
                        </div>    
                      </div>
                    </div>
                  </div>

                  <div className="row mt-15">
                    <div className="col-12 col-md-12" id="cpr-rkm-prp-div-ter">
                      <div className="row row-basic">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input custom-terms"
                            id="cpr-rkm-prp-chk-ctr"
                            required
                            data-parsley-trigger="input focus change"
                            data-parsley-required-message="Campo requerido"
                          />
                          <label className="custom-control-label font-weight-bold ft-14" for="cpr-rkm-prp-chk-ctr">
                            He leído y acepto los 
                            <a href="#" data-toggle="modal" data-target="#cpr-rkm-prp-div-mtr" className="font-weight-bold ft-14 text-dark-blue"> Términos y Condiciones.</a>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 text-right p-15">
                      <a className="btn btn-secondary m-1 btn-cancel" type="button" data-dismiss="modal" aria-label="Close" id="cpr-rkm-prp-hyp-can">
                        Cancelar
                      </a>
                      <button className="btn btn-primary m-1 btn-recovery-points" disabled="disabled" id="cpr-rkm-prp-btn-com">
                        Recuperar Puntos
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-12 col-md-12 col-lg-10 col-xl-2 p-0 mt-15 mb-15 d-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-6 col-md-6 col-lg-12 col-xl-12 mt-lg-15 p-0">
                    <div className="panel-info variant">
                      <p>Puntos expirados - 12/08/2020</p>
                      <p className="text-points">
                        <span>123,456</span>
                        <img src="https://clubpremierstyleguide.s3.amazonaws.com/corporativoinfo/assets/icon-points.svg" alt="icon-points"/>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* modal messages */}
            <div className="modal info modal-data" id="cpr-rkm-prp-div-mdd" data-keyboard="false" data-backdrop="static" tab-index="-1" role="dialog" aria-labelledby="cpr-rkm-prp-txt-mdt" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title text-center m-auto" id="cpr-rkm-prp-txt-mdt"></h4>
                    </div>
                    <div className="modal-body text-center">
                      <img src="" alt="i_sorry" className="image-error"/>
                      <p className="text-center text-alternative"></p>
                      <p className="text-center text-alternative-two d-none"></p>
                      <button className="btn btn-secondary" type="button" data-dismiss="modal" aria-label="Close">
                          Aceptar
                      </button>
                      <button className="btn btn-secondary success-add d-none" type="button">
                          Aceptar
                      </button>
                    </div>
                  </div>
              </div>
            </div>
            {/* modal terms */}
            <div className="modal info" id="cpr-rkm-prp-div-mtr" data-keyboard="false" data-backdrop="static" tab-index="-1" role="dialog" aria-labelledby="cpr-rkm-prp-txt-mtt" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title text-left" id="cpr-rkm-prp-txt-mtt">
                        Términos y Condiciones
                      </h4>
                    </div>
                    <div className="modal-body text-center">
                      <p className="text-justify">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
                        Distinctio magnam quibusdam, excepturi officia repellat 
                        velit omnis ad explicabo tempora praesentium? Saepe assumenda 
                        illo fuga maxime ad deserunt consectetur minima eos!
                      </p>
                      <p className="text-justify">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
                        Distinctio magnam quibusdam, excepturi officia repellat 
                        velit omnis ad explicabo tempora praesentium? Saepe assumenda 
                        illo fuga maxime ad deserunt consectetur minima eos!
                      </p>
                      <p className="text-justify">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
                        Distinctio magnam quibusdam, excepturi officia repellat 
                        velit omnis ad explicabo tempora praesentium? Saepe assumenda 
                        illo fuga maxime ad deserunt consectetur minima eos!
                      </p>
                      <p className="text-right">
                        <button className="btn btn-outline-primary" type="button" data-dismiss="modal" aria-label="Close">
                          Entendido
                        </button>
                      </p>
                    </div>
                  </div>
              </div>
            </div>
            {/* final */}
          </div>
        </div>
      </div>  
    );
  }
}
export default RecoveryPoints;