import React from 'react';
import SideBar from './sidebar';

class UpgradesDynamicAncilliaries extends React.Component {

  render() {
    return (    
      <div className="bg-alabaster">
        <div className="row upgrades -ancilliaries"
          data-club-points-name="Puntotes"
          data-await-purchase-timeout="5">
          <input type="hidden" id="cardId"></input>
          <div className="urlInputs d-none">
					  <input type="hidden" id="getReservationInfoUrl" value="https://ezmock.herokuapp.com/api/60a54e9a6b035f00152c1fac"/>
					  <input type="hidden" id="payUpgradeUrl" value="https://ezmock.herokuapp.com/api/6096cfb60e2a3e00156b87a4"/>
          </div>
          <div className="templates d-none">
            {/* Loader Template */}
            <div className="loader -template d-flex justify-content-center align-items-center d-none">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
            {/* Single flight information template */}
            <div className="single-flight -template d-none">
              {/* Airport data - origin, destination, operator and other details */}
              <div className="info">
                <div className="index mb-20">
                <i className="fas fa-plane"></i>
                </div>
                <div className="details d-flex">
                  <div className="airports">
                    <table>
                      <colgroup>
                        <col className="origins"></col>
                        <col className="destinations"></col>
                      </colgroup>
                      <tbody>
                        <tr className="hours">
                          <td className="orig"></td>
                          <td className="dest"></td>
                        </tr>
                        <tr className="codes">
                          <td className="orig"></td>
                          <td className="dest"></td>
                        </tr>
                        <tr className="cities">
                          <td className="orig"></td>
                          <td className="dest"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="conditions">
                    <div className="class -label">Clase</div>
                    <div className="class -content"></div>
                    <div className="departure-date -label">Salida</div>
                    <div className="departure-date -content"></div>
                    <div className="expected-time -label">Tiempo de viaje</div>
                    <div className="expected-time -content"></div>
                  </div>
                </div>
                <div className="operator mb-15"></div>
              </div>
              {/* Passenger data for each flight: names, seats, etc. */}
              <table className="passengers">
                <colgroup>
                    <col className="col-names" />
                    <col className="col-seats" />
                    <col className="col-emds" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="-label">Pasajeros</th>
                    <th className="-label">Asientos</th>
                    <th className="-label">EMD Asiento pagado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="passenger">
                    <td className="name"></td>
                    <td className="seat"></td>
                    <td className="emd"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Ugrade list row Template */}
            <div className="upgrade -template d-none">
              <div className="details">
                <div className="title">Ascenso Cabina Premier</div>
                <div className="flight">%%%%</div>
                <div className="passenger-list">
                  <div className="passenger">%%%%</div>
                </div>
              </div>
              <div className="points">
                <div className="title">Puntos Aeroméxico Rewards</div>
                <div className="cost">
                  <div className="custom-control custom-checkbox">
                    <input id="cpr-dup-upg-chk-tmp" type="checkbox" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="cpr-dup-upg-chk-tmp">%%%%</label>
                  </div>
                </div>
              </div>
            </div>
            {/* Purchase confirmation row template */}
            <div className="single-purchase -template d-none">
              <div className="flight-airports">%%</div>
              <div className="general-data">
                <div className="departure-data">
                  <div className="title">Salida</div>
                  <div className="data">Miércoles 23 de septiembre</div>
                </div>
                <div className="time-data">
                  <div className="title">Tiempo de viaje</div>
                  <div className="data">1 hrs 45 min</div>
                </div>
                <div className="class-data">
                  <div className="title">Clase</div>
                  <div className="data -highlight">Cabina Premier</div>
                </div>
              </div>
              <table className="passengers">
                <colgroup>
                      <col className="col-names" />
                      <col className="col-seats" />
                      <col className="col-emds" />
                  </colgroup>
                <thead>
                  <tr>
                    <th className="-label">Pasajeros</th>
                    <th className="-label">Asientos</th>
                    <th className="-label">EMD Asiento pagado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="passenger ">
                    <td className="name">%%</td>
                    <td className="seat">%%</td>
                    <td className="emd">%%</td>
                  </tr>
                </tbody>
              </table>
              <div className="operator mb-15">%%</div>
            {/* End single purchase-confirmation row */}
            </div>
            {/* Error msg template */}
            <div className="alert alert-danger alert-error alert-dismissible fade show col-10 d-none -template">
              <button type="button" className="close" data-dismiss="alert" aria-label="Cerrar">
                <span aria-hidden="true">&times;</span>
              </button>
              <h2 className="alert-heading">
                <i className="fa fa-times-circle"/> Ocurrió un problema con el servidor
              </h2>
              <p className="alert-body">Por favor vuelve a intentarlo más tarde</p>
            </div>
            {/* Warning msg template */}
            <div className="alert alert-warning alert-dismissible fade show col-10 d-none -template">
              <button type="button" className="close" data-dismiss="alert" aria-label="Cerrar">
                <span aria-hidden="true">&times;</span>
              </button>
              <h2 className="alert-heading">
                <i className="fa fa-exclamation-triangle"/>
              </h2>
              <p className="alert-body"></p>
            </div>
          </div>
          
          <SideBar />
          
          <div className="upgrades-container col-lg-9 p-0">
          
            <div className="group-steps">

              <div className="main-title"> 
                <h2 className="d-inline">
                <i className="fas fa-arrow-left icon-dashboard d-lg-none"></i>
                  Venta de Ancillaries
                </h2>
              </div>


              <div id="step1" className="step1">

              {/* Breadcrumbs */}
              <div className="breadcrumbs">
                <div className="hr"></div>
                <div className="crumbs-container">
                  <div className="crumb -active">
                    <div className="number">1</div>
                    <div className="label">Consulta</div>
                  </div>
                  <div className="crumb">
                    <div className="number">2</div>
                    <div className="label">Selecciona</div>
                  </div>
                  <div className="crumb">
                    <div className="number">3</div>
                    <div className="label">Resúmen</div>
                  </div>
                  <div className="crumb">
                    <div className="number">4</div>
                    <div className="label">Confirmación</div>
                  </div>
                </div>
              </div>

                {/* Header Row */}

                <div className="header">
                  <img src="https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/dynamic-ups/headers/flight-data-form.svg"></img>
                  <h1>Compra tus Ascensos Dinámicos</h1>
                </div>

                {/* Form Row */}
                <div className="d-flex justify-content-center align-items-center">
                  <div className="form-cont">
                    <h3 className="text-center mb-50">Mejora la experiencia de volar y viaja en Clase Premier.</h3>

                    {/* container for error msgs */}
                    <div className="messages"></div>

                    <form id="form-flight" className="form-flight d-flex flex-direction-column mb-50 p-30">
                      <p className="text-center mb-40">Ingresa tus datos para consultar disponibilidad</p>
                      <div className="input-group d-block">
                        <label>Clave de Reservación</label>
                        <input
                          id="cpr-dup-fdf-inp-res"
                          name="pnr"
                          className="form-control mb-30"
                          placeholder="Clave de Reservación"
                          maxLength="6"
                          required
                          data-parsley-required-message="Campo requerido"
                          data-parsley-pattern="/[a-zA-Z0-9]+$/"
                          data-parsley-pattern-message="Solamente letras y números"
                          data-parsley-trigger="input focus"
                        />
                      </div>
                      <div className="input-group d-block">
                        <label>Apellidos</label>
                        <input
                          id="cpr-dup-fdf-inp-lnm"
                          name="apellidos"
                          className="form-control mb-30"
                          placeholder="Apellidos"
                          required
                          data-parsley-required-message="Campo requerido"
                          data-parsley-pattern="/[A-Za-z áéíóúüâêîôûçÁÉÍÓÚÜÂÊÎÔÛÇ]$/"
                          data-parsley-pattern-message="No se permiten carateres especiales" 
                          data-parsley-trigger="input focus"
                          />
                      </div>
                      <button id="cpr-dup-fdf-btn-sub" className="btn btn-primary mt-30" disabled>Consultar disponibilidad</button>
                    </form>
                    <h4 className="text-center">Si tienes dudas o necesitas información adicional de tu Ascenso, comunícate a nuestro Call Center (55) 1519 4040.</h4>
                  </div>
                </div>
                {/* </div> */}
              {/* Ends Step1 */}
              </div>

              <div id="step2" className="step2 has-loader d-none">
              {/* Breadcrumbs */}
                <div className="breadcrumbs">
                  <div className="hr"></div>
                  <div className="crumbs-container">
                    <div className="crumb -active">
                      <div className="number">1</div>
                      <div className="label">Consulta</div>
                    </div>
                    <div className="crumb -active">
                      <div className="number">2</div>
                      <div className="label">Selecciona</div>
                    </div>
                    <div className="crumb">
                      <div className="number">3</div>
                      <div className="label">Resúmen</div>
                    </div>
                    <div className="crumb">
                      <div className="number">4</div>
                      <div className="label">Confirmación</div>
                    </div>
                  </div>
                </div>

                {/* Flight information data table */}
                <div className="mb-50">
                  <h3 className="title text-center mb-50">Selecciona tus Ascensos Dinámicos</h3>
                  <p className="subtitle mb-30">A continuación encontrarás  las opciones de ascenso y los detalles de tu vuelo de acuerdo a la disponibilidad. Selecciona el vuelo en el que deseas realizar el Ascenso para todos los pasajeros de tu reservación.</p>
                  {/* Flight information container */}
                  <div className="flights"></div>
                </div>
                {/* Ends Flight data from search */}

                {/* Selectable upgrades & account point data */}
                {/* point data */}
                <div className="point-data d-flex">
                  <div className="headers d-flex">
                    <div>No. de Cuenta</div>
                    <div>Saldo Puntos Aeroméxico Rewards</div>
                    <div>Puntos a Utilizar</div>
                    <div>Saldo Restante</div>
                  </div>
                  <div className="data d-flex">
                    <div className="account"></div>
                    <div className="balance"></div>
                    <div className="cost"></div>
                    <div className="remainder"></div>
                  </div>
                </div>

                {/* user messages container */}
                <div className="messages d-flex justify-content-center"></div>

                {/* upgrade list */}
                <div className="upgrades mt-40">
                  <div className="upgrade-list"></div>
                  <div className="totals">
                    <div className="title">
                      Resto a pagar
                    </div>
                    <div className="total">-</div>
                  </div>
                </div>
                {/* END Selectable upgrades data */}

                {/* Upgrade Buttons */}
                <div className="upgrades-buttons">
                  <button id="cpr-dup-upg-btn-can" className="btn btn-secondary">Regresar</button>
                  <button id="cpr-dup-upg-btn-sub" className="btn btn-primary" disabled>Continuar</button>
                </div>
              {/* Ends Step2 */}
              </div>

              <div id="step3" className="step3 d-none">
                {/* Breadcrumbs */}
                <div className="breadcrumbs">
                  <div className="hr"></div>
                  <div className="crumbs-container">
                    <div className="crumb -active">
                      <div className="number">1</div>
                      <div className="label">Consulta</div>
                    </div>
                    <div className="crumb -active">
                      <div className="number">2</div>
                      <div className="label">Selecciona</div>
                    </div>
                    <div className="crumb -active">
                      <div className="number">3</div>
                      <div className="label">Resúmen</div>
                    </div>
                    <div className="crumb">
                      <div className="number">4</div>
                      <div className="label">Confirmación</div>
                    </div>
                  </div>
                </div>

                {/* Header Row */}
                <div className="header">
                  <img src="https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/dynamic-ups/headers/flight-data-form.svg"></img>
                  <h1><span>Resúmen de compra</span> <span>Ascensos Dinámicos</span></h1>
                </div>

                {/* content row */}
                <div className="purchase-review">
                  <h3 className="title text-center mb-5">A continuación encontrarás los detalles de tu Ascenso y los Puntos requeridos</h3>

                    {/* Selectable upgrades & account point data */}
                      {/* point data */}
                      <div className="cloned-point-data"></div>

                      {/* upgrade list */}
                      <div className="cloned-upgrade-list"></div>
                    {/* END Selectable upgrades data */}

                    <div className="purchase-form mt-40">
                      {/* Terms and conditions validation chkbx */}
                      <div className="d-flex justify-content-center">
                        <div className="terms-validation custom-control custom-checkbox">
                          <input id="cpr-dup-trm-chk" type="checkbox" className="custom-control-input" />
                          <label className="custom-control-label" htmlFor="cpr-dup-trm-chk">
                            He leído y acepto los <a id="cpr-dup-trm-lnk-st3" target="_blank" href="#">Términos y Condiciones.</a>
                          </label>
                        </div>
                      </div>
                      {/* Purchase buttons */}
                      <div className="purchase-buttons">
                        <button id="cpr-dup-cnf-btn-can" className="btn btn-secondary">Regresar</button>
                        <button id="cpr-dup-cnf-btn-sub" className="btn btn-primary" disabled>Adquirir</button>
                      </div>
                    </div>
                  
                  
                  {/* </div> */}
                </div>
              {/* Ends Step3 */}
              </div>

              <div id="step4" className="step4 mb-50 d-none">
                {/* Breadcrumbs */}
                <div className="breadcrumbs">
                  <div className="hr"></div>
                  <div className="crumbs-container">
                    <div className="crumb -active">
                      <div className="number">1</div>
                      <div className="label">Consulta</div>
                    </div>
                    <div className="crumb -active">
                      <div className="number">2</div>
                      <div className="label">Selecciona</div>
                    </div>
                    <div className="crumb -active">
                      <div className="number">3</div>
                      <div className="label">Resúmen</div>
                    </div>
                    <div className="crumb">
                      <div className="number -active">4</div>
                      <div className="label">Confirmación</div>
                    </div>
                  </div>
                </div>
                <div className="header">
                  <img src="https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/dynamic-ups/headers/succesful-purchase.svg"></img>
                  <h1>Transacción realizada con éxito</h1>
                </div>
                <div className="mb-50">
                  <div className="final-confirmation">
                    <h3 className="title py-5">¡Tu Ascenso está listo!</h3>
                    <p className="subtitle">El Ascenso de tu vuelo se ha realizado con éxito. Hemos enviado a tu cuenta de correo ja**************@****ail.com la información de tu reservación actualizada.</p>

                    {/* purchase-confirmation repeater */}
                    <div className="purchase-confirmation"></div>
                  
                    <div className="select-seats mb-50">
                      <div className="link text-center">
                        <p>Te invitamos a seleccionar tus asientos en <a id="cpr-dup-end-lnk-sea" target="_blank" href="#">Aeromexico.com</a>, recuerda tener a la mano la información de la reservación.</p>
                      </div>
                      <div className="button text-center">
                        <a id="cpr-dup-end-btn-sea" tartet="_blank" href="#" className="btn btn-primary">Seleccionar mis asientos</a>
                      </div>
                    </div>
                  </div>
                </div>
              {/* Ends Step4 */}
              </div>

              <div id="step5" className="step5 d-none">
                <div className="row mb-50">
                  <div className="col-12">
                    <div className="header">
                      <img src="https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/dynamic-ups/headers/purchase-error.svg"></img>
                      <h1>Algo salió mal</h1>
                    </div>

                    <h3 className="title my-5">No fue posible realizar la transacción</h3>
                    <p className="subtitle text-center">Por favor inténtalo más tarde.</p>

                    <div className="start-over">
                      <a id="cpr-dup-res-lnk-end" className="btn btn-primary" href="#" target="_blank">Ir al Inicio</a>
                    </div>

                  </div>
                </div>
                {/* Ends Step5 */}
              </div>
            {/* End Group steps */}
            </div>


            {/* Terms and conditions */}
            <div className="terms -pt pb-60 d-none">
              <div className="d-flex content_collapse justify-content-center">
                <div className="col-11 col-sm-8 p-0">
                  <div className="accordion" id="accordion-tc30">
                    <div className="card card-collapsePrimary  ">
                      <div>
                        <h4 id="cpr-dup-trm-lnk" className="mb-0 card-header" data-toggle="collapse" data-target="#collapse30" aria-expanded="false" aria-controls="collapseOne">
                          <p>Consulta aquí términos y condiciones</p>
                          <i className="fas fa-lg fa-angle-down ml-auto"></i>
                        </h4>
                      </div>
                      <div id="collapse30" className="collapse" aria-labelledby="headingOne" data-parent="#accordion-tc30">
                        <hr className="dividerCollapse"/>
                        <div className="card-body">

                          <p><b>Adquisición de Ascensos a Clase Premier</b></p>
                          <p><b>Términos y condiciones</b></p>
                          <p>Usted declara reconocer y aceptar los presentes “Términos y Condiciones de adquisición de Ascensos a Clase Premier”.</p>
                          <p><b>Uso</b></p>
                          <p>Los Socios Aeroméxico Rewards (los “Socios” o el “Socio”) podrán adquirir un Ascenso del servicio de Clase Turista comprada originalmente para viajar con Aeroméxico o Aeroméxico Connect, a la Clase Premier ("Ascenso") con Puntos Aeroméxico Rewards conforme a lo establecido en los presentes Términos y Condiciones.</p>
                          <p>Para poder adquirir el ascenso con Puntos Aeroméxico Rewards se deberá:</p>
                          <ul>
                            <li>Ser Socio Aeroméxico Rewards de no serlo se puede inscribir gratis en <a href="https://member.clubpremier.com/registro">https://member.clubpremier.com/registro</a></li>
                            <li>Haber adquirido un vuelo con Aeroméxico o Aeroméxico Connect en Clase Turista (Tarifas participantes) con dinero o con Puntos Aeroméxico Rewards (Boleto Premio)</li>
                            <li>Asociar tu correo electrónico a tu Cuenta Aeroméxico Rewards</li>
                            <li>Adquirir el Ascenso redimiendo los Puntos Aeroméxico Rewards en <a href="https://member.clubpremier.com/ascensos">https://member.clubpremier.com/ascensos</a></li>
                            <li>Conservar copia de los boletos de avión para posibles aclaraciones.</li>
                          </ul>
                          <p><b>Vigencia</b></p>
                          <p>La vigencia de los presentes Términos y Condiciones es indefinida. Aeroméxico Rewards tiene la facultad de cancelar la presente promoción en cualquier momento y sin previo aviso.</p>
                          <p><b>Restricciones</b></p>
                          <ul>
                            <li>El Ascenso no aplicará a vuelos de código compartido o para vuelos operados por una aerolínea diferente de Aeroméxico.</li>
                            <li>El ascenso deberá adquirirse con Puntos Aeroméxico Rewards hasta 4 (cuatro) horas antes del vuelo y siempre y cuando haya disponibilidad en Cabina Premier para el vuelo.</li>
                          </ul>
                          <ul>
                            <li>El Ascenso es sobre clase F sujeto a disponibilidad.</li>
                            <li>Para los siguientes boletos no aplica la redención del Ascenso: 
                              <ul> a. Boletos en clases de servicio P, G, X. <br />b. Recompensa por afectación. <br />c. Boletos aéreos gratuitos (donaciones, sin cargo y otros). <br />d. Boletos de prestación para empleados, Pases o Unitickets. <br /> </ul>
                            </li>
                            <li>Si adquieres un Ascenso a la Clase Premier, tendrás el derecho a usar los siguientes servicios y beneficios: <ul> a. Mayor espacio y comodidad en su asiento. <br />b. Documentación en los mostradores SkyPriority. <br />c. Prioridad en ascenso y descenso del vuelo. <br />d. Prioridad en el manejo del equipaje. <br />e. Franquicia de equipaje de Clase Premier. <br />f. Alimentos de mayor calidad (no aplica en todas las rutas). <br /> </ul>
                            </li>
                            <li>Si adquieres un Ascenso, las condiciones del boleto aéreo original seguirán teniendo vigencia, con excepción de la franquicia permitida de equipaje. Esto incluye condiciones de: estadías mínimas y máximas, cancelación, penalidades y cambios.</li>
                            <li>En caso de que aplique acumulación de Puntos Aeroméxico Rewards, los Puntos Aeroméxico Rewards se acumularán conforme a la clase original y no conforme a la clase a la que se ascendió, sujeto a los Términos y Condiciones de dicho programa.</li>
                            <li>Al adquirir un Ascenso, Aeroméxico no puede garantizar un asiento específico en la Clase Premier. Su número de asiento dependerá de la disponibilidad de espacio.</li>
                            <li>Una vez realizada la redención de Puntos Aeroméxico Rewards por el Ascenso estos son: no reembolsables, no transferible y no endosable.</li>
                            <li>En caso de que Aeroméxico cancele su vuelo, se hará todo lo posible por brindarle espacio en Clase Premier en el vuelo en que se le dé protección para la misma ruta (sujeto a disponibilidad). Si no es posible ofrecerte espacio en la Clase Premier, el pasajero viajará en la clase comprada originalmente y el Ascenso será reembolsable comunicándose al call center de Aeroméxico Rewards.</li>
                            <li>Si el pasajero cambia, cancela o pierde su vuelo por causas ajenas a la aerolínea, o por caso fortuito o fuerza mayor, no habrá reembolso por el cobro de su Ascenso, aun cuando su boleto aéreo original sea reembolsable. Este Ascenso no se puede transferir a otro vuelo u otro pasajero.</li>
                            <li>No aplica el acceso a Salón Premier.</li>
                            <li>Si hay varios pasajeros en tu reservación, deberás adquirir el Ascenso para todos los pasajeros en la reservación por segmento.</li>
                          </ul>
                          <p><b>Fraudes</b></p>
                          <p>Aeroméxico Rewards se reserva el derecho a llevar a cabo acciones que considere pertinentes en caso de detectar fraudes, conductas fraudulentas o malas prácticas por parte de los Socios en las Cuentas Aeroméxico Rewards.</p>
                          <p><b>Términos y Condiciones Generales</b></p>
                          <p>Los Puntos Aeroméxico Rewards, estarán sujetos a las reglas del Programa Aeroméxico Rewards, mismos que se encuentran disponibles en “La Página”.</p>
                          <p>Aeroméxico Rewards se reserva el derecho de modificar los Planes, el monto, así como los Beneficios en cualquier momento, sin previo aviso.</p>
                          <p>La inscripción a Aeroméxico Rewards no supone garantía de disponibilidad de ningún premio del Programa Aeroméxico Rewards, no siendo responsable Aeroméxico Rewards de que el Socio pueda o no utilizar, canjear o redimir los Puntos Aeroméxico Rewards acumulados por la inscripción a Aeroméxico Rewards.</p>
                          <p>En caso de que cualquier inscripción a Aeroméxico Rewards sea utilizada por los Socios para fines impropios, u obtenida de forma indebida de una Tarjeta Bancaria, fraude o cualquier otro medio ilegal, Aeroméxico Rewards podrá, según su criterio, cancelar, anular o denegar la entrega y/o confiscar tales, así como perseguir todos y cada uno de los derechos y recursos que pueda haber disponibles.</p>
                          <p>El Socio acuerda cooperar con todas las solicitudes razonables de Aeroméxico Rewards encaminadas a la investigación o interposición de acciones judiciales contra cualquier implicado o sospechoso de estarlo en la utilización indebida o fraude con respecto a la inscripción al Programa Aeroméxico Rewards, así como a conductas fraudulentas o malas prácticas por parte de los Socios en las Cuentas Aeroméxico Rewards.</p>
                          <p>El Socio acepta que el premio y utilización de los Puntos Aeroméxico Rewards, están sujetos a los términos, condiciones, exclusiones y limitaciones del Programa Aeroméxico Rewards.</p>
                          <p>Los Puntos Aeroméxico Rewards referidos en el Programa Aeroméxico Rewards no son Puntos físicos reales sino representación de créditos en el Programa Aeroméxico Rewards. Dichos Puntos no son equivalentes ni convertibles a moneda corriente en efectivo.</p>
                          <p><b>Datos Personales</b></p>
                          <p>Todo tratamiento de datos personales realizado por Aeroméxico Rewards, así como el ejercicio de los Derechos ARCO por parte de los Socios, será efectuado conforme a la Ley y Reglamento aplicable en los Estados Unidos Mexicanos, así como en sujeción a lo establecido en el Aviso de Privacidad de Aeroméxico Rewards, el cual usted aceptó previo a su registro en Aeroméxico Rewards (para consulta del Aviso de Privacidad por favor ingresar a <a href="http://www.clubpremier.com">www.clubpremier.com</a>)</p>
                          <p>Atendiendo a lo señalado en la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita y derivado de ciertas actividades realizadas por los Socios, las cuales sean consideradas por la Ley como actividades vulnerables, Aeroméxico Rewards compartirá sus Datos Personales con las autoridades correspondientes.</p>
                          <p><b>Lavado de Dinero</b></p>
                          <p>Al aceptar y participar en la presente, usted otorga expresamente autorización para que Aeroméxico Rewards transfiera sus datos de contacto (nombre, número de socio, número telefónico, dirección de correo electrónico) a Aerovías de México, S.A. de C.V., así como a terceros subcontratados por Aeroméxico Rewards a efecto de otorgar los beneficios de la presente promoción, mismos datos que también podrán ser transferidos a las empresas que para tal efecto determine Aeroméxico Rewards (incluyendo, sin limitar, cadenas hoteleras, aerolíneas, servicios de transportación terrestre y similares).</p>
                          <p>La información que derive de los Avisos que se presenten ante las autoridades competentes será utilizada exclusivamente para la prevención, identificación, investigación y sanción de operaciones con recursos de procedencia ilícita y demás delitos relacionados con éstas. Los datos personales antes mencionados se mantendrán en resguardado absoluto, en los términos de la Constitución Política de los Estados Unidos Mexicanos.</p>
                          <p><b>Jurisdicción</b></p>
                          <p>Los presentes Términos y Condiciones, en relación a su interpretación o cumplimiento, se regirán por la Ley y jurisdicción de los Tribunales competentes de la Ciudad de México, renunciando desde ahora a cualquier otro fuero que, por razón de materia o domicilio, presente o futuro, pudiera corresponderles.</p>
                          <p>En caso de existir alguna duda o aclaración respecto a los presentes términos y condiciones, por favor contáctanos a través Centro de Atención Telefónica de Aeroméxico Rewards (55 1519 4040) o al correo electrónico: <a href="mailto:soporte@clubpremier.com">soporte@clubpremier.com</a></p>

                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* END Terms and conditions */}
            </div>

          {/* End .container-body */}
          </div> 
        {/* End view */}
        </div>
      </div>  
    );
  }
}
export default UpgradesDynamicAncilliaries;