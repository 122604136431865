import React from "react";
class AlertsAndTooltips extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>ALETRS & TOOLTIPS</h1>
                <div className="alert mb-0">
                    <h4>
                        Las alertas son elementos que nos ayudan a notificar al usuario sobre el status de alguna acción realizada dentro de la interfaz. Puenden ser de diversos tipos. A continuación se enlistan algunas ejemplos.
                    </h4>
                </div>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0">
                    <h3>Tipos de alerts</h3>
                    <h5 className="h5-etiqueta">Success</h5>
                    <div className="row p-30-guia">
                        <div className="alert alert-view col-12 col-md-7 mb-0 p-30-guia">
                            <div className="row row-basic">
                                <div className="alert alert-success" role="alert">
                                    <div className="row row-basic">
                                        <i className='fas fa-check-circle'/>
                                        <h5>H5 - Success</h5>
                                        <i className='fas fa-times'/>
                                    </div>
                                    <div className="row row-basic">
                                        <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </p>
                                    </div>
                                </div>  
                            </div>    
                        </div>
                        <div className="alert alert-view-code col-12 col-md-5 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="alert alert-success" role="alert"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="row row-basic"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class='fas fa-check-circle'/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;H5 - Success&lt;/h5&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class='fas fa-times'/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="row row-basic"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>                  
                        </div>                  
                    </div>
                    <h5 className="h5-etiqueta">Danger</h5>
                    <div className="row p-30-guia">
                        <div className="alert alert-view col-12 col-md-7 mb-0 p-30-guia">
                            <div className="row row-basic">
                                <div className="alert alert-danger" role="alert">
                                    <div className="row row-basic">
                                        <i className='fas fa-times-circle'></i><h5>H5 - Danger</h5><i className='fas fa-times'></i>
                                    </div>
                                    <div className="row row-basic">
                                        <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </p>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="alert alert-view-code col-12 col-md-5 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="alert alert-danger" role="alert"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="row row-basic"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class='fas fa-times-circle'/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;H5 - Success&lt;/h5&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class='fas fa-times'/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="row row-basic"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>                  
                        </div>                  
                    </div>
                    <h5 className="h5-etiqueta">Warning</h5>
                    <div className="row p-30-guia">
                        <div className="alert alert-view col-12 col-md-7 mb-0 p-30-guia">
                            <div className="row row-basic">
                                <div className="alert alert-warning" role="alert">
                                    <div className="row row-basic">
                                        <i className='fas fa-exclamation-triangle'/>
                                        <h5>H5 - Warning</h5>
                                        <i className='fas fa-times'/>
                                    </div>
                                    <div className="row row-basic">
                                        <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </p>
                                    </div>
                                </div>  
                            </div>    
                        </div>
                        <div className="alert alert-view-code col-12 col-md-5 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="alert alert-success" role="alert"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="row row-basic"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class='fas fa-exclamation-triangle'/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;H5 - Warning&lt;/h5&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class='fas fa-times'/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="row row-basic"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>                  
                        </div>                  
                    </div>  
                    <h5 className="h5-etiqueta">Info</h5>
                    <div className="row p-30-guia">
                        <div className="alert alert-view col-12 col-md-7 mb-0 p-30-guia">
                            <div className="row row-basic">
                                <div className="alert alert-info" role="alert">
                                    <div className="row row-basic">
                                        <i className='fas fa-info-circle'/>
                                        <h5>H5 - Info</h5>
                                        <i className='fas fa-times'/>
                                    </div>
                                    <div className="row row-basic">
                                        <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </p>
                                    </div>
                                </div>  
                            </div>    
                        </div>
                        <div className="alert alert-view-code col-12 col-md-5 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="alert alert-info" role="alert"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="row row-basic"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class='fas fa-info-circle'/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5&gt;H5 - Info&lt;/h5&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class='fas fa-times'/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="row row-basic"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>                  
                        </div>                  
                    </div>
                </div>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0">
                    <h3>Tooltips</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                            Un tooltip o globo (también llamada descripción emergente) es una herramienta de ayuda visual para nuestro usuario. Aparece generalmente cuando el usuario desliza el cursor sobre un símbolo de ayuda.
                        </h4>
                        <h5 className="h5-etiqueta">Info</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-5 mb-0 p-30-guia" style={{paddingRight: '40px', minHeight: '200px'}}>
                                <div className="row row-basic">
                                    <div className="input-group">
                                        <label>Label</label>
                                        <input className="form-control" id="example-2" placeholder="Input text" required/>
                                        <div className="tooltip hint--bottom-left">
                                            <i className="fas fa-question-circle"></i>
                                            <div className="bottom">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua met, consectetur adipisicing elit, sed do eiusmod tempor incididunt. 
                                                </p>
                                                <i></i>
                                            </div>
                                        </div>
                                    </div>  
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-7 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="input-group"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;label&gt;Label&lt;/label&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;input class="form-control" id="example-2" placeholder="Input text" required/&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;div class="tooltip hint--bottom-left"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class="fas fa-question-circle"&gt;&lt;/i&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="bottom"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;TEXTO.&lt;/p&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i&gt;&lt;/i&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>                  
                            </div>                  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default AlertsAndTooltips;
