import React from "react";
class ModalsAndCarrousel extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>Modals and carrousel</h1>
                <div className="alert mb-0">
                    <h4>
                    Las ventanas modal son un elemento utilizado para informar al usuario de alguna tarea, evento o solicitud. Representan un diálogo de cara al usuario lo cual nos permite enganchar al usuario sin la necesidad de llevarlo a una pantalla nueva.
                    </h4>
                </div>
            </div>
            <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Carrousel</h5>
            <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                <div className="alert alert-view col-12 p-30-guia">
                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">
                                    <div className="col-12 col-md-6 bg-divCP mb-2">
                                        <article className="col-10">
                                            <h1>Descubre Aeroméxico Rewards Business</h1>
                                            <p className="textCarrousel">Si tu empresa aún no forma parte de gyutryugtr gtrgtryugrt guytrgyutr gtrhrhgtr</p>
                                            <button className="btn btn-primary">Lorem ipsum dolor sit amet</button>
                                        </article>
                                    </div>
                                    <div className="col-6 bg-secondary">
                                        <img className="w-100 imgCarrousel" src="https://corpstylo.aeromexicorewards.com/images/photo-slide-home.png" alt="First slide"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-12 col-md-6 bg-divCP mb-2">
                                        <article className="col-10">
                                            <h1>Descubre Aeroméxico Rewards Business</h1>
                                            <p className="textCarrousel">Si tu empresa aún no forma parte de gyutryugtr gtrgtryugrt guytrgyutr gtrhrhgtr</p>
                                            <button className="btn btn-primary">Lorem ipsum dolor sit amet</button>
                                        </article>
                                    </div>
                                    <div className="col-6 bg-secondary">
                                        <img className="w-100 imgCarrousel" src="https://corpstylo.aeromexicorewards.com/images/photo-slide-acumServices.png" alt="First slide"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                    <code>
                        &lt;div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&lt;ol class="carousel-indicators"&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"&gt;&lt;/li&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li data-target="#carouselExampleIndicators" data-slide-to="1"&gt;&lt;/li&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&lt;/ol&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&lt;div class="carousel-inner"&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="carousel-item active"&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="row"&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-12 col-md-6 bg-divCP mb-2"&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;article class="col-10"&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h1&gt;TITULO.&lt;/h1&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="textCarrousel"&gt;TEXTO.&lt;/p&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button class="btn btn-primary"&gt;&lt;/button&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/article&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-6 bg-secondary"&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img class="w-100 imgCarrousel" src="" alt="First slide"/&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                        &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                        &lt;/div&gt;<br/>
                    </code>            
                </div> 
            </div> 
            <div className="alert alert-texto mb-0">
                <div className="alert alert-texto mb-0">
                    <h4>
                        NOTA: Por cuestion de espacio y logica de programacion la seccion de codigo del elemento "Carrousel" fue simplificada.
                    </h4>
                </div>
            </div>
            <div className="alert alert-subtitulo mb-0">
                <h3>Modals</h3>
                <div className="alert alert-texto mb-0">
                    <h4>
                        Las ventanas modal son un elemento utilizado para informar al usuario de alguna tarea, evento o solicitud. Representan un diálogo de cara al usuario lo cual nos permite enganchar al usuario sin la necesidad de llevarlo a una pantalla nueva.
                    </h4>
                    <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Modal informativo</h5>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                        <div className="alert alert-view col-12 p-30-guia">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
                                Launch demo modal
                            </button>
                            <div className="col-12 col-md-6">
                                <div className="modal info" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title" id="exampleModalLongTitle">Modal title
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <i className="fas fa-times"></i>
                                                    </button>
                                                </h4>
                                            </div>
                                            <div className="modal-body">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. At molestiae omnis ratione odio illum voluptas ex facere corrupti magni, iure autem non in reiciendis nulla, nam dolore error corporis tempora?
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                        <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="modal info" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="modal-dialog modal-dialog-centered" role="document"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="modal-content"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="modal-header"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4 class="modal-title" id="exampleModalLongTitle"&gt;Modal title<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button type="button" class="close" data-dismiss="modal" aria-label="Close"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class="fas fa-times"&gt;&lt;/i&gt;<br/>
                                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/button&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="modal-body"&gt;TEXTO.&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>            
                        </div> 
                    </div> 
                    <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Modal call to action</h5>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                        <div className="alert alert-view col-12 p-30-guia">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenterCTA">
                                Launch demo modal
                            </button>
                            <div className="col-12">
                                <div className="modal info" id="exampleModalCenterCTA" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title" id="exampleModalLongTitle">Modal title
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <i className="fas fa-times"></i>
                                                </button>
                                                </h4>
                                            </div>
                                            <div className="modal-body">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. At molestiae omnis ratione odio illum voluptas ex facere corrupti magni, iure autem non in reiciendis nulla, nam dolore error corporis tempora?
                                            </div>
                                            <div className="modal-footer">
                                                <div className="row col-12  pl-0 pr-0">
                                                    <div className="col-6 pl-0 pr-0">
                                                        <div className="custom-control custom-checkbox">
                                                            <input id="custom-check-1" className="custom-control-input" type="checkbox"/>
                                                            <label htmlFor="custom-check-1" className="custom-control-label">Checkbox vacío</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-0 pr-0">
                                                        <button className="btn btn-outline-primary mb-0">Lorem ipsum dolor sit am</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                        <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="modal info" id="exampleModalCenterCTA" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="modal-dialog modal-dialog-centered" role="document"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="modal-content"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="modal-header"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4 class="modal-title" id="exampleModalLongTitle"&gt;Modal title<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button type="button" class="close" data-dismiss="modal" aria-label="Close"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class="fas fa-times"&gt;&lt;/i&gt;<br/><br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/button&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="modal-body"&gt;TEXTO.&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="modal-footer"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="row col-12  pl-0 pr-0"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-6 pl-0 pr-0"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="custom-control custom-checkbox"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;input id="custom-check-1" class="custom-control-input" type="checkbox"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;label for="custom-check-1" class="custom-control-label"&gt;Checkbox vacío&lt;/label&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-6 pl-0 pr-0"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button class="btn btn-outline-primary mb-0"&gt;Lorem ipsum dolor sit am&lt;/button&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>            
                        </div> 
                    </div> 
                </div>
            </div> 
        </div>
    );
  }
}
export default ModalsAndCarrousel;
