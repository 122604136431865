import React from "react";
class Iconos extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div class="container-fluid container-p">
            <div class="alert alert-titulo mb-0 col-12">
                <h1>Íconos</h1>
            </div>
            <div class="alert mb-0">
                <div class="alert alert-subtitulo mb-0" style={{marginBottom: '30px'}}>
                    <h3>Default</h3>
                    <div class="alert alert-texto mb-0" style={{height: '400px'}}>
                        <h4>
                            Aeroméxico Rewards cuenta con un set de íconos diseñados para comunicar aspectos y beneficios especificos de la marca.  Son una herramienta dentro de nuestro sistema diseño que nos ayuda a comunicar de manera accesible los beneficios y propuesta de valor. No podrán ser modificados en cuanto a su estructura, podemos variar tamaño y color.
                        </h4>
                        <div class="alert mb-0 p-0">
                            <div class="row row-basic alert alert-view" style={{margin: '15px'}}>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#actividades'}> </use>
                                    </svg>
                                    <small class="d-block">#actividades</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#arrendadoras'}> </use>
                                    </svg>
                                    <small class="d-block">#arrendadoras</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#aventura'}> </use>
                                    </svg>
                                    <small class="d-block">#aventura</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#bancos'}> </use>
                                    </svg>
                                    <small class="d-block">#bancos</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#cine'}> </use>
                                    </svg>
                                    <small class="d-block">#cine</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#comparte'}> </use>
                                    </svg>
                                    <small class="d-block">#comparte</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#compra-puntos'}> </use>
                                    </svg>
                                    <small class="d-block">#compra-puntos</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#cruceros'}> </use>
                                    </svg>
                                    <small class="d-block">#cruceros</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#entretenimiento'}> </use>
                                    </svg>
                                    <small class="d-block">#entretenimiento</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#especiales-premier'}> </use>
                                    </svg>
                                    <small class="d-block">#especiales-premier</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#experiencias'}> </use>
                                    </svg>
                                    <small class="d-block">#experiencias</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#experiencias-sala'}> </use>
                                    </svg>
                                    <small class="d-block">#experiencias-sala</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#gasolineras'}> </use>
                                    </svg>
                                    <small class="d-block">#gasolineras</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#gran-sorteo'}> </use>
                                    </svg>
                                    <small class="d-block">#gran-sorteo</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#hoteles'}> </use>
                                    </svg>
                                    <small class="d-block">#hoteles</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#invita-a-un-amigo'}> </use>
                                    </svg>
                                    <small class="d-block">#invita-a-un-amigo</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#Mall'}> </use>
                                    </svg>
                                    <small class="d-block">#Mall</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#multiplica'}> </use>
                                    </svg>
                                    <small class="d-block">#multiplica</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#musica'}> </use>
                                    </svg>
                                    <small class="d-block">#musica</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#actividades'}> </use>
                                    </svg>
                                    <small class="d-block">#no-definido1</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#actividades'}> </use>
                                    </svg>
                                    <small class="d-block">#no-definido2</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#actividades'}> </use>
                                    </svg>
                                    <small class="d-block">#no-definido3</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#actividades'}> </use>
                                    </svg>
                                    <small class="d-block">#no-definido4</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#actividades'}> </use>
                                    </svg>
                                    <small class="d-block">#no-definido5</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#actividades'}> </use>
                                    </svg>
                                    <small class="d-block">#no-definido6</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#no-definido7'}> </use>
                                    </svg>
                                    <small class="d-block">#no-definido7</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#otros'}> </use>
                                    </svg>
                                    <small class="d-block">#otros</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#paquetes'}> </use>
                                    </svg>
                                    <small class="d-block">#paquetes</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#productos'}> </use>
                                    </svg>
                                    <small class="d-block">#productos</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#recupera-puntos'}> </use>
                                    </svg>
                                    <small class="d-block">#recupera-puntos</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#salones-premier'}> </use>
                                    </svg>
                                    <small class="d-block">#salones-premier</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#seguro-de-viaje'}> </use>
                                    </svg>
                                    <small class="d-block">#seguro-de-viaje</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#seguros'}> </use>
                                    </svg>
                                    <small class="d-block">#seguros</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#servicios-de-viaje'}> </use>
                                    </svg>
                                    <small class="d-block">#servicios-de-viaje</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#servicios-financieros'}> </use>
                                    </svg>
                                    <small class="d-block">#servicios-financieros</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#subasta'}> </use>
                                    </svg>
                                    <small class="d-block">#subasta</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#tarjeta-de-regalo'}> </use>
                                    </svg>
                                    <small class="d-block">#tarjeta-de-regalo</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#taxi'}> </use>
                                    </svg>
                                    <small class="d-block">#taxi</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#tienda-en-linea'}> </use>
                                    </svg>
                                    <small class="d-block">#tienda-en-linea</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#tiendas-y-mas'}> </use>
                                    </svg>
                                    <small class="d-block">#tiendas-y-mas</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#transferencias'}> </use>
                                    </svg>
                                    <small class="d-block">#transferencias</small>
                                </div>
                                <div class="text-center col-12 col-sm-6 col-md-4 col-lg-3" style={{padding: '15px'}}>
                                    <svg class="color-icon">
                                        <use xlinkHref={'#vuelos'}> </use>
                                    </svg>
                                    <small class="d-block">#vuelos</small>
                                </div>
                            </div>
                        </div>
                        <h5 class="h5-etiqueta2" style={{marginBottom: '-15px'}}>Codigo Muestra</h5>
                        <div class="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="text-center"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;svg class="color-icon"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;use xlinkHref=&#123;'#name'&#125;&gt; &lt;/use&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/svg&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;small class="d-block"&gt;#name&lt;/small&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code> 
                        </div> 
                        <h5 class="h5-etiqueta" style={{marginBottom: '-40px'}}>Íconos font awesome</h5>
                        <div class="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                            <div class="alert alert-view p-30-guia">
                                <div class=" text-center row row-basic">
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-times-circle" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-check-circle" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-exclamation-triangle" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-info-circle" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-arrow-circle-up" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-arrow-circle-right" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-arrow-circle-left" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-arrow-circle-down" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-backspace" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-caret-square-up" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-caret-square-right" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-caret-square-left" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-caret-square-down" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-clock" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-check-square" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-hourglass" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-map" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-paw" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-image" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-images" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-map-signs" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-hospital" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-hospital-symbol" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-id-card" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-map-marker-alt" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-lock" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-lock-open" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-heart" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-location-arrow" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-envelope" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-cog" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-eye" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-concierge-bell" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-key" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-eye-slash" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-luggage-cart" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-headphones" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-hourglass-start" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-hourglass-half" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-arrow-up" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-arrow-down" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-arrow-right" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-arrow-left" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-check" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-angle-right" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-angle-left" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-exclamation" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-ellipsis-h" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-ellipsis-v" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-link" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-cut" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-registered" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-redo" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-pen" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-search" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-search-plus" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-search-minus" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-phone" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-question" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-question-circle" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-trash" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-times" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-plus" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-minus" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-align-left" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fas fas-basic fa-align-justify" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-facebook-f" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-twitter" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-linkedin-in" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-tumblr" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-telegram-plane" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-facebook-square" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-twitter-square" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-tumblr-square" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-linkedin" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-facebook" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-skype" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto', marginBottom: '15px'}}>
                                        <i class="fab fas-basic fa-telegram" style={{margin: 'auto'}}/>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto'}}/>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto'}}/>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto'}}/>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto'}}/>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto'}}/>
                                    <div class="col-3 col-sm-3 col-md-2 col-lg-1" style={{margin: 'auto'}}/>
                                </div>
                            </div>
                        </div>
                        <div class="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div class="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;i class="fas fas-basic fa-times-circle"/&gt;
                                </code>            
                            </div> 
                        </div>
                        <div class="alert alert-texto mb-0" style={{marginBottom: '30px'}}>
                            <div class="alert alert-texto mb-0">
                                <h7>
                                    NOTA: Por cuestion de estetica, se les agrego la clase "fas-basic", para obetener el tamaño y color mostrado.
                                </h7>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default Iconos;
