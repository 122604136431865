import React from "react";
class Dividers extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
            <h1>DIVIDERS</h1>
            </div>
            <div className="alert mb-0" style={{paddingTop: '0'}}>
                <div className="alert alert-subtitulo mb-0" style={{paddingTop: '0'}}>  
                <h3>Tipos</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                            Los dividers son un elemento que nos permiten separar la información conforme 
                        </h4>
                        <h5 className="h5-etiqueta">Divider A</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row row-basic" style={{width: '100%', height: '100%'}}>
                                    <div style={{minHeight: '75px', width: '100%'}}>
                                        <div className="dividers-a">
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div className="dividers-a"&gt;<br/>
                                    &lt;/div&gt;
                                </code>                  
                            </div>       
                        </div>
                        <h5 className="h5-etiqueta">Divider B</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row row-basic" style={{width: '100%', height: '100%'}}>
                                    <div style={{minHeight: '75px', width: '100%'}}>
                                        <div className="dividers-b" style={{border: '1px solid #555555'}}>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="dividers-b"&gt;<br/>
                                    &lt;/div&gt;
                                </code>                  
                            </div>       
                        </div>
                        <h6>
                            Nota: el borde del Divider B, solo es usado de apoyo.
                        </h6>
                        <h5 className="h5-etiqueta">Divider C</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row row-basic" style={{width: '100%', height: '100%'}}>
                                    <div style={{minHeight: '75px', width: '100%'}}>
                                        <div className="dividers-c">
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div className="dividers-c"&gt;<br/>
                                    &lt;/div&gt;
                                </code>                  
                            </div>       
                        </div>
                        <h5 className="h5-etiqueta">Divider D</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row row-basic" style={{width: '100%', height: '100%'}}>
                                    <div style={{minHeight: '75px', width: '100%'}}>
                                        <div className="dividers-d">
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div className="dividers-d"&gt;<br/>
                                    &lt;/div&gt;
                                </code>                  
                            </div>       
                        </div>
                        <h5 className="h5-etiqueta">Divider E</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row row-basic" style={{width: '100%', height: '100%'}}>
                                    <div style={{minHeight: '75px', width: '100%'}}>
                                        <div className="dividers-e">
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div className="dividers-e"&gt;<br/>
                                    &lt;/div&gt;
                                </code>                  
                            </div>       
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default Dividers;