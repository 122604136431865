//constantes pagina
export const CARDS="/cards";
export const DIVIDERS="/dividers";
export const FORMS="/forms";
export const NAVBAR="/navbar";
export const ICONOGRAPHY="/iconography";
export const ICONOS="/iconos";
export const ILLUSTRATIONS="/illustrations";
export const LOGOS="/logos";
export const MODALS_AND_CARROUSEL="/modals_and_carrousel";
export const PROGRESS_INDICATORS="/progress_indicators";
export const TABLES_AND_COLLAPSE="/tables_and_collapse";
export const TITLES_AND_TEXT="/titles_and_text";
export const BUTTONS="/buttons";
export const COLOR_PALETTE="/color_palette";
export const APP="/";
export const ALERTS_AND_TOOLTIPS="/Alerts_and_tooltips";
export const FOOTER="/Footer";
export const GRID="/Grid";
export const LOGIN_CP="/login_cp";
export const ENROLLMENT_INIT="/enrollment_init";
export const ENROLLMENT_FORM="/enrollment_form";
export const HOME_MEMBER="/home_member";
export const CORPORATE_PROFILE="/corporate_profile";
export const ACCOUNT_STATEMENT="/account_statement";