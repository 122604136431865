import React from "react";
class Footer extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>FOOTER</h1>
                <div className="alert mb-0">
                    <h4>
                        El header representa un grupo de elementos de ayuda introductoria o de navegación. Este elemento responde a las necesidades primarias del sistema y también representa a la marca. La estructura básica del header de CPC es la siguiente:
                    </h4>
                </div>
            </div>
            <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Estructura header</h5>
            <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                <div className="alert alert-view col-12 p-30-guia">
                    <div className="container-fluid footer-wrapper">
                        <div className="row">
                            <div className="container site-footer">
                                <div className="row">
                                    <div className="col-md-3 contact-info">
                                        <h6 id="footer-contact-info">H6 - Atención al cliente</h6>
                                        <div className="row">
                                            <div className="col-7 col-md-12 col-lg-7">Link - Lorem ipsum dolor</div>
                                            <div className="col-5 col-md-12 col-lg-5"><a href="tel:1234567890">123 456 789 09</a></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-7 col-md-12 col-lg-7">Link - Lorem ipsum dolor</div>
                                            <div className="col-5 col-md-12 col-lg-5"><a href="tel:1234567890">123 456 789 09</a></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-7 col-md-12 col-lg-7">Link - Lorem ipsum dolor</div>
                                            <div className="col-5 col-md-12 col-lg-5"><a href="tel:1234567890">123 456 789 09</a></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-7 col-md-12 col-lg-7">Link - Lorem ipsum dolor</div>
                                            <div className="col-5 col-md-12 col-lg-5"><a href="tel:1234567890">123 456 789 09</a></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-7 col-md-12 col-lg-7">Link - Lorem ipsum dolor</div>
                                            <div className="col-5 col-md-12 col-lg-5"><a href="tel:1234567890">123 456 789 09</a></div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 text-md-left other-info">
                                        <h6>H6 - Envíanos un correo</h6>
                                        <p><a href="mailto:programa.corporativo@clubpremier.com" >programa.corporativo@clubpremier.com</a></p>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo odit beatae labore cum magni excepturi reprehenderit nam laudantium soluta et.</p>
                                    </div>
                                    <div className="col-md-3 other-info">
                                        <h6>H6 - Descarga nuestra app</h6>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo odit beatae labore cum magni excepturi reprehenderit nam laudantium soluta et.</p>
                                        <div className="row justify-content-center">
                                            <div className="col-auto col-md-12 col-lg-6 text-center">
                                                <p><a href="https://play.google.com/store/apps/details?id=com.clubpremier.loyalty.android"><img src="https://s3media.clubpremier.com/club_premier/img/footer/google-play-badge.svg" alt="Google Play" rel="noopener nofollow" target="_blank" /></a></p>
                                            </div>
                                            <div className="col-auto col-md-12 col-lg-6 text-center text-lg-right">
                                                <p><a href="https://itunes.apple.com/mx/app/club-premier/id592240448?mt=8"><img src="https://s3media.clubpremier.com/club_premier/img/footer/app-store-badge.svg" alt="App Store" rel="noopener nofollow" target="_blank"/></a></p>
                                            </div>
                                        </div>
                                        <h6 className="mt-40">h6 - Síguenos</h6>
                                        <div className="social-buttons">
                                            <a href="https://www.facebook.com/clubpremiermexico" target="_blank" class="social-link menu-item menu-item-type-custom menu-item-object-custom"><span class="fab fa-facebook-f"></span></a>
                                            <a href="https://www.linkedin.com/company/premier-loyalty-&-marketing/" target="_blank" class="social-link menu-item menu-item-type-custom menu-item-object-custom"><span class="fab fa fa-linkedin"></span></a>
                                        </div>
                                    </div>
                                    <div className="col-md-3 text-center text-md-left other-links">
                                        <p><a href="https://www.clubpremier.com/mx/acerca-de-club-premier/?webSyncID=6787bbed-72ed-b2a5-7a27-6c4688c8c6ea&amp;sessionGUID=e57d78a8-e86c-cb30-7ceb-325c3ee189c2">Link text - Acerca de Aeroméxico Rewards</a></p>
                                        <p><a href="https://www.clubpremier.com/mx/?webSyncID=6787bbed-72ed-b2a5-7a27-6c4688c8c6ea&amp;sessionGUID=e57d78a8-e86c-cb30-7ceb-325c3ee189c2">Link text - Preguntas frecuentes</a></p>
                                        <p><a href="https://www.clubpremier.com/mx/acerca-de-club-premier/administracion/?webSyncID=6787bbed-72ed-b2a5-7a27-6c4688c8c6ea&amp;sessionGUID=e57d78a8-e86c-cb30-7ceb-325c3ee189c2">Link text - Administración</a></p>
                                        <p><a href="https://amfacturacion.aeromexico.com/?_ga=2.263481548.1302231669.1535385513-385790940.1535385513">Link text - Facturación</a></p>
                                        <p><a href="https://www.clubpremier.com/mx/?webSyncID=6787bbed-72ed-b2a5-7a27-6c4688c8c6ea&amp;sessionGUID=e57d78a8-e86c-cb30-7ceb-325c3ee189c2">Link text - Comunicados</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default Footer;
