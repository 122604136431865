import React from 'react'

class HomeCms extends React.Component {
  render() {

    const imgStyle = {
      backgroundImage: "url(https://s3media.clubpremier.com/CPcms/23/CPC/hot-sale.jpg)"
    }
    
    return (
      <>
        {/* // This slider only is a layout, desen't have functionality */}
        <section className="container-fluid container-corporativo ">
          <section className="section-corporativo auto p-0">
            <div className="row carousel-descubre m-0 ">
              <div className="carousel slide  w-100 mb-0" data-ride="carousel">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 bg-divCP mb-2 p-0">
                      <article>
                        <h1 className="wow fadeInDownBig" style={{visibility: "visible", animationName: "fadeInDownBig"}}>Hot Sale</h1>
                        <p className="h1 wow fadeInDownBig" style={{visibility: "visible", animationName: "fadeInDownBig"}}>Este Hot Sale, tus viajes de Negocio con el 40% de descuento al adquirir tu Boleto Aeroméxico Rewards</p>
                        <a className="btn btn-primary position-md-absolute mb-30 wow fadeInUpBig" href="https://corporativo.clubpremier.com/mx/promociones/aeromexico/hot-sale?lang=es" style={{visibility: "visible", animationName: "fadeInUpBig"}}>Conoce más</a>
                      </article>
                    </div>
                    <div style={imgStyle} className="col-6 d-none d-md-block p-0 col-img wow fadeInDownBig">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
            
          <section className="section-corporativo auto">
            <div className="row grid-descubre section-corp">
              <div className="col-lg-6 bg-divCP bg-divCPBrown title-bullet-cont-fr wow fadeIn" data-wow-delay="0s" data-wow-offset="60" 
              style={{visibility: "visible", animationDelay: "0s", animationName: "fadeIn"}}>
                <article className="h-100">
                  <ul>
                    <li>
                      <h2 className="title-bullet">Aerolíneas</h2>
                    </li>
                  </ul>
                  <p>Aeroméxico Rewards Business es el Programa de Lealtad empresarial, con el cual acumulan Puntos Aeroméxico Rewards y obtienen beneficios exclusivos tanto la empresa como sus empleados.</p>
                  <p>Tu empresa acumula Puntos Aeroméxico Rewards por los vuelos que hagas con nuestras principales aerolíneas afiliadas, obtén de 4 a 6 puntos por dólar gastado con Aeroméxico y 50% de puntos por distancia volada con Delta, Air France y KLM.
                  </p>
                  <img className="img-fluid" src="https://corpstylo.clubpremier.info/ambusinessstyleguide/images/logo%20AMR%20Business.png" alt="" />
                </article>
              </div>
              <div className="col-lg-6">
                <div className="row h-100">
                  <div className="col-lg-6 h-md-50 p-0 wow fadeIn" data-wow-delay="0s" data-wow-offset="60" style={{visibility: "visible", animationDelay: "0s", animationName: "fadeIn"}}>
                    <div style={{backgroundColor: "#182743"}} className="h-100 grid-images d-none d-lg-flex">
                      <img className="img-fluid " src="https://s3media.clubpremier.com/CPcms/20cpc/logos/hm-aeromexico.png" alt="" />
                      <div className="imagen-tumb" style={{backgroundColor: "#182743"}}>
                        <div className="textLand">
                          <p>La aerolínea bandera de México con centro de conexiones en la Ciudad de México, Monterrey, Guadalajara y Hermosillo; realiza vuelos a las principales ciudades del interior de la República, Norte, Centro y Sudamérica, además de El Caribe, Europa y Asia.</p>
                          <a className="" href="https://corporativo.clubpremier.com/mx/acumula/aerolineas/aeromexico?lang=es">Saber más</a>
                        </div>
                      </div>
                    </div>
                    <a className="d-block d-lg-none" href="https://corporativo.clubpremier.com/mx/acumula/aerolineas/aeromexico?lang=es">
                      <div style={{backgroundColor: "#182743"}} className="h-100 grid-images">
                        <img className="img-fluid " src="https://s3media.clubpremier.com/CPcms/20cpc/logos/hm-aeromexico.png" alt="" />
                      </div>
                    </a>
                  </div>
                  
                  <div className="col-lg-6 h-md-50 p-0 wow fadeIn" data-wow-delay="1s" data-wow-offset="60" style={{visibility: "visible", animationDelay: "1s", animationName: "fadeIn"}}>
                    <div style={{backgroundColor: "#184076"}} className="h-100 grid-images d-none d-lg-flex">
                      <img className="img-fluid " src="https://s3media.clubpremier.com/CPcms/20cpc/logos/hm-delta.png" alt="" />
                      <div className="imagen-tumb" style={{backgroundColor: "#184076"}}>
                        <div className="textLand">
                          <p>Tus viajes de negocios entre México y Estados Unidos nunca fueron tan fáciles gracias a Aeroméxico y Delta. Descubre un mundo de oportunidades para viajar entre ambos países.</p>
                          <a className="" href="https://corporativo.clubpremier.com/mx/acumula/aerolineas/delta?lang=es">Saber más</a>
                        </div>
                      </div>
                    </div>
                    <a className="d-block d-lg-none" href="https://corporativo.clubpremier.com/mx/acumula/aerolineas/delta?lang=es">
                      <div style={{backgroundColor: "#184076"}} className="h-100 grid-images">
                        <img className="img-fluid " src="https://s3media.clubpremier.com/CPcms/20cpc/logos/hm-delta.png" alt="" />
                      </div>
                    </a>
                  </div>
                  
                  <div className="col-lg-6 h-md-50 p-0 wow fadeIn" data-wow-delay="0s" data-wow-offset="60" style={{visibility: "visible", animationDelay: "1s", animationName: "fadeIn"}}>
                    <div style={{backgroundColor: "#cb2229"}} className="h-100 grid-images d-none d-lg-flex">
                      <img className="img-fluid " src="https://s3media.clubpremier.com/CPcms/20cpc/logos/hm-airfrance.png" alt="" />
                      <div className="imagen-tumb" style={{backgroundColor: "#cb2229"}}>
                        <div className="textLand">
                          <p>Vuela hacia o desde México a las principales ciudades de Francia, Europa, África, Asia y Medio Oriente.</p>
                          <a className="" href="https://corporativo.clubpremier.com/mx/acumula/aerolineas/air-france?lang=es">Saber más</a>
                        </div>
                      </div>
                    </div>
                    <a className="d-block d-lg-none" href="https://corporativo.clubpremier.com/mx/acumula/aerolineas/air-france?lang=es">
                      <div style={{backgroundColor: "#cb2229"}} className="h-100 grid-images">
                        <img className="img-fluid " src="https://s3media.clubpremier.com/CPcms/20cpc/logos/hm-airfrance.png" alt="" />
                      </div>
                    </a>
                  </div>
                  
                  <div className="col-lg-6 h-md-50 p-0 wow fadeIn" data-wow-delay="1s" data-wow-offset="60" style={{visibility: "visible", animationDelay: "1s", animationName: "fadeIn"}}>
                    <div style={{backgroundColor:"#00a1de"}} className="h-100 grid-images d-none d-lg-flex">
                      <img className="img-fluid " src="https://s3media.clubpremier.com/CPcms/20cpc/logos/hm-klm.png" alt="" />
                      <div className="imagen-tumb" style={{backgroundColor:"#00a1de"}}>
                        <div className="textLand">
                          <p>Vuela hacia o desde México a las principales ciudades de Europa, África, Asia y Medio Oriente.</p>
                          <a className="" href="https://corporativo.clubpremier.com/mx/acumula/aerolineas/klm?lang=es">Saber más</a>
                        </div>
                      </div>
                    </div>
                    <a className="d-block d-lg-none" href="https://corporativo.clubpremier.com/mx/acumula/aerolineas/klm?lang=es">
                      <div style={{backgroundColor:"#00a1de"}} className="h-100 grid-images">
                        <img className="img-fluid " src="https://s3media.clubpremier.com/CPcms/20cpc/logos/hm-klm.png" alt="" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>  

        <section className="section-corporativo bg-westar">
          <div className="row justify-content-center section-corp group-text-area wow fadeIn" style={{visibility: "visible", animationName: "fadeIn"}}>
            <div className="title-bullet-cont col-md col-text">
              <ul>
                <li>
                  <h2 className="title-bullet">Inscribe sin costo a tu empresa y recibe múltiples beneficios</h2>
                </li>
              </ul>
            </div>
          </div>
          
          <div className="justify-content-center columns-descubre section-corp d-none d-lg-flex mb-0">
            <div className="row justify-content-center col-12 card-row">
              
              <div className="col-md-5 card-column col-lg wow fadeIn" data-wow-delay="1s" data-wow-offset="0" style={{visibility: "visible", animationDelay: "3s", animationName: "fadeIn"}}>
                <div className="h-100">
                  <img src='https://corpstylo.clubpremier.info/ambusinessstyleguide/images/cms/Gift.svg' />
                  <div className="card-title mb-15" style={{height: "72.0469px"}}>
                    <h5>Recibe un ascenso a clase AM Plus</h5>
                  </div>
                  <div className="card-body mt-15">
                    <p>Agrega valor a tu viaje al ascender de categoría a un asiento “AM Plus Aeroméxico” con espacio adicional, así como abordaje y descenso prioritario.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-5 card-column col-lg wow fadeIn" data-wow-delay="1s" data-wow-offset="0" style={{visibility: "visible", animationDelay: "3s", animationName: "fadeIn"}}>
                <div className="h-100">
                  <img src='https://corpstylo.clubpremier.info/ambusinessstyleguide/images/cms/Trophy.svg' />
                  <div className="card-title mb-15" style={{height: "72.0469px"}}>
                    <h5>Gana muchos más Puntos por tus viajes de negocios</h5>
                  </div>
                  <div className="card-body mt-15">
                    <p>Ahora tu empresa gana más Puntos Aeroméxico Rewards al viajar con Aeroméxico obtén de 4 a 6 puntos por dólar gastado, Delta, Air France y KLM obtén 50% de puntos por distancia volada.</p>
                  </div>
                </div>
              </div>
              
              <div className="col-md-5 card-column col-lg wow fadeIn" data-wow-delay="2s" data-wow-offset="0" style={{visibility: "visible", animationDelay: "3s", animationName: "fadeIn"}}>
                <div className="h-100">
                  <img src='https://corpstylo.clubpremier.info/ambusinessstyleguide/images/cms/Star.svg' />
                  <div className="card-title mb-15" style={{height: "72.0469px"}}>
                    <h5>Descuento en la compra de Accesos o Membresía Salón Premier</h5>
                  </div>
                  <div className="card-body mt-15">
                    <p>Disfruta de la exclusividad y confort de los Salones y Terrazas Premier y obtén 50% de descuento en la compra de tu Acceso Único o Membresía Salón Premier.<br/></p>
                  </div>
                </div>
              </div>

              <div className="col-md-5 card-column col-lg wow fadeIn" data-wow-delay="2s" data-wow-offset="0" style={{visibility: "visible", animationDelay: "3s", animationName: "fadeIn"}}>
                <div className="h-100">
                  <img src='https://corpstylo.clubpremier.info/ambusinessstyleguide/images/cms/Plane.svg' />
                  <div className="card-title mb-15" style={{height: "72.0469px"}}>
                    <h5>Ahora tus Puntos Aeroméxico Rewards tienen una vigencia de 24 meses&nbsp;</h5>
                  </div>
                  <div className="card-body mt-15">
                    <p>Los Puntos de tu Cuenta Corporativa tendrán una vigencia de 24 meses. Utilízalos para viajar a donde tú quieras con Aeroméxico, Delta, Air France y KLM.<br/></p>
                  </div>
                </div>
              </div>
              
              <div className="col-md-5 card-column col-lg wow fadeIn" data-wow-delay="3s" data-wow-offset="0" style={{visibility: "visible", animationDelay: "3s", animationName: "fadeIn"}}>
                <div className="h-100">
                  <img src='https://corpstylo.clubpremier.info/ambusinessstyleguide/images/cms/Heart.svg' />
                  <div className="card-title mb-15" style={{height: "72.0469px"}}>
                    <h5>Gana más Puntos Aeroméxico Rewards con nuestras empresas afiliadas</h5>
                  </div>
                  <div className="card-body mt-15">
                    <p>Tu empresa también puede ganar Puntos Aeroméxico Rewards al utilizar los servicios de hoteles, arrendadoras y más empresas afiliadas.<br /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-corporativo bg-tangaroa">
          <div className="row form-registration section-corp mb-15">
            <div className="col-lg-6 p-0 mb-30 wow fadeIn" data-wow-delay="0s" data-wow-offset="20" style={{visibility: "visible", animationDelay: "0s", animationName: "fadeIn"}} >
              <article className="title-bullet-cont-fr">
                <ul>
                  <li>
                    <h2 className="title-bullet text-white">Inscribe a tu empresa sin costo hoy mismo y conoce los beneficios de Aeroméxico Rewards Business.</h2>
                  </li>
                </ul>
                <p className="mb-30 text-white">Para crear una Cuenta Corporativa es necesario asignar a un Administrador, quien debe ser Socio Aeroméxico Rewards.</p>
              </article>
              <article className="col-10 text-center m-center">
                <h3 className="mb-30 text-white">Si aun no eres Socio del programa individual, Inscríbete gratis</h3>
                <a className="btn btn-primary-dark btn-size-override" href="https://member.clubpremier.com/registro">Inscríbete 
                  <i className="fas fa-check fa-xs"></i>
                </a>
              </article>
            </div>
            
            <div className="col-md-9 col-lg-5 m-center p-0 wow fadeInUp" data-wow-offset="20" style={{visibility: "visible", animationName: "fadeInUp"}}>
              <div className="card">
                <div className="card-body p-35">
                  <h4 className="card-title text-center m-0">Si ya eres Socio, ingresa tus datos:</h4>
                  <p className="card-text text-md-center">El Administrador representa a la empresa en el programa Aeroméxico Rewards Business y tiene autorización para administrar la Cuenta Corporativa. </p>
                  <form id="formCorp" action="/corporativo/registro/confirma" method="post" data-parsley-validate="" noValidate="">
                    <div id="group-accountNumber" className="form-group">
                      <div className="input-group">
                        <label>No. de cuenta Aeroméxico Rewards</label>
                        <input className="form-control" id="accountNumber" name="accountNumber" data-parsley-errors-container="#group-accountNumber" data-parsley-trigger="keyup" placeholder="123456789" required="" data-parsley-required-message="Campo requerido" minLength="2" maxLength="11" data-parsley-length-message="Máximo 11 dígitos" data-parsley-luhn="" data-parsley-luhn-message="No. de cuenta inválido" data-parsley-type="digits" data-parsley-type-message="Sólo dígitos" />
                        
                        <div className="input-group-append">
                          <span className="input-group-text valid">
                            <i className="fas fa-check-circle "></i>
                          </span>
                          <span className="input-group-text invalid">
                            <i className="fas fa-times-circle"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    
                    <div id="group-birthday" className="form-group">
                      <div className="input-group">
                        <label>Fecha de nacimiento</label>
                        <input className="form-control" id="birthday" name="birthday" data-mask="00/00/0000" data-unmask-submit="" placeholder="dd/mm/aaaa" data-parsley-errors-container="#group-birthday" data-parsley-trigger="keyup" minLength="10" maxLength="10" data-parsley-length-message="Ingresa la fecha con formato dd/mm/aaaa" data-parsley-valid-date="" data-parsley-valid-date-message="No es una fecha válida" data-parsley-not-adult="" data-parsley-not-adult-message="Debes ser mayor de edad" data-parsley-is-future="" data-parsley-is-future-message="Es una fecha futura" autoComplete="off" required="" data-parsley-required-message="Campo requerido" />
                        
                        <div className="input-group-append">
                          <span className="input-group-text valid"> 
                            <i className="fas fa-check-circle"></i>
                          </span>
                          <span className="input-group-text invalid">
                            <i className="fas fa-times-circle"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    
                    <div id="group-emailCorp" className="form-group">
                      <div className="input-group">
                        <label>Correo electrónico</label>
                        <input className="form-control" id="emailCorp" name="emailCorp" placeholder="correo@example.com" data-parsley-errors-container="#group-emailCorp" data-parsley-trigger="keyup" type="email" data-parsley-type-message="Correo inválido" autoComplete="false" required="" data-parsley-required-message="Campo requerido" />
                        
                        <div className="input-group-append">
                          <span className="input-group-text valid">
                            <i className="fas fa-check-circle "></i>
                          </span>
                          <span className="input-group-text invalid">
                            <i className="fas fa-times-circle"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    
                    <div className="form-group text-center mb-30">
                      <button id="submit-form" className="btn btn-primary  mt-15" type="submit" disabled="">Confirmar</button>
                    </div>
                    
                    <div className="form-group text-center text-md-left m-0">
                      <a href="https://corporativo.clubpremier.com/login/auth?login_error=1&amp;lang=es">Recuperar número de cuenta</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
    )
  }
}

export default HomeCms