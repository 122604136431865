import React from "react";
class Grid extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>GRID</h1>
                <div className="alert mb-0">
                    <h4>
                        Para el caso de Aeroméxico Rewards Business en aplicaciones web, el grid debe cumplir las siguientes características: margin de 150px, gutter de 30px y 12 columnas. A continuación se ilustra cada caso:
                    </h4>
                </div>
            </div>
            <h5 className="h5-etiqueta alert-grid" style={{marginBottom: '-40px'}}>Grid web</h5>
            <div className="row alert-grid" style={{paddingLeft: '30px', paddingRight: '30px', paddingTop: '30px'}}>
                <div className="alert row row-basic alert-view col-12 p-30-guia">
                    <div className="content-grid">
                        <div className="colum-web-1">
                            <strong>150px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>1</strong>
                        </div>
                        <div className="colum-web-3">
                            <strong>30px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>2</strong>
                        </div>
                        <div className="colum-web-3">
                            <strong>30px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>3</strong>
                        </div>
                        <div className="colum-web-3">
                            <strong>30px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>4</strong>
                        </div>
                        <div className="colum-web-3">
                            <strong>30px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>5</strong>
                        </div>
                        <div className="colum-web-3">
                            <strong>30px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>6</strong>
                        </div>
                        <div className="colum-web-3">
                            <strong>30px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>7</strong>
                        </div>
                        <div className="colum-web-3">
                            <strong>30px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>8</strong>
                        </div>
                        <div className="colum-web-3">
                            <strong>30px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>9</strong>
                        </div>
                        <div className="colum-web-3">
                            <strong>30px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>10</strong>
                        </div>
                        <div className="colum-web-3">
                            <strong>30px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>11</strong>
                        </div>
                        <div className="colum-web-3">
                            <strong>30px</strong>
                        </div>
                        <div className="colum-web-2">
                            <strong>12</strong>
                        </div>
                        <div className="colum-web-1">
                            <strong>150px</strong>
                        </div>
                    </div>
                </div>
            </div>
            <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Grid mobile</h5>
            <div className="row" style={{paddingLeft: '30px', paddingRight: '30px', paddingTop: '30px'}}>
                <div className="alert row row-basic alert-view col-12 p-30-guia">
                    <div className="content-grid-mobile">
                        <div className="alert-grid-m">
                            <div className="colum-mobile-1">
                                <strong>15px</strong>
                            </div>
                            <div className="colum-mobile-2">
                                <strong>1</strong>
                            </div>
                            <div className="colum-mobile-3">
                                <strong>15px</strong>
                            </div>
                            <div className="colum-mobile-2">
                                <strong>2</strong>
                            </div>
                            <div className="colum-mobile-3">
                                <strong>15px</strong>
                            </div>
                            <div className="colum-mobile-2">
                                <strong>3</strong>
                            </div>
                            <div className="colum-mobile-3">
                                <strong>15px</strong>
                            </div>
                            <div className="colum-mobile-2">
                                <strong>4</strong>
                            </div>
                            <div className="colum-mobile-1">
                                <strong>15px</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default Grid;
