import React from "react";
import cpcorporativo from '../images/cpcorporativo.png';
import cpcorporativoother from '../images/cpcorporativoother.png';
import aereomexico from '../images/aereomexico.png';
import aereomexicoother from '../images/aeromexicoother.png';
import delta from '../images/delta.png';
import deltaother from '../images/deltaother.png';
import airfrance from '../images/airfrance.png';
import airfranceother from '../images/airfranceother.png';
import klm from '../images/klm.png'
import klmother from '../images/klmother.png';
class Logos extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>LOGOTIPOS</h1>
                <div className="alert mb-0">
                    <div className="alert alert-texto mb-0">
                            <h4>
                                Aeroméxico Rewards es una marca premium con una personalidad moderna y accesible. Comunica,  a través de los dos puntos que se unen, la  posibilidad  para juntar Puntos Aeroméxico Rewards. 
                            </h4>
                        <div className="alert mb-0">
                            <div className="alert alert-view" style={{maxHeight: '274px', maxWidth: '708px', height: '274px', margin: 'auto', display: 'flex', alignItems: 'center'}}>
                                <img style={{maxWidth: '-webkit-fill-available', margin: 'auto', maxHeight: '-webkit-fill-available', padding: '35px'}} src={cpcorporativo} alt=""/>
                            </div>  
                        </div>
                    </div>
                    <div className="alert mb-0">
                        <div className="alert alert-texto mb-0">
                            <h4>
                                Nota: Al hacer uso del logotipo, se debe contemplar un área mínima de protección, equivalente a 35px, variando al uso del logotipo.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0">
                    <h3>Cobranding</h3>
                    <div className="alert alert-texto mb-0">
                        <div className="alert mb-0 p-0">
                            <div className="row">
                                <div className='col-md-2'/>
                                <div className=" col-12 col-md-12 col-lg-8">
                                    <div className="applications app-cob">
                                        <img className='img-left' src={cpcorporativo} alt=""/>
                                        <hr/>
                                        <img className='img-right' src={aereomexico} alt=""/>
                                    </div>
                                </div>
                                <div className='col-md-2'/>
                            </div>
                            <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                    <code>
                                        &lt;div class="applications app-cob"&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;img class='img-left' src=&#123;&#125;&gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;hr/ &gt;<br/>
                                        &nbsp;&nbsp;&nbsp;&lt;img class='img-right' src=&#123;&#125;/&gt;<br/>
                                        &lt;/div&gt;<br/>
                                    </code>            
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0">
                    <h3>Applications</h3>
                    <div className="alert alert-texto mb-0">
                        <div className="alert mb-0 p-0">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="applications">
                                        <img src={cpcorporativo} alt=""/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="applications" style={{background: '#394E59'}}>
                                        <img src={cpcorporativoother} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="applications">
                                        <img src={aereomexico} alt=""/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="applications" style={{background: '#182743'}}>
                                        <img src={aereomexicoother} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="applications">
                                        <img src={delta} alt=""/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="applications" style={{background: '#184076'}}>
                                        <img src={deltaother} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="applications">
                                        <img src={airfrance} alt=""/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="applications" style={{background: '#CB2229'}}>
                                        <img src={airfranceother} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="applications">
                                        <img style={{width: '40px'}} src={klm} alt=""/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="applications" style={{background: '#10A4E2'}}>
                                        <img style={{width: '40px'}} src={klmother} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="applications" style=&#123;&#123;background: ''&#125;&#125; &gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;img src=&#123;&#125;/&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>            
                            </div> 
                        </div>
                        <div className="alert mb-0">
                            <div className="alert alert-texto mb-0">
                                <h4>
                                    Nota: En este caso, el backgroud se declara directamente en el componente en cuestion, en caso de que se requiera.
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0">
                    <h3>Applications</h3>
                    <div className="alert alert-texto mb-0">
                        <div className="alert mb-0 p-0">
                        <div className="row grid-descubre">
                            <div className="col-md-6  ">
                                <div className="row h-100">
                                    <div className="col-md-6 h-md-50 p-0">
                                        <div className="h-100 grid-images" style={{background:'#182743'}}>
                                            <img className="img-fluid " src="https://corpstylo.aeromexicorewards.com/logos/AeroMexico.png" alt="" />
                                            <div className="imagen-tumb">
                                                <img className="image_land" src="https://corpstylo.aeromexicorewards.com/logos/AeroMexico.png" alt=""/>
                                                <div className="textLand" style={{background:'#182743'}}>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla congue finibus arcu, eu sodales sapien semper in. In quis nulla vel arcu suscipit porta at nec neque. Integer aliquam faucibus imperdiet. Aliquam feugiat neque eget neque eleifend consequat. Pellentesque id scelerisque frnufueifre frbfeyfbr fryefrybfr ifyrbfrb fyr yfrfbr</p>
                                                    <a href="#sab">Saber más</a>
                                                </div>
​
                                            </div>
                                        </div>
​
​
                                    </div>
                                   
                                </div>
​
                            </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default Logos;
