import React from 'react';
import SideBar from './sidebar';

class RepresentContacts extends React.Component {

  render() {
    return (    
      <div className="bg-alabaster">
        <div className="row pl-15 pr-15 represent-contacts" data-text="ES" data-type-account="AR" data-account="" data-text-type-ar="Representante Empresarial" data-text-type-cr="Contacto" data-account-text="Cuenta Aeroméxico Rewards" data-textbutton-validate="Validar cuenta"  data-textbutton-add="Agregar" data-textbutton-update="Guardar" data-textbutton-remove="Si, Eliminar" data-text-error-general="Porfavor, intenta nuevamente.">
					<input className="base-url" type="hidden" value="https://f547329e-732d-44a6-a6fc-9dd94d1147ca.mock.pstmn.io"/>
					<input className="image-error" type="hidden" value="https://clubpremierstyleguide.s3.amazonaws.com/corporativoinfo/assets/i-sorry.png"/>
					<input className="image-success-add" type="hidden" value="https://clubpremierstyleguide.s3.amazonaws.com/corporativoinfo/assets/success-register.png"/>
					<input className="image-success-edit" type="hidden" value="https://clubpremierstyleguide.s3.amazonaws.com/corporativoinfo/assets/success-save.png"/>
					<input className="image-success-delete" type="hidden" value="https://clubpremierstyleguide.s3.amazonaws.com/corporativoinfo/assets/delete-success.png"/>
					<SideBar />
          <div className="col-12 col-lg-9 container-body p-0 mb-15">
            <div className="col-12 mt-30"> 
              <h2 className="d-inline">
                <i className="fas fa-arrow-left icon-dashboard"></i>
                Representantes y Contactos
              </h2>
            </div>
            <p className="subtitle mt-15">
              Administra los contactos y representantes empresariales de tu Cuenta Corporativa,
              recuerda que los contactos autorizados podrán tener acceso a la información de 
              la Cuenta Corporativa y utilizar los Puntos Aeroméxico Rewards de la empresa.
            </p>

            <div className="row bg-white card-white">
              <div className="col-12 col-md-4 text-left mt-15 mb-15">
                <div className="input-group input-basic" id="cpr-ryc-rci-inp-bpn">
                  <input className="form-control search-name" placeholder="Buscar por nombre" required/>
                  <div className="input-group-append">
                      <span className="input-group-text inputBasic">
                        <i className="fas fa-search"></i>
                      </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8 text-right mt-15 mb-15">
                <button className="btn btn-primary btn-small" data-toggle="modal" data-target="#cpr-ryc-rci-div-mac">
                  Agregar un Contacto o Representante
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>
            <div className="row bg-white card-white list-represents"></div>

            {/* modal messages */}
            <div className="modal info modal-data" id="cpr-ryc-rci-div-mda" data-keyboard="false" data-backdrop="static" tab-index="-1" role="dialog" aria-labelledby="cpr-ryc-rci-txt-mdt" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title text-center m-auto" id="cpr-ryc-rci-txt-mdt">Vacio</h4>
                    </div>
                    <div className="modal-body text-center">
                      <img src="" alt="i_sorry" className="image-error"/>
                      <p className="text-center text-alternative"></p>
                      <p className="text-center text-alternative-two d-none"></p>
                      <button className="btn btn-secondary" type="button" data-dismiss="modal" aria-label="Close">
                          Aceptar
                      </button>
                      <button className="btn btn-secondary success-add d-none" type="button">
                          Aceptar
                      </button>
                    </div>
                  </div>
              </div>
            </div>
            {/* modal add contact */}
            <div className="modal info col-12 modal-add-contact" id="cpr-ryc-rci-div-mac" data-keyboard="false" data-backdrop="static" tab-index="-1" role="dialog" aria-labelledby="cpr-ryc-rci-txt-act" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="cpr-ryc-rci-txt-act">
                      Agregar un Representante o Contacto
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="alert alert-info" role="alert">
                      <div className="row row-basic">
                          <i className='fas fa-info-circle'/>
                          <h5>Importante</h5>
                          <i className='fas fa-times'/>
                      </div>
                      <div className="row row-basic">
                        <p>
                          El Representante Empresarial administra y controla la Cuenta Corporativa, 
                          mientras que el Contacto auxilia en la administración de la Cuenta Corporativa.
                        </p>
                      </div>
                    </div>
                    <form id="cpr-ryc-rci-frm-vac" className="form-validate validate-form">
                      <div className="row">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-6" id="cpr-ryc-rci-div-asc">
                          <div className="input-group" >
                            <label>Cuenta Aeroméxico Rewards</label>
                            <input 
                              className="form-control input-account"
                              name="accountNumber"
                              data-parsley-errors-container="#group-accountNumber"
                              data-parsley-trigger="input focus"
                              placeholder="Cuenta Aeroméxico Rewards"
                        
                              required
                              data-parsley-required-message="Campo requerido"
                              minLength="2"
                              maxLength="11"
                              data-parsley-length-message="Máximo 11 dígitos"
                              data-parsley-luhn
                              data-parsley-luhn-message="No. de cuenta inválido"
                              data-parsley-type="digits"
                              data-parsley-type-message="Sólo dígitos"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-6" id="cpr-ryc-rci-div-afn">
                          <div className="input-group">
                            <label>Fecha de nacimiento</label>
                            <input 
                              name="birthday"
                              className="form-control input-birthday"
                              data-parsley-errors-container="#group-birthday"
                              data-parsley-trigger="input focus"
                              placeholder="dd/mm/aaaa"
                              
                              required
                              data-parsley-required-message="Campo requerido"
                              minLength="10"
                              maxLength="10"
                              data-parsley-length-message="Ingresa la fecha con formato dd/mm/aaaa"
                              data-parsley-valid-date=""
                              data-parsley-valid-date-message="No es una fecha válida"
                              data-parsley-not-adult=""
                              data-parsley-not-adult-message="Debes ser mayor de edad"
                              data-parsley-is-future=""
                              data-parsley-is-future-message="Es una fecha futura"
                              autoComplete="off"
                              />
                          </div>
                        </div>
                        <div className="col-12 text-right p-15 buttons-actions">
                          <a className="btn btn-secondary m-1" href="/" type="button" data-dismiss="modal" aria-label="Close" id="cpr-ryc-rci-hyp-can">
                            Cancelar
                          </a>
                          <button className="btn btn-primary m-1" type="submit" disabled="disabled" id="cpr-ryc-rci-btn-val">
                            Validar cuenta
                          </button>
                        </div>
                      </div>
                    </form>
                    {/* Second form hidden */}
                    <form id="cpr-ryc-rci-frm-adc" className="form-add-contact d-none add-contact">
                      <div className="row">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-6" id="cpr-ryc-rci-div-nom">
                          <div className="input-group" >
                            <label>Nombre</label>
                            <input 
                              className="form-control input-name"
                              name="nameContact"
                              data-parsley-errors-container="#group-nameContact"
                              data-parsley-trigger="input focus"
                              placeholder="Nombre del nuevo contacto"
                        
                              required
                              data-parsley-required-message="Campo requerido"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-6" id="cpr-ryc-rci-div-tar">
                          <div className="input-group">
                            <label className="select-label">Selecciona un tipo</label>                         
                            <select
                              name="contactType"
                              type="text"
                              className="input-type-contact custom-select"
                              defaultValue={'DEFAULT'}
                              required
                              data-parsley-errors-container="#group-contact"
                              data-parsley-trigger="input focus"
                              data-parsley-required-message="Campo requerido"
                            >
                              <option value="DEFAULT" disabled>Selecciona una opción</option>
                              <option value="AC">Contacto</option>
                              <option value="AR">Representante</option>
                            </select>
                            <div className="input-group-append">
                              <span className="input-group-text inputBasic pl-0">
                                <i className="fas fa-lg fa-angle-down"/>
                              </span>
                            </div>                        
                          </div> 
                        </div>
                        <div className="col-12 text-right p-15 buttons-actions">
                          <a className="btn btn-secondary m-1" href="/" type="button" data-dismiss="modal" aria-label="Close" id="cpr-ryc-rci-hyp-cnc">
                            Cancelar
                          </a>
                          <button className="btn btn-primary m-1" type="submit" disabled="disabled" id="cpr-ryc-rci-btn-agr">
                            Agregar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* modal update contact */}
            <div className="modal info col-12 modal-update-contact" id="cpr-ryc-rci-div-upc" data-keyboard="false" data-backdrop="static" tab-index="-1" role="dialog" aria-labelledby="cpr-ryc-rci-txt-uct" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="cpr-ryc-rci-txt-uct">
                        Editar Contacto
                      </h4>
                    </div>
                    <div className="modal-body">
                      <div className="alert alert-info" role="alert">
                        <div className="row row-basic">
                            <i className='fas fa-info-circle'/>
                            <h5>Importante</h5>
                            <i className='fas fa-times'/>
                        </div>
                        <div className="row row-basic">
                          <p>
                            El Representante Empresarial administra y controla la Cuenta Corporativa, 
                            mientras que el Contacto auxilia en la administración de la Cuenta Corporativa.
                          </p>
                        </div>
                      </div>
                      <form id="cpr-ryc-rci-frm-ucf" className="form-update-contact update-contact-form">
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-6 col-xl-6" id="cpr-ryc-rci-div-ecp">
                            <div className="input-group" >
                              <label>Cuenta Aeroméxico Rewards</label>
                              <input 
                                className="form-control input-account"
                                name="accountNumber"
                                data-parsley-errors-container="#group-accountNumber"
                                data-parsley-trigger="input focus"
                                placeholder="Cuenta Aeroméxico Rewards"
                                disabled
                                required
                                data-parsley-required-message="Campo requerido"
                                minLength="2"
                                maxLength="11"
                                data-parsley-length-message="Máximo 11 dígitos"
                                data-parsley-luhn
                                data-parsley-luhn-message="No. de cuenta inválido"
                                data-parsley-type="digits"
                                data-parsley-type-message="Sólo dígitos"
                              />
                              <div className="input-group-append">
                                <span className="input-group-text inputBasic">
                                  <i className="fas fa-lock-alt"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-6 col-xl-6" id="cpr-ryc-rci-div-efn">
                            <div className="input-group">
                              <label>Fecha de nacimiento</label>
                              <input 
                                name="birthday"
                                className="form-control input-birthday"
                                data-parsley-errors-container="#group-birthday"
                                data-parsley-trigger="input focus"
                                placeholder="dd/mm/aaaa"
                                disabled
                                required
                                data-parsley-required-message="Campo requerido"
                                minLength="10"
                                maxLength="10"
                                data-parsley-length-message="Ingresa la fecha con formato dd/mm/aaaa"
                                data-parsley-valid-date=""
                                data-parsley-valid-date-message="No es una fecha válida"
                                data-parsley-not-adult=""
                                data-parsley-not-adult-message="Debes ser mayor de edad"
                                data-parsley-is-future=""
                                data-parsley-is-future-message="Es una fecha futura"
                                autoComplete="off"
                                />
                              <div className="input-group-append">
                                <span className="input-group-text inputBasic">
                                  <i className="fas fa-lock-alt"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-6 col-xl-6" id="cpr-ryc-rci-div-eno">
                            <div className="input-group" >
                              <label>Nombre</label>
                              <input 
                                className="form-control input-name"
                                name="nameContact"
                                data-parsley-errors-container="#group-nameContact"
                                data-parsley-trigger="input focus"
                                placeholder="Nombre del nuevo contacto"
                                disabled
                                required
                                data-parsley-required-message="Campo requerido"
                              />
                              <div className="input-group-append">
                                <span className="input-group-text inputBasic">
                                  <i className="fas fa-lock-alt"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-6 col-xl-6" id="cpr-ryc-rci-div-eti">
                            <div className="input-group">
                              <label className="select-label">Selecciona un tipo</label>                         
                              <select
                                name="contactType"
                                type="text"
                                className="input-type-contact custom-select"
                                defaultValue={'DEFAULT'}
                                required
                                data-parsley-errors-container="#group-contact"
                                data-parsley-trigger="input focus"
                                data-parsley-required-message="Campo requerido"
                              >
                                <option value="DEFAULT" disabled>Selecciona una opción</option>
                                <option value="AC">Contacto</option>
                                <option value="AR">Representante</option>
                              </select>
                              <div className="input-group-append">
                                <span className="input-group-text inputBasic pl-0">
                                  <i className="fas fa-lg fa-angle-down"/>
                                </span>
                              </div>                      
                            </div> 
                          </div>
                          <div className="col-12 text-right p-15">
                            <a className="btn btn-secondary text-white m-1" href="/" type="button" data-dismiss="modal" aria-label="Close" id="cpr-ryc-rci-hyp-eca">
                              Cancelar
                            </a>
                            <button className="btn btn-primary m-1" type="submit" disabled="disabled" id="cpr-ryc-rci-btn-egu">
                              Guardar
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
              </div>
            </div>
						{/* modal delete contact */}
						<div className="modal info modal-delete-contact" id="cpr-ryc-rci-div-mdc" data-keyboard="false" data-backdrop="static" tab-index="-1" role="dialog" aria-labelledby="cpr-ryc-rci-txt-dct" aria-hidden="true">
							<div className="modal-dialog modal-dialog-centered" role="document">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title text-center m-auto" id="cpr-ryc-rci-txt-dct">
											¿Estás seguro que deseas eliminar a <span className="username-delete"></span> ?
										</h4>
									</div>
									<div className="modal-body text-center">
										<p className="text-center type-delete"></p>
										<p className="text-center">
											Cuenta Aeroméxico Rewards: <span className="accountnum-delete"></span>
										</p>
										<button className="btn btn-secondary m-1" type="button" data-dismiss="modal" aria-label="Close" id="cpr-ryc-rci-btn-ean">
											Cancelar
										</button>
										<button className="btn btn-primary m-1 submit-delete-contact" type="button" id="cpr-ryc-rci-btn-eel">
											Si, Eliminar
										</button>
									</div>
								</div>
							</div>
						</div>
            {/* final */}
          </div>
        </div>
      </div>  
    );
  }
}
export default RepresentContacts;