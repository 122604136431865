import React from 'react';

class PointCalculator extends React.Component {

  render() {
    return (    
      <div className="point-calculator-cms container-fluid container-corporativo">
        <input className="d-none" id="language" type="hidden" value="es" />
        <input className="d-none" id="calculatorUrl" type="hidden" value="https://ezmock.herokuapp.com/api/61d47e7dc540290015f890b0 " />
        {/* TOP CAROUSEL */}
        <section className="section-corporativo p-0">
          <div className="carousel-descubre row m-0">
            <div id="carouselTop" className="carousel slide w-100 mb-0" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 bg-divCP mb-2 p-0">
                        <article>
                          <h1 className="">Conoce cuántos Puntos Aeroméxico Rewards acumulas por tus vuelos con Aeroméxico.</h1>
                        </article>
                      </div>
                      <div style={{backgroundImage:"url(https://s3media.clubpremier.com/CPcms/21/CPC/hm-cpc-actualizaciondedatos.jpg)"}} className="col-6 d-none d-md-block p-0 col-img "></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* TITLE, DESCRIPTION AND THAT JAZZ */}
        <section className="section-corporativo auto">
          <div className="row justify-content-center section-corp group-text-area">
            <div className="title-bullet-cont col-md-8 col-text">
              <ul>
                <li>
                  <h2 className="title-bullet">Acumula Puntos Aeroméxico Rewards al volar con Aeroméxico</h2>
                </li>
              </ul>
            </div>
            <div className="col-md-8 col-text text-center">
              <p>Siempre que vuelas por Aeroméxico acumulas Puntos Aeroméxico Rewards dependiendo de la familia tarifaria por la que viajes. Si aún no eres Socio Aeroméxico Rewards inscríbete gratis aquí.</p>
              <h3 className="color-gull-gray">Calcula la cantidad de puntos que acumularás por tus vuelos, ya sea que lo vayas a adquirir o ya cuentes con un boleto para volar con Aeroméxico.</h3>
            </div>
          </div>
        </section>

        {/* THE CALCULATOR */}
        <section className="section-corporativo py-30">
          <div className="row justify-content-center m-0">
            <div className="col-xs-12 col-sm-10 col-md-9 calculator">
              <div className="calculator-tabs">
                <div className="calculator-tab -active" data-target="info" id="cmp-ptc-cal-div-tab-lft">
                  <p>
                    <span>Voy a adquirir un vuelo</span>
                  </p>
                </div>
                <div className="calculator-tab" data-target="form" id="cmp-ptc-cal-div-tab-rgt">
                  <p>
                    <span>Tengo un boleto con Aeroméxico</span>
                  </p>
                </div>
              </div>
              <div className="calculator-content">
                <div className="calculator-info">
                  <h3 className="mb-30 text-center">Conoce cuántos Puntos acumulas por tipo de vuelo</h3>
                  <div className="info-panels">
                    <div className="info-panel">
                      <img height="214" width="214" src="https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/calculadora-corporativo/avion.svg" />
                      <h4>Vuelos Nacionales</h4>
                      <hr />
                      <p>Acumula 4 Puntos por dólar gastado en tus vuelos dentro de México. </p>
                    </div>
                    <div className="info-panel">
                      <img height="214" width="214" src="https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/calculadora-corporativo/world.svg" />
                      <h4>Vuelos Internacionales</h4>
                      <hr />
                      <p>Acumula 6 Puntos por dólar gastado en tus vuelos fuera de México. </p>
                    </div>
                  </div>
                </div>
                <div className="calculator-form d-none">
                  <div className="d-none" id="alert-templates">
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                      <h5 className="alert-heading">
                        <i className="fa fa-times-circle" aria-hidden="true"></i> Importante
                      </h5>
                      <p className="alert-text">Para acumular los Puntos Aeroméxico Rewards de tu vuelo verifica que tu reserva cuente con tu Número de Socio Aeroméxico Rewards y Número de Cuenta Corporativa.</p>
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div className="form-alerts">
                    <div className="alert alert-primary alert-dismissible fade show" role="alert">
                      <h5 className="alert-heading">
                        <i className="fa fa-info-circle" aria-hidden="true"></i> Importante.
                      </h5>
                      <p className="alert-text">Para acumular los Puntos Aeroméxico Rewards de tu vuelo verifica que tu reserva cuente con tu Número de Socio Aeroméxico Rewards y Número de Cuenta Corporativa.</p>
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div className="form-container">
                    <div className="form-pictogram" >
                      <img src="https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/calculadora-corporativo/calculator.svg" />
                    </div>
                    <form className="form-calculator">
                      <h4>Ingresa los datos de tu boleto</h4>
                      <div className="input-row">
                        <div className="input-col -reservation-code" id="input-reservation-code">
                          <div className="input-group floating-label">  
                            <input
                              id="cmp-ptc-cal-inp-pnr"
                              className="form-control mt-15"
                              data-parsley-errors-container="#input-reservation-code"
                              required
                              data-parsley-required-message="Campo requerido"
                              maxLength="6"
                              minLength="6"
                              data-parsley-length-message="Debe ser 6 digítos"
                              data-parsley-pattern="/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/"
                              data-parsley-pattern-message="No se permiten carateres especiales"
                              data-parsley-trigger="input focus"
                              placeholder="Clave de reservación"
                            />
                            <label>Clave de reservación</label>      
                          </div> 
                          <div className="input-group-append">
                            <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                            <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                          </div> 
                        </div>
                        <div className="input-col -surnames" id="input-surnames">
                          <div className="input-group floating-label" >  
                            <input 
                              id="cmp-ptc-cal-inp-sur"
                              className="form-control mt-15"
                              data-parsley-errors-container="#input-surnames"
                              required
                              data-parsley-required-message="Campo requerido"
                              data-parsley-pattern="/^[a-zA-Z0-9\- *(a-zA-ZÀ-ÖØ-öø-ÿ*)*]+$/"
                              data-parsley-pattern-message="Solo números, letras y espacios"
                              data-parsley-trigger="input focus"
                              placeholder="Apellidos"
                            />
                            <label>Apellidos</label>      
                          </div> 
                          <div className="input-group-append">
                            <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                            <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                          </div>
                        </div>
                      </div>
                      <div className="input-row -buttons">
                        <div className="input-col">
                          <div className="input-group">
                            <button className="btn btn-primary" id="cmp-ptc-cal-btn-sub" disabled>Calcular</button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="form-ticket">
                      <img 
                        srcSet="https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/calculadora-corporativo/boleto1x.png,
                                https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/calculadora-corporativo/boleto2x.png 2x"
                        src="https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/calculadora-corporativo/boleto1x.png"
                        alt="Ticket display"
                      />
                    </div>
                  </div>
                  <div className="form-info">
                    <div className="form-help text-center">
                      <h3 className="color-gull-gray">¿No estás seguro de cómo acumular con tu Cuenta Corporativa?</h3>
                      <p>Comunícate a nuestro Centro de Atención Telefónica</p>
                      <div>
                        <img  src="https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/calculadora-corporativo/call-center.svg" />
                      </div>
                      <h2>(52 55) 1519 4040</h2>
                    </div>
                    <div className="form-results text-center d-none">
                      <h3 className="color-gull-gray">Después de volar podrás acumular hasta:</h3>
                      <p className="mt-30">Puntos Aeroméxico Rewards por Tarifa Base</p>
                      <div className="amount-base-points">2,000</div>
                      <p className="label-total-points">Total de Puntos</p>
                      <h3 className="amount-total-points">2,000</h3>
                      <p className="point-disclaimer text-center">* Cantidad de Puntos aproximada, puedes consultar la tabla de acumulación <a href="#" target="_blank">aquí</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ */}
        <section className="section-corporativo py-50 bg-grey">
          <div className="row justify-content-center m-0">
            <div className="col-xs-12 col-sm-10 col-md-9">
              <h2 className="text-center">Preguntas Frecuentes</h2>
              <p className="text-center">Aeroméxico y Aeroméxico Rewards Business se transforman para tu Empresa, a partir del 1° de enero de 2022 descubre la nueva forma de acumular Puntos Aeroméxico Rewards."</p>
              <div className="faq-view accordion-container my-5" id="cpr-stl-faq-acr">
                {/* Accordion #1 */}
                <div className="accordion" id="cpr-stl-faq-acr-1">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse1" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse1"> ¿Los Puntos Aeroméxico Rewards que acumula la Cuenta Corporativa de mi Empresa afectan los Puntos de mi Cuenta Individual? 	 
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse1" className="collapse" aria-labelledby="headingOne1" data-parent="#cpr-stl-faq-acr">
                      <div className="card-body">
                        <p>
                        Recuerda que tanto tu Empresa y tu acumulan Puntos Aeroméxico Rewards al mismo tiempo por tus Viajes de Negocios. Los Puntos Aeroméxico Rewards que se otorgan a las Cuentas Corporativas son adicionales a los Puntos que se acreditan en las Cuentas Aeroméxico Rewards Individuales de los Colaboradores de la Empresa.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #2 */}
                <div className="accordion" id="cpr-stl-faq-acr-2">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse2" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse2"> ¿Qué vigencia tienen los Puntos Aeroméxico Rewards que se acumulan en la Cuenta Corporativa de la Empresa? 	 
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse2" className="collapse" aria-labelledby="headingOne2" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                            <p>
                            Los Puntos Aeroméxico Rewards de la Cuenta Corporativa tienen una vigencia de 24 meses a partir de la fecha de acreditación
                            </p>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #3 */}
                <div className="accordion" id="cpr-stl-faq-acr-3">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse3" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse3"> ¿Dónde puedo consultar el saldo de Puntos Aeroméxico Rewards de la Cuenta Corporativa de la Empresa? 	 
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse3" className="collapse" aria-labelledby="headingOne3" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                            <p>Si eres Representante Empresarial o Contacto Autorizado de la Cuenta Corporativa mensualmente recibirás a tu correo registrado el Estado de Cuenta con el balance de Puntos de la Cuenta Corporativa.</p>
                            <p>Adicionalmente, puedes acceder al perfil de la Empresa iniciando sesión en <a target="_blank" href="https://corporativo.clubpremier.com/login">https://corporativo.clubpremier.com/login</a> y consultar la sección de "Estado de Cuenta" para conocer el detalle de las transacciones que se han registrado en la Cuenta Corporativa. 		                        </p>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #4 */}
                <div className="accordion" id="cpr-stl-faq-acr-4">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse4" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse4"> ¿Cómo reactivo o recupero mi Cuenta Aeroméxico Rewards Business?
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse4" className="collapse" aria-labelledby="headingOne4" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                            <p>
                            En caso de que el Administrador principal registrado ya no se encuentre en la Empresa, o simplemente no se tengan los datos completos para iniciar sesión favor de enviar un correo a <a target="_blank" href="mailto:programa.corporativo@clubpremier.com">programa.corporativo@clubpremier.com</a> para que puedas recuperar tu Cuenta. 	
                            </p>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #5 */}
                <div className="accordion" id="cpr-stl-faq-acr-5">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse5" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse5"> ¿Qué pasa si no ingrese mi Número de Cuenta Corporativa al momento de reservar mi viaje de Negocios?  	 
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse5" className="collapse" aria-labelledby="headingOne5" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                            <p>Si al momento de hacer la reservación no se ingresó el Número de Cuenta Corporativa, es indispensable registrar la Clave de Acumulación de tu Empresa (OSI) antes de realizar el vuelo llamando a nuestro Call Center al (55) 1519 4040, por medio de tu agencia de viajes o enviando un correo a <a target="_blank" href="mailto:programa.corporativo@clubpremier.com">programa.corporativo@clubpremier.com</a> solicitando que agreguen el Número de Cuenta Corporativa a tu reserva.</p>
                            <p>Es importante tener presente que no existe acumulación retroactiva para vuelos en el Programa Corporativo.</p>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #6 */}
                <div className="accordion" id="cpr-stl-faq-acr-6">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse6" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse6"> Además de vuelos, ¿cómo puedo acumular Puntos Aeroméxico Rewards a la Cuenta Corporativa? 	 
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse6" className="collapse" aria-labelledby="headingOne6" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                            <p>Tu Empresa también puede acumular Puntos Aeroméxico Rewards al adquirir todos los servicios que nuestro Programa y Empresas afiliadas tienen para ti:</p>
                            <ul>
                              <li>Al pagar con Tu Tarjeta American Express(R) Corporate Aeroméxico.</li>
                              <li>Al reservar tu estancia de negocios en Hoteles en Línea.</li>
                              <li>Hospedándote en las propiedades de Choice Hotels y City Express.</li>
                            </ul>
                            <p>Descubre todas las formas en las que tu Empresa y tu pueden acumular más Puntos Aeroméxico Rewards al complementar tus Experiencias de Negocios dando click en el siguiente link <a target="_blank" hred="https://corporativo.clubpremier.com/mx/acumula?lang=es">https://corporativo.clubpremier.com/mx/acumula?lang=es</a></p>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #7 */}
                <div className="accordion" id="cpr-stl-faq-acr-7">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse7" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse7"> ¿En qué se pueden utilizar los Puntos Aeroméxico Rewards de la Cuenta Corporativa?
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse7" className="collapse" aria-labelledby="headingOne7" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                          <p>Para utilizar los Puntos Aeroméxico Rewards de la Cuenta Corporativa debes de ser el Representante Empresarial o Contacto Autorizado de tu Empresa.</p>
                          <p>Hay muchas formas de utilizar los Puntos Aeroméxico Rewards que acumula tu Empresa. Adquiere vuelos, estancias en hoteles, productos de viaje, renta de autos y muchos más.</p>
                          <p>Conoce a nuestras Empresas afiliadas dando click en el siguiente link <a target="_blank" href="https://corporativo.clubpremier.com/mx/utiliza?lang=es">https://corporativo.clubpremier.com/mx/utiliza?lang=es</a></p>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #8 */}
                <div className="accordion" id="cpr-stl-faq-acr-8">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse8" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse8"> ¿Qué requisitos deben cumplir los empleados de las Empresas inscritas a Aeroméxico Rewards Business para que puedan recibir el descuento del 50% en la adquisición de Accesos Únicos y Membresías Salón Premier?
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse8" className="collapse" aria-labelledby="headingOne8" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                            <p>Los requisitos para adquirir Accesos Únicos y Membresías Salón Premier son:</p>
                            <ul>
                              <li>La Empresa deberá estar inscrita en Aeroméxico Rewards Business.  	</li>
                              <li>La reservación del Colaborador al que se aplicará el descuento deberá tener ingresada la Clave Única de Acumulación de la Empresa (OSI) al momento de adquirir los Accesos Únicos o Membresías Salón Premier</li>
                            </ul>
                            <p>Recuerda que para adquirir estos beneficios deberás comunicarte a nuestro Call Center llamando al (55) 1519 4040. Conoce más aquí <a target="_blank" href="https://corporativo.clubpremier.com/mx/utiliza/productos-de-vuelo/salones-premier/">https://corporativo.clubpremier.com/mx/utiliza/productos-de-vuelo/salones-premier/</a></p>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #9 */}
                <div className="accordion" id="cpr-stl-faq-acr-9">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse9" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse9"> ¿Qué son las Tarjetas de Nivel?
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse9" className="collapse" aria-labelledby="headingOne9" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                            <p>Tu Empresa puede obtener Tarjetas de Nivel Oro y Platino sin costo de acuerdo con la facturación con Aeroméxico para brindarle a los Colaboradores beneficios exclusivos durante sus vuelos de Negocios.</p>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #10 */}
                <div className="accordion" id="cpr-stl-faq-acr-10">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse10" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse10"> ¿Qué beneficios otorgan las Tarjetas de Nivel? 	 
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse10" className="collapse" aria-labelledby="headingOne10" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                            <p>Algunos de los beneficios que brindan nuestras Tarjeta de Nivel son:</p>
                            <ul>
                              <li>Ascenso a Clase Premier.</li>
                              <li>Selección y asignación de asientos sin costo adicional.</li>
                              <li>Acceso a Salones Premier* para esperar tu vuelo.</li>
                              <li>Prioridad al hacer tu check-in antes del vuelo y abordaje prioritario.</li>
                              <li>Documenta en mostradores preferenciales SkyPriority. 	</li>
                              <li>1 maleta extra documentada sin costo.</li>
                            </ul>
                            <p>Consulta más información en: <a target="_blank" href="https://corporativo.clubpremier.com/mx/corporativo/niveles?lang=es">https://corporativo.clubpremier.com/mx/corporativo/niveles?lang=es</a></p>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #11 */}
                <div className="accordion" id="cpr-stl-faq-acr-11">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse11" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse11"> ¿Cómo se pueden asignar las Tarjetas de Nivel de la Cuenta Corporativa?
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse11" className="collapse" aria-labelledby="headingOne11" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                          <p>Para asignar las Tarjetas de Nivel de la Cuenta Corporativa debes de ser el Representante Empresarial.</p>
                            <p>Ingresa al perfil de tu Empresa iniciando sesión en <a target="_blank" href="https://corporativo.clubpremier.com/login">https://corporativo.clubpremier.com/login</a></p>
                            <p>Dirigirte a la sección de "Tarjetas y Membresías" y asignar las Tarjetas de Nivel disponibles a tus Colaboradores que estén inscritos al Programa Individual.</p>
                            <p>Recuerda que el Nivel Tier quedará vinculado con el Número de Cuenta Individual de tu Colaborador.</p>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #12 */}
                <div className="accordion" id="cpr-stl-faq-acr-12">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse12" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse12"> Cómo saber si los Colaboradores de la Empresa ya tienen una Tarjeta de Nivel asignada a su Cuenta Individual?
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse12" className="collapse" aria-labelledby="headingOne12" data-parent="#cpr-stl-faq-acr">
                      <div className="card-body">
                        <p>El Representante Empresarial podrá consultar las Tarjetas de Nivel asignadas desde el Perfil de la Cuenta Corporativa al iniciar sesión en <a target="_blank" href="https://corporativo.clubpremier.com/login">https://corporativo.clubpremier.com/login</a></p>
                        <p>Adicional, sugerimos solicitar a los Colaboradores que descarguen la App de Aeroméxico Rewards e iniciar sesión con su Cuenta Individual. En el APP podrán:</p>
                        <ul>
                          <li>Conocer su Nivel personal Aeroméxico Rewards Aeroméxico o el otorgado por su Cuenta Corporativa.</li>
                          <li>Ver y utilizar su Tarjeta virtual para acumular Puntos Aeroméxico Rewards, ingresar a Salones Premier y más.</li>
                        </ul>
                        <p>Recuerda que si tu Colaborador tiene un Nivel Tier superior al asignado por la Empresa este conservará el Nivel con mayores beneficios.</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #13 */}
                <div className="accordion" id="cpr-stl-faq-acr-13">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse13" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse13">¿Cómo puedo registrar una factura para acumular con hoteles y arrendadoras afiliadas?
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse13" className="collapse" aria-labelledby="headingOne13" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                            <p>Para acumular Puntos Aeroméxico Rewards con hoteles y empresas de alquiler de autos en la Cuenta Corporativa, es necesario ingresar la factura correspondiente al servicio contratado con ellos siguiendo los pasos a continuación: 	</p>
                            <ol>
                              <li> Contratar el servicio de estancias de hotel o arrendadoras afiliadas al Programa Corporativo. 	</li>
                              <li>Al concluir la estancia o renta de auto, solicitar la factura correspondiente a tu servicio.</li>
                              <li>Ingresar al perfil de tu Empresa iniciando sesión en <a target="_blank" href="https://corporativo.clubpremier.com/login">https://corporativo.clubpremier.com/login </a> </li>
                              <li>Dirigirse a la sección de Acumulaciones Retroactivas y registra la factura ingresando el número de Cuenta Corporativa de tu Empresa para que se acrediten los Puntos Aeroméxico Rewards. 		</li>
                              <li>Una vez que finalice el proceso de validación, los Puntos Aeroméxico Rewards se verán reflejados en la Cuenta Corporativa de la Empresa dentro de un período de 14 días hábiles después de la fecha de registro de la factura. 	</li>
                            </ol>
                            <p>Recuerda que para ingresar la factura a la Cuenta Corporativa debes de ser el Representante Empresarial o Contacto Autorizado de tu Empresa.</p>
                            <p>Es importante tener presente que no existe acumulación retroactiva para vuelos en el Programa Corporativo.</p>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Accordion #14 */}
                <div className="accordion" id="cpr-stl-faq-acr-14">
                  <div className="card card-collapseSecondary">
                    <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse14" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse14"> ¿Dónde pueden atender mis dudas y comentarios acerca de Aeroméxico Rewards Business?
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h6>
                    <div id="cpr-stl-faq-faqCollapse14" className="collapse" aria-labelledby="headingOne14" data-parent="#cpr-stl-faq-acr">
                        <div className="card-body">
                            <p>Vía e-mail a: <a target="_blank" href="mailto:programa.corporativo@clubpremier.com">programa.corporativo@clubpremier.com</a></p>
                            <p>En el Chat: <a target="_blank" href="https://www.clubpremiercorporativo.com">www.clubpremiercorporativo.com</a></p>
                            <p>En el Centro de Atención Telefónica Aeroméxico Rewards Business, a los números de teléfono:</p>
                            <ul>
                              <li>En la Ciudad de México: (55) 1519 4040</li>
                              <li>En un horario de atención de lunes a viernes de 09:00 a 19:00 horas. (Horario de la Ciudad de México).</li>
                            </ul>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>


        {/* BOTTOM CAROUSEL */}
        <section className="section-corporativo p-0">
          <div className="carousel-descubre row m-0">
            <div id="carouselTop" className="carousel slide w-100 mb-0" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 bg-divCP mb-2 p-0">
                        <article>
                          <h2 className="title-bullet text-white text-left">Actualización de Datos para Cuentas Corporativas</h2>
                          <p>Ahora puedes utilizar parte de los Puntos Aeroméxico Rewards de tu Cuenta Corporativa y la otra parte la puedes pagar con tarjeta de crédito o débito para adquirir tu Boleto Premio, rentar un auto y más. De esta manera, te será más fácil administrar tus Puntos Aeroméxico Rewards y disfrutar de los beneficos de Aeroméxico Rewards Business.
                          </p>
                          <a className="btn btn-primary position-md-absolute mb-30" href="#">Conoce más <i className="fas fa-arrow-right"/> </a>
                        </article>
                      </div>
                      <div style={{backgroundImage:"url(https://s3media.clubpremier.com/CPcms/21/CPC/hm-cpc-actualizaciondedatos.jpg)"}} className="col-6 d-none d-md-block p-0 col-img "></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* TERMS AND CONDITIONS */}
        <div className="section-corporativo terms py-60">
          <div className="d-flex content_collapse justify-content-center">
            <div className="col-11 col-sm-8 p-0">
              <div className="accordion" id="accordion-tc30">
                <div className="card card-collapsePrimary  ">
                  <div>
                    <h4 id="cpr-dup-trm-lnk" className="mb-0 card-header" data-toggle="collapse" data-target="#collapse30" aria-expanded="false" aria-controls="collapseOne">
                      <p>Consulta aquí términos y condiciones</p>
                      <i className="fas fa-lg fa-angle-down ml-auto"></i>
                    </h4>
                  </div>
                  <div id="collapse30" className="collapse" aria-labelledby="headingOne" data-parent="#accordion-tc30">
                    <hr className="dividerCollapse"/>
                    <div className="card-body">

                      <p><b>Adquisición de Ascensos a Clase Premier</b></p>
                      <p><b>Términos y condiciones</b></p>
                      <p>Usted declara reconocer y aceptar los presentes “Términos y Condiciones de adquisición de Ascensos a Clase Premier”.</p>
                      <p><b>Uso</b></p>
                      <p>Los Socios Aeroméxico Rewards (los “Socios” o el “Socio”) podrán adquirir un Ascenso del servicio de Clase Turista comprada originalmente para viajar con Aeroméxico o Aeroméxico Connect, a la Clase Premier ("Ascenso") con Puntos Aeroméxico Rewards conforme a lo establecido en los presentes Términos y Condiciones.</p>
                      <p>Para poder adquirir el ascenso con Puntos Aeroméxico Rewards se deberá:</p>
                      <ul>
                        <li>Ser Socio Aeroméxico Rewards de no serlo se puede inscribir gratis en <a href="https://member.clubpremier.com/registro">https://member.clubpremier.com/registro</a></li>
                        <li>Haber adquirido un vuelo con Aeroméxico o Aeroméxico Connect en Clase Turista (Tarifas participantes) con dinero o con Puntos Aeroméxico Rewards (Boleto Premio)</li>
                        <li>Asociar tu correo electrónico a tu Cuenta Aeroméxico Rewards</li>
                        <li>Adquirir el Ascenso redimiendo los Puntos Aeroméxico Rewards en <a href="https://member.clubpremier.com/ascensos">https://member.clubpremier.com/ascensos</a></li>
                        <li>Conservar copia de los boletos de avión para posibles aclaraciones.</li>
                      </ul>
                      <p><b>Vigencia</b></p>
                      <p>La vigencia de los presentes Términos y Condiciones es indefinida. Aeroméxico Rewards tiene la facultad de cancelar la presente promoción en cualquier momento y sin previo aviso.</p>
                      <p><b>Restricciones</b></p>
                      <ul>
                        <li>El Ascenso no aplicará a vuelos de código compartido o para vuelos operados por una aerolínea diferente de Aeroméxico.</li>
                        <li>El ascenso deberá adquirirse con Puntos Aeroméxico Rewards hasta 4 (cuatro) horas antes del vuelo y siempre y cuando haya disponibilidad en Cabina Premier para el vuelo.</li>
                      </ul>
                      <ul>
                        <li>El Ascenso es sobre clase F sujeto a disponibilidad.</li>
                        <li>Para los siguientes boletos no aplica la redención del Ascenso: 
                          <ul> a. Boletos en clases de servicio P, G, X. <br />b. Recompensa por afectación. <br />c. Boletos aéreos gratuitos (donaciones, sin cargo y otros). <br />d. Boletos de prestación para empleados, Pases o Unitickets. <br /> </ul>
                        </li>
                        <li>Si adquieres un Ascenso a la Clase Premier, tendrás el derecho a usar los siguientes servicios y beneficios: <ul> a. Mayor espacio y comodidad en su asiento. <br />b. Documentación en los mostradores SkyPriority. <br />c. Prioridad en ascenso y descenso del vuelo. <br />d. Prioridad en el manejo del equipaje. <br />e. Franquicia de equipaje de Clase Premier. <br />f. Alimentos de mayor calidad (no aplica en todas las rutas). <br /> </ul>
                        </li>
                        <li>Si adquieres un Ascenso, las condiciones del boleto aéreo original seguirán teniendo vigencia, con excepción de la franquicia permitida de equipaje. Esto incluye condiciones de: estadías mínimas y máximas, cancelación, penalidades y cambios.</li>
                        <li>En caso de que aplique acumulación de Puntos Aeroméxico Rewards, los Puntos Aeroméxico Rewards se acumularán conforme a la clase original y no conforme a la clase a la que se ascendió, sujeto a los Términos y Condiciones de dicho programa.</li>
                        <li>Al adquirir un Ascenso, Aeroméxico no puede garantizar un asiento específico en la Clase Premier. Su número de asiento dependerá de la disponibilidad de espacio.</li>
                        <li>Una vez realizada la redención de Puntos Aeroméxico Rewards por el Ascenso estos son: no reembolsables, no transferible y no endosable.</li>
                        <li>En caso de que Aeroméxico cancele su vuelo, se hará todo lo posible por brindarle espacio en Clase Premier en el vuelo en que se le dé protección para la misma ruta (sujeto a disponibilidad). Si no es posible ofrecerte espacio en la Clase Premier, el pasajero viajará en la clase comprada originalmente y el Ascenso será reembolsable comunicándose al call center de Aeroméxico Rewards.</li>
                        <li>Si el pasajero cambia, cancela o pierde su vuelo por causas ajenas a la aerolínea, o por caso fortuito o fuerza mayor, no habrá reembolso por el cobro de su Ascenso, aun cuando su boleto aéreo original sea reembolsable. Este Ascenso no se puede transferir a otro vuelo u otro pasajero.</li>
                        <li>No aplica el acceso a Salón Premier.</li>
                        <li>Si hay varios pasajeros en tu reservación, deberás adquirir el Ascenso para todos los pasajeros en la reservación por segmento.</li>
                      </ul>
                      <p><b>Fraudes</b></p>
                      <p>Aeroméxico Rewards se reserva el derecho a llevar a cabo acciones que considere pertinentes en caso de detectar fraudes, conductas fraudulentas o malas prácticas por parte de los Socios en las Cuentas Aeroméxico Rewards.</p>
                      <p><b>Términos y Condiciones Generales</b></p>
                      <p>Los Puntos Aeroméxico Rewards, estarán sujetos a las reglas del Programa Aeroméxico Rewards, mismos que se encuentran disponibles en “La Página”.</p>
                      <p>Aeroméxico Rewards se reserva el derecho de modificar los Planes, el monto, así como los Beneficios en cualquier momento, sin previo aviso.</p>
                      <p>La inscripción a Aeroméxico Rewards no supone garantía de disponibilidad de ningún premio del Programa Aeroméxico Rewards, no siendo responsable Aeroméxico Rewards de que el Socio pueda o no utilizar, canjear o redimir los Puntos Aeroméxico Rewards acumulados por la inscripción a Aeroméxico Rewards.</p>
                      <p>En caso de que cualquier inscripción a Aeroméxico Rewards sea utilizada por los Socios para fines impropios, u obtenida de forma indebida de una Tarjeta Bancaria, fraude o cualquier otro medio ilegal, Aeroméxico Rewards podrá, según su criterio, cancelar, anular o denegar la entrega y/o confiscar tales, así como perseguir todos y cada uno de los derechos y recursos que pueda haber disponibles.</p>
                      <p>El Socio acuerda cooperar con todas las solicitudes razonables de Aeroméxico Rewards encaminadas a la investigación o interposición de acciones judiciales contra cualquier implicado o sospechoso de estarlo en la utilización indebida o fraude con respecto a la inscripción al Programa Aeroméxico Rewards, así como a conductas fraudulentas o malas prácticas por parte de los Socios en las Cuentas Aeroméxico Rewards.</p>
                      <p>El Socio acepta que el premio y utilización de los Puntos Aeroméxico Rewards, están sujetos a los términos, condiciones, exclusiones y limitaciones del Programa Aeroméxico Rewards.</p>
                      <p>Los Puntos Aeroméxico Rewards referidos en el Programa Aeroméxico Rewards no son Puntos físicos reales sino representación de créditos en el Programa Aeroméxico Rewards. Dichos Puntos no son equivalentes ni convertibles a moneda corriente en efectivo.</p>
                      <p><b>Datos Personales</b></p>
                      <p>Todo tratamiento de datos personales realizado por Aeroméxico Rewards, así como el ejercicio de los Derechos ARCO por parte de los Socios, será efectuado conforme a la Ley y Reglamento aplicable en los Estados Unidos Mexicanos, así como en sujeción a lo establecido en el Aviso de Privacidad de Aeroméxico Rewards, el cual usted aceptó previo a su registro en Aeroméxico Rewards (para consulta del Aviso de Privacidad por favor ingresar a <a href="http://www.clubpremier.com">www.clubpremier.com</a>)</p>
                      <p>Atendiendo a lo señalado en la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita y derivado de ciertas actividades realizadas por los Socios, las cuales sean consideradas por la Ley como actividades vulnerables, Aeroméxico Rewards compartirá sus Datos Personales con las autoridades correspondientes.</p>
                      <p><b>Lavado de Dinero</b></p>
                      <p>Al aceptar y participar en la presente, usted otorga expresamente autorización para que Aeroméxico Rewards transfiera sus datos de contacto (nombre, número de socio, número telefónico, dirección de correo electrónico) a Aerovías de México, S.A. de C.V., así como a terceros subcontratados por Aeroméxico Rewards a efecto de otorgar los beneficios de la presente promoción, mismos datos que también podrán ser transferidos a las empresas que para tal efecto determine Aeroméxico Rewards (incluyendo, sin limitar, cadenas hoteleras, aerolíneas, servicios de transportación terrestre y similares).</p>
                      <p>La información que derive de los Avisos que se presenten ante las autoridades competentes será utilizada exclusivamente para la prevención, identificación, investigación y sanción de operaciones con recursos de procedencia ilícita y demás delitos relacionados con éstas. Los datos personales antes mencionados se mantendrán en resguardado absoluto, en los términos de la Constitución Política de los Estados Unidos Mexicanos.</p>
                      <p><b>Jurisdicción</b></p>
                      <p>Los presentes Términos y Condiciones, en relación a su interpretación o cumplimiento, se regirán por la Ley y jurisdicción de los Tribunales competentes de la Ciudad de México, renunciando desde ahora a cualquier otro fuero que, por razón de materia o domicilio, presente o futuro, pudiera corresponderles.</p>
                      <p>En caso de existir alguna duda o aclaración respecto a los presentes términos y condiciones, por favor contáctanos a través Centro de Atención Telefónica de Aeroméxico Rewards (55 1519 4040) o al correo electrónico: <a href="mailto:soporte@clubpremier.com">soporte@clubpremier.com</a></p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* END Terms and conditions */}
        </div>
      </div>
    );
  }
}
export default PointCalculator;