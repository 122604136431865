import React from "react";
class Illustrations extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>ILUSTRACIONES</h1>
                <div className="alert mb-0">
                    <div className="alert alert-texto mb-0">
                            <h4>
                                El uso de las ilustraciones en Aeroméxico Rewards Business debe ser bastante cuidadoso y debidamente implementado. Para esto se utilizará el recurso de undraw.co que proveé de imágenes en formato SVG. En caso de utilizarlas, el color que debe implementarse como principal es #FF8200.
                            </h4>
                        <div className="alert mb-0">
                           <div className="row row-basic">
                                <div className="img-b col-12 col-md-6">
                                    <div className="img-container">
                                        <img className="img-basic" src="https://corpstylo.aeromexicorewards.com/images/Illustrations/undraw_order_confirmed_aaw7+1.png" alt=""/>
                                    </div>
                                </div>
                                <div className="img-b col-12 col-md-6">
                                    <div className="img-container">
                                        <img className="img-basic" src="https://corpstylo.aeromexicorewards.com/images/Illustrations/Group+140.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-basic">
                                <div className="img-b col-12 col-md-6">
                                    <div className="img-container">
                                        <img className="img-basic" src="https://corpstylo.aeromexicorewards.com/images/Illustrations/undraw_travelers_qlt1+1.png" alt=""/>
                                    </div>
                                </div>
                                <div className="img-b col-12 col-md-6">
                                    <div className="img-container">
                                        <img className="img-basic" src="https://corpstylo.aeromexicorewards.com/images/Illustrations/Group+139.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 className="h5-etiqueta2" style={{marginBottom: '-15px'}}>Img basic</h5>
                        <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="img-container"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;img class="img-basic" src="" alt=""/&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code> 
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default Illustrations;
