import React from 'react';
import text from './../password.json';

class Login extends React.Component {
  constructor(props) {
    super();
  }
    changePage(idComponent){
        document.getElementById(idComponent).click();
    }
    utf8_to_b64( str ) {
      return window.btoa(unescape(encodeURIComponent( str )));
    }
    evaluateCred(){
        let user = document.getElementById("numUser").value;
        let password = document.getElementById("passUser").value;
        text.map(function(item, i){
            if(user === item.user && password === item.password){  
                localStorage.setItem('token', window.btoa(password));
                
                document.getElementById("main").click();
               }else{
                   document.getElementById("errorLogin").style.display = "block";
               }
        })

    }
  render() {
    return (
      <div>
        <section className="section-corp login-styleGuide">
        <div className = "row justify-content-center">
        <div className = "col-12 col-md-6 text-center">
        <h3>Iniciar sesión</h3>
        <div className="form-group">
        <div className="input-group">
        <label>Usuario</label>
        <input className="form-control" id="numUser" required></input>
        <label>Contraseña</label>
        <input className="form-control" id="passUser" required></input>
        <label className = "errorLogin" id = "errorLogin">Usuario o contraseña incorrectos</label>
        </div>
        <div className="form-group justify-content-center m-0">
        <button className="btn btn-primary mt-15" type="submit" onClick={this.evaluateCred}>Iniciar sesión</button>
        </div>
        </div>
        </div>
        </div>
        </section>
</div>
    );
  }
}
export default Login;