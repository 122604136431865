import React from "react";
class NavBar extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
                                        <div className="navbar-menu bg-corporate">
                                <nav className="container">
                                    <ul className="navbar">
                                        <li className="item level-one"><a className="link h6" href="#Desc">Descubre</a>
                                            <ul className="submenu d-none">
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg><span>Productos de vuelo</span></a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Salones premier</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Hoteles en Linea</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Autos</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Actividades</a></li>
                                            </ul>
                                        </li>
                                        <li className="item level-one"><a className="link h6" href="#a">Acumula</a></li>
                                        <li className="item level-one"><a className="h6" href="#u">Utiliza</a>
                                            <ul className="submenu d-none">
                                                <li className="item level-two"><a className="h6" href="#s"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg><span>Sub Menu nivel 2</span></a>
                                                    <ul className="subnivel d-none">
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                    </ul>
                                                </li>
                                                <li className="item level-two"><a className="h6" href="#s"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Sub Menu nivel 2</a>
                                                    <ul className="subnivel d-none">
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                    </ul>
                                                </li>
                                                <li className="item level-two"><a className="h6" href="#s"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Sub Menu nivel 2</a>
                                                    <ul className="subnivel d-none">
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="item level-one"><a className="link h6" href="#p">Promociones</a></li>
                                        <li className="item level-one"><a className="link h6" href="#c">Cuenta Corporativa</a>
                                            <ul className="submenu d-none">
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Cuenta Corporativa</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Cuenta Corporativa</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                    <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                </svg>Cuenta Corporativa</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Cuenta Corporativa</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                    <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                </svg>Cuenta Corporativa</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            
            <div className="alert alert-titulo mb-0 col-12">
                <h1>HEADER & NAVBAR</h1>
                <div className="alert mb-0">
                    <h4>
                        El header representa un grupo de elementos de ayuda introductoria o de navegación. Este elemento responde a las necesidades primarias del sistema y también representa a la marca. La estructura básica del header de CPC es la siguiente:
                    </h4>
                </div>
            </div>
            <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Estructura header</h5>
            <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                <div className="alert alert-view col-12 p-30-guia">
                    <div className="header-wrapper">
                        <div className="container header-corporate">
                            <div className="header-group logo">
                                <div className="cp-corporate-logo"><a href="/"><img src="https://s3media.clubpremier.info/clubpremierstyleguide/cpstyleguideinfo/images/logo-corporate.svg" alt=""/></a></div>
                                <div className="header-partners"><a href="/" target="_blank"><img src="https://s3media.clubpremier.info/clubpremierstyleguide/cpstyleguideinfo/images/aeromexico.svg"  alt=""/></a><a href="/" target="_blank"><img src="https://s3media.clubpremier.info/clubpremierstyleguide/cpstyleguideinfo/images/delta.svg"/></a><a href="/" target="_blank"><img src="https://s3media.clubpremier.info/clubpremierstyleguide/cpstyleguideinfo/images/airfrance.svg"/></a><a href="/" target="_blank"><img src="https://s3media.clubpremier.info/clubpremierstyleguide/cpstyleguideinfo/images/klm.svg"/></a></div>
                            </div>
                            <div className="header-group login">
                                <div><a className="btn btn-secondary btn-sm login-link" href="https://corporativo.clubpremier.info/login">Inicia sesión</a></div>
                                <div><a className="btn btn-primary btn-sm login-link" href="https://corporativo.clubpremier.info/corporativo/registro">Inscríbete</a></div>
                            </div>
                            <div className="header-group language">
                                <h6><a href="https://example.org/es">ES</a><span></span><a href="https://example.org/en">EN</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="alert alert-subtitulo mb-0" style={{paddingTop: '30px'}}>
                <h3>Navbar</h3>
                <div className="alert alert-texto mb-0">
                    <h4>
                        Las cards o tarjetas pueden utilizarse para crear mosaicos de las mismas o de acuerdo a la necesidad, pueden agruparse en carruseles. A continuación se enslistan las características que deben cumplir.
                    </h4>
                    <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Estructura navbar</h5>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px', minHeight: '150px'}}>
                        <div className="alert alert-view col-12 p-30-guia">
                            <div className="navbar-menu-responsive">
                                <div className="main-bar">
                                    <div><a className="menu-toggle" href="#nav-menu-responsive" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-responsive"><span className="fas fa-bars"></span></a></div>
                                    <div><button className="btn btn-secondary btn-sm btn-size-override" href="https://corporativo.clubpremier.info/login">Inicia sesión</button> <button className="btn btn-primary btn-sm btn-size-override" href="https://corporativo.clubpremier.info/corporativo/registro">Inscríbete</button></div>
                                </div>
                                <div className="menu-container collapse" id="nav-menu-responsive">
                                    <div className="menu-content level-1">
                                        <div className="menu-item">
                                            <div className="menu-item-label icon-times"><a className="menu-toggle" href="#nav-menu-responsive" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-responsive"><span className="fas fa-times"></span></a></div>
                                        </div>
                                        <div className="menu-item">
                                            <div className="menu-item-label">
                                                <a href="#nav-menu-1" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-1">Descubre</a>
                                                <div className="menu-content level-2 collapse" id="nav-menu-1">
                                                <div className="menu-item">
                                                    <div className="menu-item-label">
                                                        <a href="#nav-menu-1-1" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-1-1">
                                                            <svg><use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'} ></use></svg> Lorem
                                                        </a> 
                                                    </div>
                                                    <div className="menu-content level-3 collapse" id="nav-menu-1-1">
                                                        <a className="menu-item" href="#l">Lorem</a>
                                                        <a className="menu-item" href="#l">Lorem</a>
                                                        <a className="menu-item" href="#l">Lorem</a>
                                                    </div>
                                                </div>
                                                <a className="menu-item" href="#l">
                                                    <svg><use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-hoteles'}></use>
                                                    </svg> 
                                                    Lorem
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="menu-item">
                                            <div className="menu-item-label">
                                                <a href="#nav-menu-1" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-1">Acumula</a>
                                            </div>
                                            <div className="menu-content level-2 collapse" id="nav-menu-1">
                                                <div className="menu-item">
                                                    <div className="menu-item-label">
                                                        <a href="#nav-menu-1-1" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-1-1">
                                                            <svg><use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas"'}></use></svg> Aerolíneas
                                                        </a>
                                                    </div>
                                                    <div className="menu-content level-3 collapse" id="nav-menu-1-1">
                                                        <a className="menu-item" href="https://corporativo.clubpremier.info/mx/acumula/aerolineas/aeromexico">Aeroméxico</a>
                                                        <a className="menu-item" href="#s">SkyTeam</a>
                                                        <a className="menu-item" href="#o">Otras aerolíneas</a>
                                                    </div>
                                                </div>
                                                <a className="menu-item" href="https://hotelesenlinea.clubpremier.info/c/home/es-MX/">
                                                    <svg><use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-hoteles'}></use>
                                                    </svg> 
                                                    Hoteles en línea
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="navbar-menu bg-corporate">
                                <nav className="container">
                                    <ul className="navbar">
                                        <li className="item level-one"><a className="link h6" href="#d">Descubre</a>
                                            <ul className="submenu d-none">
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Productos de vuelo</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Salones premier</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Hoteles en Linea</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Autos</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Actividades</a></li>
                                            </ul>
                                        </li>
                                        <li className="item level-one"><a className="link h6" href="#a">Acumula</a></li>
                                        <li className="item level-one"><a className="h6" href="#u">Utiliza</a>
                                            <ul className="submenu d-none">
                                                <li className="item level-two"><a className="h6" href="#s"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Sub Menu nivel 2</a>
                                                    <ul className="subnivel d-none">
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                    </ul>
                                                </li>
                                                <li className="item level-two"><a className="h6" href="#s"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Sub Menu nivel 2</a>
                                                    <ul className="subnivel d-none">
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                    </ul>
                                                </li>
                                                <li className="item level-two"><a className="h6" href="#s"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Sub Menu nivel 2</a>
                                                    <ul className="subnivel d-none">
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                        <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/">Lorem ipsum dolor sit</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="item level-one"><a className="link h6" href="#p">Promociones</a></li>
                                        <li className="item level-one"><a className="link h6" href="#c">Cuenta Corporativa</a>
                                            <ul className="submenu d-none">
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Cuenta Corporativa</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Cuenta Corporativa</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                    <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                </svg>Cuenta Corporativa</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                            <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                        </svg>Cuenta Corporativa</a></li>
                                                <li className="item"><a className="sub-link h6" href="https://www.clubpremier.info/mx/bienvenido/"><svg>
                                                    <use  xmlnsXlink={'http://www.w3.org/1999/xlink'} xlinkHref={'#shape-menu-aerolineas'}></use>
                                                </svg>Cuenta Corporativa</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
  }
}
export default NavBar;
