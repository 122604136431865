import React from 'react';
import Login from './components/login';
import MainStyle from './components/main';

import Cards from './components/cards';
import Dividers from './components/dividers';
import Forms from './components/forms';
import Navbar from './components/navbar';
import Iconography from './components/iconography';
import Iconos from './components/iconos';
import Illustrations from './components/illustrations';
import Logos from './components/logos';
import Modals_and_carrousel from './components/modals_and_carrousel';
import Progress_indicators from './components/progress_indicators';
import Tables_and_collapse from './components/tables_and_collapse';
import Titles_and_text from './components/titles_and_text';
import Buttons from './components/buttons';
import Color_palette from './components/color_palette';
import Alerts_and_tooltips from './components/alerts_and_tooltips';
import Footer from './components/footer';
import Grid from './components/grid';
import LoginCp from './components/login_cp';
import EnrollmentInit from './components/enrollment_init';
import EnrollmentForm from './components/enrollment_form';
import HomeMember from './components/home_member';
import HeaderFoterContainer from './components/header-footer-container.js'
import HeaderFoterContainerLogged from './components/header-footer-container-logged';
import CorporateProfile from './components/corporate_profile';
import AccountStatement from './components/account_statement';
import RepresentContacts from './components/represent-and-contacts';
import CardsMemberships from './components/cards-memberships';
import RetroactiveAccreditations from './components/retroactive-accreditations';
import BuyPoints from './components/buy-points';
import RecoveryPoints from './components/recovery-points';
import PointCalculator from './components/point-calculator';
import Upgrades from './components/upgrades';
import UpgradesDynamicAncilliaries from './components/upgrades-dynamic-ancilliaries';
import {CARDS, DIVIDERS, FORMS, NAVBAR, ICONOGRAPHY, ICONOS, ILLUSTRATIONS, LOGOS, 
  MODALS_AND_CARROUSEL, PROGRESS_INDICATORS, TABLES_AND_COLLAPSE, TITLES_AND_TEXT, 
  BUTTONS, COLOR_PALETTE, APP, ALERTS_AND_TOOLTIPS, FOOTER, GRID, LOGIN_CP, ENROLLMENT_INIT, ENROLLMENT_FORM, HOME_MEMBER, CORPORATE_PROFILE, ACCOUNT_STATEMENT} from './constantes';
import { Link } from 'react-router-dom';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Component } from 'react';
import ProtectedRoute from './ProtectedRoute';
import Faq from './components/faq';
import HomeCms from './components/home_cms';

class App extends Component {
  constructor(props){
    super();
  }
  render(){        
    return ( 
      <div className="app">
        <div id  ="principal">
          <BrowserRouter>
            <div display="none" ><Link id="cards" to={CARDS} ></Link></div>
            <div display="none" ><Link id="dividers" to={DIVIDERS} ></Link></div>
            <div display="none" ><Link id="forms" to={FORMS} ></Link></div>
            <div display="none" ><Link id="buttons" to={BUTTONS} ></Link></div>
            <div display="none" ><Link id="navbar" to={NAVBAR} ></Link></div>
            <div display="none" ><Link id="iconography" to={ICONOGRAPHY} ></Link></div>
            <div display="none" ><Link id="iconos" to={ICONOS} ></Link></div>
            <div display="none" ><Link id="buttons" to={BUTTONS} ></Link></div>
            <div display="none" ><Link id="ilustrations" to={ILLUSTRATIONS} ></Link></div>
            <div display="none" ><Link id="logos" to={LOGOS} ></Link></div>
            <div display="none" ><Link id="modals_carrousel" to={MODALS_AND_CARROUSEL} ></Link></div>
            <div display="none" ><Link id="progress_indicators" to={PROGRESS_INDICATORS} ></Link></div>
            <div display="none" ><Link id="tables_collapse" to={TABLES_AND_COLLAPSE} ></Link></div>
            <div display="none" ><Link id="title_text" to={TITLES_AND_TEXT} ></Link></div>
            <div display="none" ><Link id="color_palette" to={COLOR_PALETTE} ></Link></div>
            <div display="none" ><Link id="main" to={APP} ></Link></div>
            <div display="none" ><Link id="alerts_and_tooltips" to={ALERTS_AND_TOOLTIPS} ></Link></div>
            <div display="none" ><Link id="footer" to={FOOTER} ></Link></div>
            <div display="none" ><Link id="grid" to={GRID} ></Link></div>
            <div display="none" ><Link id="login_cp" to={LOGIN_CP} ></Link></div>
            <div display="none" ><Link id="enrollment_init" to={ENROLLMENT_INIT} ></Link></div>
            <div display="none" ><Link id="enrollment_form" to={ENROLLMENT_FORM} ></Link></div>
            <div display="none" ><Link id="home_member" to={HOME_MEMBER} ></Link></div>
            <div display="none" ><Link id="corporate_profile" to={CORPORATE_PROFILE} ></Link></div>
            <div display="none" ><Link id="account_statement" to={ACCOUNT_STATEMENT} ></Link></div>
            <Switch>
              <Route  path="/login" component={Login}  />
              <ProtectedRoute exact={true} path="/" component={MainStyle} />
              <ProtectedRoute path="/buttons" component={Buttons}  />
              <ProtectedRoute path="/cards" component={Cards} />
              <ProtectedRoute path="/dividers" component={Dividers}  />
              <ProtectedRoute path="/forms" component={Forms} />
              <ProtectedRoute path="/navbar" component={Navbar} />
              <ProtectedRoute path="/iconography" component={Iconography}  />
              <ProtectedRoute path="/iconos" component={Iconos} />
              <ProtectedRoute path="/illustrations" component={Illustrations} />
              <ProtectedRoute path="/logos" component={Logos} />
              <ProtectedRoute path="/modals_and_carrousel" component={Modals_and_carrousel} />
              <ProtectedRoute path="/progress_indicators" component={Progress_indicators} />
              <ProtectedRoute path="/tables_and_collapse" component={Tables_and_collapse} />
              <ProtectedRoute path="/titles_and_text" component={Titles_and_text} />
              <ProtectedRoute path="/color_palette" component={Color_palette} />
              <ProtectedRoute path="/alerts_and_tooltips" component={Alerts_and_tooltips} />
              <ProtectedRoute path="/footer" component={Footer} />
              <ProtectedRoute path="/grid" component={Grid} />
              <ProtectedRoute path="/login_cp" component={LoginCp} />
              <ProtectedRoute path="/enrollment_init" component={EnrollmentInit} />
              <ProtectedRoute path="/enrollment_form" component={EnrollmentForm} />
              <ProtectedRoute path="/header-footer" component={HeaderFoterContainer} />
              <ProtectedRoute path="/header-footer-logged" component={HeaderFoterContainerLogged} />
              <ProtectedRoute path="/home_member" component={HomeMember} />
              <ProtectedRoute path="/corporate_profile" component={CorporateProfile} />
              <ProtectedRoute path="/account_statement" component={AccountStatement} />
              <ProtectedRoute path="/represent-and-contacts" component={RepresentContacts} />
              <ProtectedRoute path="/cards-and-memberships" component={CardsMemberships} />
              <ProtectedRoute path="/retroactive-accreditations" component={RetroactiveAccreditations} />
              <ProtectedRoute path="/buy-points" component={BuyPoints} />
              <ProtectedRoute path="/recovery-points" component={RecoveryPoints} />
              <ProtectedRoute path="/faq" component={Faq} />
              <ProtectedRoute path="/point-calculator" component={PointCalculator} />
              <ProtectedRoute path="/upgrades" component={Upgrades} />
              <ProtectedRoute path="/upgrades-dynamic-ancilliaries" component={UpgradesDynamicAncilliaries} />

              <ProtectedRoute path="/home-cms" component={HomeCms} />
            </Switch>
          </BrowserRouter>
        </div>
      </div>
    );
  }
}

export default App;