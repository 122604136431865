import React from "react";
class Cards extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>CARDS</h1>
                <div className="alert mb-0">
                    <h4>
                    Las cards o tarjetas son uno de los elementos más convenientes para mostrar contenido que se encuentra compuesto por diferentes tipos de elementos. Podemos utilizarlas como un simple diálogo o incluso una ventana hacia lo aspiracional. 
                    </h4>
                </div>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0">
                    <h3>Categorias</h3>
                    <h5 className="h5-etiqueta">Card básica</h5>
                    <div className="row p-30-guia">
                        <div className="alert alert-view col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="row row-basic">
                                <div className="card-basic">
                                    <div className="card-title">
                                        <h4>Card title</h4>
                                    </div>
                                    <div className="card-divider">
                                        <hr className="cardDividers"/>
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.
                                        Some quick example text to build on the card title and make up the bulk of the card's content.
                                        Some quick example text to build on the card title and make up the bulk of the card's content.
                                        Some quick example text to build on the card title and make up the bulk of the card's content.
                                        Some quick example text to build on the card title and make up the bulk of the card's content.
                                        </p>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="alert alert-view-code col-12 col-md-6 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="card-basic"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-title"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card title&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-divider"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;hr class="cardDividers"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-body"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="card-text"&gt;TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>                  
                        </div>                  
                    </div> 
                    <h5 className="h5-etiqueta">Card con imagen</h5>
                    <div className="row p-30-guia">
                        <div className="alert alert-view col-12 col-md-5 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="row row-basic">
                                <div className="card-with-img">
                                    <div className="card-img-v">
                                        <img  src="http://placehold.it/350x200" alt=""/>
                                    </div>
                                    <div className="card-title">
                                        <h4>Card title</h4>
                                    </div>
                                    <div className="card-divider">
                                        <hr className="cardDividers"/>
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    </div>
                                    <div className="card-footer">
                                        <a href="#/" className="card-link">Another link</a>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="alert alert-view-code col-12 col-md-7 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="card-with-img"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-img-v"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img class="" src="http://placehold.it/350x200" alt="Card image cap"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-title"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card title&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-divider"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;hr class="cardDividers"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-body"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="card-text"&TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-footer"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a href="#" class="card-link"&gt;Another link&lt;/a&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>                  
                        </div>                  
                    </div> 
                    <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Card con imagen horizontal</h5>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                        <div className="alert alert-view col-12 p-30-guia">
                            <div className="card-with-img-h">
                                <div className="row card-h">
                                    <div className="card-img-h col-12 col-md-4">
                                        <img className="img-card-h" src="https://corpstylo.aeromexicorewards.com/images/Illustrations/Group+139.png" alt=""/>
                                    </div>
                                    <div className="col-12 col-md-8 p-0">
                                        <div className="card-title">
                                            <h4>Card title</h4>
                                        </div>
                                        <div className="card-divider">
                                            <hr className="cardDividers"/>
                                        </div>
                                        <div className="card-body-h">
                                            <p className="card-text">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullacorper integer convallis rhoncus.
                                            </p>
                                        </div>
                                        <div className="card-footer">
                                            <a href="#/" className="card-link">Another link</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                        <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="card-with-img-h"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="row card-h"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-img-h col-12 col-md-4"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img class="img-card-h" src="" alt=""/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-12 col-md-8 p-0"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-title"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card title&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-divider"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;hr class="cardDividers"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-body-h"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="card-text"&gt;TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-footer"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a href="#" class="card-link"&gt;Another link&lt;/a&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>            
                        </div> 
                    </div> 
                    <h5 className="h5-etiqueta">Card con beneficios</h5>
                    <div className="row p-30-guia">
                        <div className="alert alert-view col-12 col-md-5 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="row row-basic">
                                <div className="card-with-img">
                                    <div className="card-img-b">
                                        <img className="" src="http://placehold.it/350x200" alt=""/>
                                    </div>
                                    <div className="card-title">
                                        <h4>Card title</h4>
                                    </div>
                                    <div className="card-divider">
                                        <hr className="cardDividers"/>
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                        </p>
                                    </div>
                                    <div className="card-footer">
                                        <a href="#/" className="card-link">Another link</a>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="alert alert-view-code col-12 col-md-7 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="card-with-img"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-img-b"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img class="" src="http://placehold.it/350x200" alt="Card image cap"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-title"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card title&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-divider"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;hr class="cardDividers"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-body"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="card-text"&gt;TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-footer"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a href="#" class="card-link"&gt;Another link&lt;/a&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>                  
                        </div>                  
                    </div> 
                    <h5 className="h5-etiqueta">Card de venta</h5>
                    <div className="row p-30-guia">
                        <div className="alert alert-view col-12 col-md-5 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="row row-basic">
                                <div className="card-with-img">
                                    <div className="card-img-b">
                                        <div className="labelRecommended">
                                            <p className="recommended">Recomendado</p>
                                        </div>
                                        <img className="" src="http://placehold.it/350x200" alt=""/>
                                    </div>
                                    <div className="card-title">
                                        <h4>Card title</h4>
                                    </div>
                                    <div className="card-divider">
                                        <hr className="cardDividers"/>
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                        </p>
                                    </div>
                                    <div className="card-footer-venta">
                                        <button className="btn btn-outline-primary">Lorem ipsum dolor sit<i className="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="alert alert-view-code col-12 col-md-7 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="card-with-img"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-img-b"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="labelRecommended"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="recommended"&gt;Recomendado&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img class="" src="http://placehold.it/350x200" alt="Card image cap"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-title"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card title&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-divider"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;hr class="cardDividers"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-body"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="card-text"&gt;TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-footer-venta"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button class="btn btn-outline-primary"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TEXTO.&lt;i class="fas fa-arrow-right"&gt;&lt;/i&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/button&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>                  
                        </div>                  
                    </div> 
                    <h5 className="h5-etiqueta">Card inspiracionales</h5>
                    <div className="row p-30-guia">
                        <div className="alert alert-view col-12 col-md-5 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="row row-basic">
                                <div className="card-ins">
                                    <div className="card-img-ins">
                                        <img className="card-img-ins" src="http://placehold.it/720x480" alt=""/>
                                    </div>
                                    <div className="card-body-ins">
                                        <div className="card-title-ins">
                                            <h4>Card title</h4>
                                        </div>
                                        <p className="card-text-ins">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="alert alert-view-code col-12 col-md-7 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="card-ins"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-img-ins"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img class="card-img-ins" src="http://placehold.it/720x480" alt="Card image cap"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-body-ins"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-title-ins"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card title&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="card-text-ins"&gt;TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>                  
                        </div>                  
                    </div> 
                    <h5 className="h5-etiqueta">Card saber mas</h5>
                    <div className="row p-30-guia">
                        <div className="alert alert-view col-12 col-md-5 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="row row-basic">
                                <div className="card-saber-mas">
                                    <div className="card-img-v">
                                        <img className="" src="http://placehold.it/350x200" alt=""/>
                                    </div>
                                    <div className="card-title">
                                        <h4>Card title</h4>
                                    </div>
                                    <div className="card-divider">
                                        <hr className="cardDividers"/>
                                    </div>
                                    <div className="card-footer-saber">
                                        <a href="#/" className="card-link" data-toggle="collapse" href="#collapseExample">Saber más<i className='fas fa-plus'></i></a>
                                    </div>
                                    <div className="card-body collapse" id="collapseExample">
                                        <p className="card-text">
                                            This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                        </p>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="alert alert-view-code col-12 col-md-7 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="card-saber-mas"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-img-v"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img class="" src="http://placehold.it/350x200" alt="Card image cap"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-title"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card title&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-divider"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;hr class="cardDividers"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-footer-saber"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a href="#" class="card-link" data-toggle="collapse" href="#collapseExample"&gt; Saber más &lt;i class='fas fa-plus'&gt;&lt;/i&gt;&lt;/a&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="card-body collapse" id="collapseExample"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="card-text"&gt;TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>                  
                        </div>                  
                    </div> 
                </div>
            </div>
            <div className="alert alert-subtitulo mb-0">
                <h3>Uso de las cards en carrusel</h3>
                <div className="alert alert-texto mb-0">
                    <h4>
                        Las cards o tarjetas pueden utilizarse para crear mosaicos de las mismas o de acuerdo a la necesidad, pueden agruparse en carruseles. A continuación se enslistan las características que deben cumplir.
                    </h4>
                    <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Card inspiracionales (carrusel)</h5>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                        <div className="alert alert-view col-12 p-30-guia">
                            <div className="row row-basic">
                                <div className="carrusel-ins">
                                    <div id="carousel-example-1z" className="carousel slide carousel-fade" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                            <li data-target="#carousel-example-1z" data-slide-to="0" className="active"></li>
                                            <li data-target="#carousel-example-1z" data-slide-to="1"></li>
                                            <li data-target="#carousel-example-1z" data-slide-to="2"></li>
                                        </ol>
                                        <div className="carousel-inner" role="listbox">
                                            <div className="carousel-item active">
                                                <img className="d-block img-carrusel w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(130).jpg" alt="First slide"/>
                                                <div className="card-body-ins-car">
                                                    <div className="card-title-ins">
                                                        <h4>Card title</h4>
                                                    </div>
                                                    <p className="card-text-ins">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                    <button className="btn btn-primary">Lorem ipsum dolor sit am</button>
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block img-carrusel w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(70).jpg" alt="Second slide"/>
                                                <div className="card-body-ins-car">
                                                    <div className="card-title-ins">
                                                        <h4>Card title</h4>
                                                    </div>
                                                    <p className="card-text-ins">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                    <button className="btn btn-primary">Lorem ipsum dolor sit am</button>
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block img-carrusel w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(1).jpg" alt="Third slide"/>
                                                <div className="card-body-ins-car">
                                                    <div className="card-title-ins">
                                                        <h4>Card title</h4>
                                                    </div>
                                                    <p className="card-text-ins">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                    <button className="btn btn-primary">Lorem ipsum dolor sit am</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                        <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div class="carrusel-ins"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;div id="carousel-example-1z" class="carousel slide carousel-fade" data-ride="carousel"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ol class="carousel-indicators"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li data-target="#carousel-example-1z" data-slide-to="0" class="active"&gt;&lt;/li&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li data-target="#carousel-example-1z" data-slide-to="1"&gt;&lt;/li&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li data-target="#carousel-example-1z" data-slide-to="2"&gt;&lt;/li&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ol&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="carousel-inner" role="listbox"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="carousel-item active"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img class="d-block img-carrusel w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(130).jpg" alt="First slide"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-body-ins-car"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-title-ins"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card title&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="card-text-ins"&gt;TEXTO.et, consectetur adipiscing elit.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button class="btn btn-primary"&gt;TEXTO.&lt;/button&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="carousel-item"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img class="d-block img-carrusel w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(70).jpg" alt="Second slide"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-body-ins-car"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-title-ins"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card title&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="card-text-ins"&gt;TEXTO.et, consectetur adipiscing elit.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button class="btn btn-primary"&gt;TEXTO.&lt;/button&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="carousel-item"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img class="d-block img-carrusel w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(1).jpg" alt="Third slide"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-body-ins-car"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-title-ins"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card title&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="card-text-ins"&gt;TEXTO.et, consectetur adipiscing elit.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button class="btn btn-primary"&gt;TEXTO.&lt;/button&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code>
                        </div> 
                    </div> 
                    <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Card con beneficios (carrusel)</h5>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                        <div className="alert alert-view col-12 p-30-guia">
                            <div id="myCarousel" className="carousel car-card-ins slide carousel-multiItems" data-ride="carousel">
                                <div className="carousel-inner" role="listbox">
                                    <div className="carousel-item active">
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <img className="" src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p>This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                                </div>
                                                <div className="card-footer">
                                                    <a href="#/" className="card-link">Another link</a>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <img className="" src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p>This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                                </div>
                                                <div className="card-footer">
                                                    <a href="#/" className="card-link">Another link</a>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p>This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                                </div>
                                                <div className="card-footer">
                                                    <a href="#/" className="card-link">Another link</a>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p>This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                                </div>
                                                <div className="card-footer">
                                                    <a href="#/" className="card-link">Another link</a>
                                                </div>
                                            </div>
                                        </div>   
                                    </div>
                                    <div className="carousel-item">
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p>This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                                </div>
                                                <div className="card-footer">
                                                    <a href="#/" className="card-link">Another link</a>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p>This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                                </div>
                                                <div className="card-footer">
                                                    <a href="#/" className="card-link">Another link</a>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p>This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                                </div>
                                                <div className="card-footer">
                                                    <a href="#/" className="card-link">Another link</a>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3  card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p>This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                                </div>
                                                <div className="card-footer">
                                                    <a href="#/" className="card-link">Another link</a>
                                                </div>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <a className="carousel-control-prev w-auto m-0" href="#myCarousel" role="button" data-slide="prev" style={{marginLeft: '0'}}>
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next w-auto" href="#myCarousel" role="button" data-slide="next" style={{marginRight: '0'}}>
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                        <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                            <code>
                                &lt;div id="myCarousel" class="carousel car-card-ins slide carousel-multiItems" data-ride="carousel"&gt; <br/>
                                &nbsp;&nbsp;&nbsp;&lt;div class="carousel-inner" role="listbox"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="carousel-item active"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="col-12 col-md-6 col-lg-4 col-xl-3 card-item"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-with-img"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-img-b"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img class="" src="http://placehold.it/350x200" alt="Card image cap"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-title"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card title&lt;/h4&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-divider"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;hr class="cardDividers"/&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-body"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;TEXTO.&lt;/p&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-footer"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a href="#" class="card-link"&gt;Another link&lt;/a&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt; <br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;a class="carousel-control-prev w-auto m-0" href="#myCarousel" role="button" data-slide="prev"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &lt;span class="carousel-control-prev-icon" aria-hidden="true"&gt;&lt;/span&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="sr-only"&gt;Previous&lt;/span&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/a&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;a class="carousel-control-next w-auto" href="#myCarousel" role="button" data-slide="next"&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="carousel-control-next-icon" aria-hidden="true"&gt;&lt;/span&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="sr-only"&gt;Next&lt;/span&gt;<br/>
                                &nbsp;&nbsp;&nbsp;&lt;/a&gt;<br/>
                                &lt;/div&gt;<br/>
                            </code> 
                        </div> 
                    </div> 
                    <div className="alert alert-texto mb-0">
                        <div className="alert alert-texto mb-0">
                            <h4>
                                NOTA: Por cuestion de espacio y logica de programacion la seccion de codigo del elemento "Card con beneficios (carrusel)" fue simplificada.
                            </h4><br/>
                            <h4>
                                NOTA: Dicho carrusel fue diseñado para admitir varios tipos de Cards dentro de el, como el siguiente ejemplo.
                            </h4>
                        </div>
                    </div>
                    <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Card con beneficios (carrusel)</h5>
                    <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                        <div className="alert alert-view col-12 p-30-guia">
                            <div id="myCarousel" className="carousel car-card-ins slide carousel-multiItems" data-ride="carousel">
                                <div className="carousel-inner" role="listbox">
                                    <div className="carousel-item active">
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <div className="labelRecommended">
                                                        <p className="recommended">Recomendado</p>
                                                    </div>
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                                    </p>
                                                </div>
                                                <div className="card-footer-venta">
                                                    <button className="btn btn-outline-primary">Lorem ipsum dolor sit<i className="fas fa-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <div className="labelRecommended">
                                                        <p className="recommended">Recomendado</p>
                                                    </div>
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                                    </p>
                                                </div>
                                                <div className="card-footer-venta">
                                                    <button className="btn btn-outline-primary">Lorem ipsum dolor sit<i className="fas fa-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <div className="labelRecommended">
                                                        <p className="recommended">Recomendado</p>
                                                    </div>
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                                    </p>
                                                </div>
                                                <div className="card-footer-venta">
                                                    <button className="btn btn-outline-primary">Lorem ipsum dolor sit<i className="fas fa-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <div className="labelRecommended">
                                                        <p className="recommended">Recomendado</p>
                                                    </div>
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                                    </p>
                                                </div>
                                                <div className="card-footer-venta">
                                                    <button className="btn btn-outline-primary">Lorem ipsum dolor sit<i className="fas fa-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </div>   
                                    </div>
                                    <div className="carousel-item">
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <div className="labelRecommended">
                                                        <p className="recommended">Recomendado</p>
                                                    </div>
                                                    <img  src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                                    </p>
                                                </div>
                                                <div className="card-footer-venta">
                                                    <button className="btn btn-outline-primary">Lorem ipsum dolor sit<i className="fas fa-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <div className="labelRecommended">
                                                        <p className="recommended">Recomendado</p>
                                                    </div>
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                                    </p>
                                                </div>
                                                <div className="card-footer-venta">
                                                    <button className="btn btn-outline-primary">Lorem ipsum dolor sit<i className="fas fa-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <div className="labelRecommended">
                                                        <p className="recommended">Recomendado</p>
                                                    </div>
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                                    </p>
                                                </div>
                                                <div className="card-footer-venta">
                                                    <button className="btn btn-outline-primary">Lorem ipsum dolor sit<i className="fas fa-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="col-12 col-md-6 col-lg-4 col-xl-3  card-item">
                                            <div className="card-with-img">
                                                <div className="card-img-b">
                                                    <div className="labelRecommended">
                                                        <p className="recommended">Recomendado</p>
                                                    </div>
                                                    <img src="http://placehold.it/350x200" alt=""/>
                                                </div>
                                                <div className="card-title">
                                                    <h4>Card title</h4>
                                                </div>
                                                <div className="card-divider">
                                                    <hr className="cardDividers"/>
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                                    </p>
                                                </div>
                                                <div className="card-footer-venta">
                                                    <button className="btn btn-outline-primary">Lorem ipsum dolor sit<i className="fas fa-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <a className="carousel-control-prev w-auto m-0" href="#myCarousel" role="button" data-slide="prev" style={{marginLeft: '0'}}>
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next w-auto" href="#myCarousel" role="button" data-slide="next" style={{marginRight: '0'}}>
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default Cards;
