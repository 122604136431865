import React from "react";
class ColorPalette extends React.Component {
  constructor(props) {
    super();
  }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>PALETA DE COLORES</h1>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0">
                    <h3>Colores Primarios</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                        Los colores pimarios el es azul y anaranjado, de ellos estamos desprendiendo diferentes tintas para poder utilizar los colores de acuerdo al peso visual de los diferentes elementos. El uso del degrado es de manera exclusiva para bloques que busquen resaltar información.
                        </h4>
                        <div className="alert mb-0 row">
                            <div className="col-md-3 col-sm-6 col-12 p-2">
                                <div className="card-color-100" style={{background: '#425563'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #496C82
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 73, 108, 130
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#6C93AC'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#9DB7C8'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#CEDBE3'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12 p-2">
                                <div className="card-color-100" style={{background: '#5B7F95'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #5B7F95
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 91, 127, 149
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#81A0B2'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#ABBFCC'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#D5DFE5'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12 p-2">
                                <div className="card-color-100" style={{background: '#FF8200'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #FF8200
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 255, 130, 0
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#FFA140'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#FFC180'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#FFE0BF'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12 p-2">
                                <div className="card-color-100" style={{background: '#FF6200'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #FF6200
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 255, 130, 0
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#FF8940'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#FFB180'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#FFD8BF'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-12 p-2">
                                <div className="card-color-100 card-color-100-radial" style={{background: 'radial-gradient(92.12% 90.78% at 87.2% 90.78%, #1D3148 35.18%, #3D698A 100%)', mixBlendMode: 'normal'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div style={{padding: '30px', paddingTop: '15px'}}>
                                            <code style={{color: '#ffffff'}}>
                                            &#123; background&#58; radial-gradient(92.12% 90.78% at 87.2% 90.78%, #1D3148 35.18%, #3D698A 100%); }
                                            </code>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3>Colores Secundarios</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                        Contamos con una paleta secundaria que ayuda a reforzar la comunicación de acumulación y uso de Puntos  Premier. En el caso del color aqua  será  utilizado para comunicar acumulación, funciona como acento de color más nunca como fondo. En el caso del color rosa se usa cuando comunicamos uso de los Puntos Aeroméxico Rewards, funciona como acento de color más nunca como fondo.
                        </h4>
                        <div className="alert mb-0 row">
                            <div className="col-md-6 col-sm-6 col-12 p-2">
                                <div className="card-color-100" style={{background: '#00B388'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #00B388
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 0, 179, 136
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#07FFC3'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#5AFFD7'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#ACFFEB'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-12 p-2">
                                <div className="card-color-100" style={{background: '#AC145A'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #AC145A
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 172, 20, 90
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#E62980'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#EE71AB'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#F7B8D5'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3>Colores Complementarios</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                            La paleta complementaria consta de los siguientes colores, podrán utilizarse las tintas establecidas. Es importante resaltar que son de uso exclusivo para dennotar diferentes status.
                        </h4>
                        <div className="alert mb-0 row">
                            <div className="col-md-3 col-sm-6 col-12 p-2">
                                <div className="card-color-100" style={{background: '#42A94B'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #42A94B
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 66, 169, 75
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#6BC573'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#9CD8A1'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#CEECD0', opacity: '0.28'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12 p-2">
                                <div className="card-color-100" style={{background: '#426DA9'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #426DA9
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 66, 109, 169
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#6B90C5'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#9CB5D8'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#CEDAEC'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12 p-2">
                                <div className="card-color-100" style={{background: '#FFC845'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #FFC845
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 255, 200, 69
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#FFD673'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#FFE3A2'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#FFF1D1'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-12 p-2">
                                <div className="card-color-100" style={{background: '#CA0F0F'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #CA0F0F
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 202, 15, 15
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#F03232'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#F57777'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#FABBBB'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3>Colores De Tipografia</h3>
                    <div className="alert alert-texto mb-0">
                        <div className="alert mb-0 row color-tipog">
                            <div className="col-color-4 col-12 p-2">
                                <div className="card-color-100" style={{background: '#425563'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #425563
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 66, 85, 99
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#658197'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#98ABBA'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#CBD5DC'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-color-4 col-12 p-2">
                                <div className="card-color-100" style={{background: '#444444'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                        <div className="row">
                                            &nbsp;
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            HEX #444444
                                        </div>
                                        <div className="row" style={{marginLeft: '10px'}}>
                                            RGB 68, 68, 68
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-75" style={{background: '#737373'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            75%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-50" style={{background: '#A2A2A2'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            50%
                                        </div>
                                    </div>
                                </div>
                                <div className="card-color-25" style={{background: '#D0D0D0'}}>
                                    <div className="alert card-color-texto1 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '5px'}}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-color-3 col-12 p-2">
                                <div className="card-color-100-b" style={{background: '#FFFFFF', border: '1px solid'}}>
                                    <div className="alert card-color-texto2 mb-0 p-0">
                                        <div className="row" style={{marginLeft: '10px', marginTop: '15px'}}>
                                            100%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default ColorPalette;
