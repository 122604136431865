import React from "react";

class Faq extends React.Component {
  
  render() {
    const imgStyle = {
      backgroundImage: "url(https://s3media.clubpremier.com/clubpremierstyleguide/corporativoinfo/assets/photo.png)",
    }
      return (
        <div className="container-fluid container-corporativo faq-view">
          <div className="row carousel-descubre m-0 wow fadeIn">
            <div id="carouselSlide0" className="carousel slide  w-100 mb-0" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 bg-divCP mb-2 p-0">
                        <article>
                          <h1 className="">Preguntas Frecuentes</h1>
                          <p className="h1">Le invitamos a conocer información útil sobre el programa Aeroméxico Rewards Business.</p>
                        </article>
                      </div>
                      <div style={imgStyle} className="col-6 d-none d-md-block p-0 col-img "></div>
                    </div>
                  </div>
                </div>
              </div>
              <ol className="carousel-indicators d-none">
                <li data-target="#carouselSlide0" data-slide-to="0" className="active"></li>
              </ol>
            </div>
          </div>

          <div className="accordion-container my-5" id="cpr-stl-faq-acr">
            {/* Accordion #1 */}
            <div className="accordion" id="cpr-stl-faq-acr-1">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse1" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse1"> ¿Los Puntos Aeroméxico Rewards que acumula la Cuenta Corporativa de mi Empresa afectan los Puntos de mi Cuenta Individual? 	 
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse1" className="collapse" aria-labelledby="headingOne1" data-parent="#cpr-stl-faq-acr">
                  <div className="card-body">
                    <p>
                    Recuerda que tanto tu Empresa y tu acumulan Puntos Aeroméxico Rewards al mismo tiempo por tus Viajes de Negocios. Los Puntos Aeroméxico Rewards que se otorgan a las Cuentas Corporativas son adicionales a los Puntos que se acreditan en las Cuentas Aeroméxico Rewards Individuales de los Colaboradores de la Empresa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Accordion #2 */}
            <div className="accordion" id="cpr-stl-faq-acr-2">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse2" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse2"> ¿Qué vigencia tienen los Puntos Aeroméxico Rewards que se acumulan en la Cuenta Corporativa de la Empresa? 	 
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse2" className="collapse" aria-labelledby="headingOne2" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                        <p>
                        Los Puntos Aeroméxico Rewards de la Cuenta Corporativa tienen una vigencia de 24 meses a partir de la fecha de acreditación
                        </p>
                    </div>
                </div>
              </div>
            </div>
            {/* Accordion #3 */}
            <div className="accordion" id="cpr-stl-faq-acr-3">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse3" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse3"> ¿Dónde puedo consultar el saldo de Puntos Aeroméxico Rewards de la Cuenta Corporativa de la Empresa? 	 
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse3" className="collapse" aria-labelledby="headingOne3" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                        <p>Si eres Representante Empresarial o Contacto Autorizado de la Cuenta Corporativa mensualmente recibirás a tu correo registrado el Estado de Cuenta con el balance de Puntos de la Cuenta Corporativa.</p>
                        <p>Adicionalmente, puedes acceder al perfil de la Empresa iniciando sesión en <a target="_blank" href="https://corporativo.clubpremier.com/login">https://corporativo.clubpremier.com/login</a> y consultar la sección de "Estado de Cuenta" para conocer el detalle de las transacciones que se han registrado en la Cuenta Corporativa. 		                        </p>
                    </div>
                </div>
              </div>
            </div>
            {/* Accordion #4 */}
            <div className="accordion" id="cpr-stl-faq-acr-4">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse4" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse4"> ¿Cómo reactivo o recupero mi Cuenta Aeroméxico Rewards Business?
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse4" className="collapse" aria-labelledby="headingOne4" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                        <p>
                        En caso de que el Administrador principal registrado ya no se encuentre en la Empresa, o simplemente no se tengan los datos completos para iniciar sesión favor de enviar un correo a <a target="_blank" href="mailto:programa.corporativo@clubpremier.com">programa.corporativo@clubpremier.com</a> para que puedas recuperar tu Cuenta. 	
                        </p>
                    </div>
                </div>
              </div>
            </div>
            {/* Accordion #5 */}
            <div className="accordion" id="cpr-stl-faq-acr-5">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse5" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse5"> ¿Qué pasa si no ingrese mi Número de Cuenta Corporativa al momento de reservar mi viaje de Negocios?  	 
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse5" className="collapse" aria-labelledby="headingOne5" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                        <p>Si al momento de hacer la reservación no se ingresó el Número de Cuenta Corporativa, es indispensable registrar la Clave de Acumulación de tu Empresa (OSI) antes de realizar el vuelo llamando a nuestro Call Center al (55) 1519 4040, por medio de tu agencia de viajes o enviando un correo a <a target="_blank" href="mailto:programa.corporativo@clubpremier.com">programa.corporativo@clubpremier.com</a> solicitando que agreguen el Número de Cuenta Corporativa a tu reserva.</p>
                        <p>Es importante tener presente que no existe acumulación retroactiva para vuelos en el Programa Corporativo.</p>
                    </div>
                </div>
              </div>
            </div>
            {/* Accordion #6 */}
            <div className="accordion" id="cpr-stl-faq-acr-6">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse6" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse6"> Además de vuelos, ¿cómo puedo acumular Puntos Aeroméxico Rewards a la Cuenta Corporativa? 	 
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse6" className="collapse" aria-labelledby="headingOne6" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                        <p>Tu Empresa también puede acumular Puntos Aeroméxico Rewards al adquirir todos los servicios que nuestro Programa y Empresas afiliadas tienen para ti:</p>
                        <ul>
                          <li>Al pagar con Tu Tarjeta American Express(R) Corporate Aeroméxico.</li>
                          <li>Al reservar tu estancia de negocios en Hoteles en Línea.</li>
                          <li>Hospedándote en las propiedades de Choice Hotels y City Express.</li>
                        </ul>
                        <p>Descubre todas las formas en las que tu Empresa y tu pueden acumular más Puntos Aeroméxico Rewards al complementar tus Experiencias de Negocios dando click en el siguiente link <a target="_blank" hred="https://corporativo.clubpremier.com/mx/acumula?lang=es">https://corporativo.clubpremier.com/mx/acumula?lang=es</a></p>
                    </div>
                </div>
              </div>
            </div>
            {/* Accordion #7 */}
            <div className="accordion" id="cpr-stl-faq-acr-7">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse7" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse7"> ¿En qué se pueden utilizar los Puntos Aeroméxico Rewards de la Cuenta Corporativa?
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse7" className="collapse" aria-labelledby="headingOne7" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                      <p>Para utilizar los Puntos Aeroméxico Rewards de la Cuenta Corporativa debes de ser el Representante Empresarial o Contacto Autorizado de tu Empresa.</p>
                      <p>Hay muchas formas de utilizar los Puntos Aeroméxico Rewards que acumula tu Empresa. Adquiere vuelos, estancias en hoteles, productos de viaje, renta de autos y muchos más.</p>
                      <p>Conoce a nuestras Empresas afiliadas dando click en el siguiente link <a target="_blank" href="https://corporativo.clubpremier.com/mx/utiliza?lang=es">https://corporativo.clubpremier.com/mx/utiliza?lang=es</a></p>
                    </div>
                </div>
              </div>
            </div>
            {/* Accordion #8 */}
            <div className="accordion" id="cpr-stl-faq-acr-8">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse8" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse8"> ¿Qué requisitos deben cumplir los empleados de las Empresas inscritas a Aeroméxico Rewards Business para que puedan recibir el descuento del 50% en la adquisición de Accesos Únicos y Membresías Salón Premier?
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse8" className="collapse" aria-labelledby="headingOne8" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                        <p>Los requisitos para adquirir Accesos Únicos y Membresías Salón Premier son:</p>
                        <ul>
                          <li>La Empresa deberá estar inscrita en Aeroméxico Rewards Business.  	</li>
                          <li>La reservación del Colaborador al que se aplicará el descuento deberá tener ingresada la Clave Única de Acumulación de la Empresa (OSI) al momento de adquirir los Accesos Únicos o Membresías Salón Premier</li>
                        </ul>
                        <p>Recuerda que para adquirir estos beneficios deberás comunicarte a nuestro Call Center llamando al (55) 1519 4040. Conoce más aquí <a target="_blank" href="https://corporativo.clubpremier.com/mx/utiliza/productos-de-vuelo/salones-premier/">https://corporativo.clubpremier.com/mx/utiliza/productos-de-vuelo/salones-premier/</a></p>
                    </div>
                </div>
              </div>
            </div>
            {/* Accordion #9 */}
            <div className="accordion" id="cpr-stl-faq-acr-9">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse9" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse9"> ¿Qué son las Tarjetas de Nivel?
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse9" className="collapse" aria-labelledby="headingOne9" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                        <p>Tu Empresa puede obtener Tarjetas de Nivel Oro y Platino sin costo de acuerdo con la facturación con Aeroméxico para brindarle a los Colaboradores beneficios exclusivos durante sus vuelos de Negocios.</p>
                    </div>
                </div>
              </div>
            </div>
            {/* Accordion #10 */}
            <div className="accordion" id="cpr-stl-faq-acr-10">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse10" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse10"> ¿Qué beneficios otorgan las Tarjetas de Nivel? 	 
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse10" className="collapse" aria-labelledby="headingOne10" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                        <p>Algunos de los beneficios que brindan nuestras Tarjeta de Nivel son:</p>
                        <ul>
                          <li>Ascenso a Clase Premier.</li>
                          <li>Selección y asignación de asientos sin costo adicional.</li>
                          <li>Acceso a Salones Premier* para esperar tu vuelo.</li>
                          <li>Prioridad al hacer tu check-in antes del vuelo y abordaje prioritario.</li>
                          <li>Documenta en mostradores preferenciales SkyPriority. 	</li>
                          <li>1 maleta extra documentada sin costo.</li>
                        </ul>
                        <p>Consulta más información en: <a target="_blank" href="https://corporativo.clubpremier.com/mx/corporativo/niveles?lang=es">https://corporativo.clubpremier.com/mx/corporativo/niveles?lang=es</a></p>
                    </div>
                </div>
              </div>
            </div>
            {/* Accordion #11 */}
            <div className="accordion" id="cpr-stl-faq-acr-11">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse11" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse11"> ¿Cómo se pueden asignar las Tarjetas de Nivel de la Cuenta Corporativa?
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse11" className="collapse" aria-labelledby="headingOne11" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                      <p>Para asignar las Tarjetas de Nivel de la Cuenta Corporativa debes de ser el Representante Empresarial.</p>
                        <p>Ingresa al perfil de tu Empresa iniciando sesión en <a target="_blank" href="https://corporativo.clubpremier.com/login">https://corporativo.clubpremier.com/login</a></p>
                        <p>Dirigirte a la sección de "Tarjetas y Membresías" y asignar las Tarjetas de Nivel disponibles a tus Colaboradores que estén inscritos al Programa Individual.</p>
                        <p>Recuerda que el Nivel Tier quedará vinculado con el Número de Cuenta Individual de tu Colaborador.</p>
                    </div>
                </div>
              </div>
            </div>
            {/* Accordion #12 */}
            <div className="accordion" id="cpr-stl-faq-acr-12">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse12" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse12"> Cómo saber si los Colaboradores de la Empresa ya tienen una Tarjeta de Nivel asignada a su Cuenta Individual?
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse12" className="collapse" aria-labelledby="headingOne12" data-parent="#cpr-stl-faq-acr">
                  <div className="card-body">
                    <p>El Representante Empresarial podrá consultar las Tarjetas de Nivel asignadas desde el Perfil de la Cuenta Corporativa al iniciar sesión en <a target="_blank" href="https://corporativo.clubpremier.com/login">https://corporativo.clubpremier.com/login</a></p>
                    <p>Adicional, sugerimos solicitar a los Colaboradores que descarguen la App de Aeroméxico Rewards e iniciar sesión con su Cuenta Individual. En el APP podrán:</p>
                    <ul>
                      <li>Conocer su Nivel personal Aeroméxico Rewards Aeroméxico o el otorgado por su Cuenta Corporativa.</li>
                      <li>Ver y utilizar su Tarjeta virtual para acumular Puntos Aeroméxico Rewards, ingresar a Salones Premier y más.</li>
                    </ul>
                    <p>Recuerda que si tu Colaborador tiene un Nivel Tier superior al asignado por la Empresa este conservará el Nivel con mayores beneficios.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Accordion #13 */}
            <div className="accordion" id="cpr-stl-faq-acr-13">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse13" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse13">¿Cómo puedo registrar una factura para acumular con hoteles y arrendadoras afiliadas?
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse13" className="collapse" aria-labelledby="headingOne13" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                        <p>Para acumular Puntos Aeroméxico Rewards con hoteles y empresas de alquiler de autos en la Cuenta Corporativa, es necesario ingresar la factura correspondiente al servicio contratado con ellos siguiendo los pasos a continuación: 	</p>
                        <ol>
                          <li> Contratar el servicio de estancias de hotel o arrendadoras afiliadas al Programa Corporativo. 	</li>
                          <li>Al concluir la estancia o renta de auto, solicitar la factura correspondiente a tu servicio.</li>
                          <li>Ingresar al perfil de tu Empresa iniciando sesión en <a target="_blank" href="https://corporativo.clubpremier.com/login">https://corporativo.clubpremier.com/login </a> </li>
                          <li>Dirigirse a la sección de Acumulaciones Retroactivas y registra la factura ingresando el número de Cuenta Corporativa de tu Empresa para que se acrediten los Puntos Aeroméxico Rewards. 		</li>
                          <li>Una vez que finalice el proceso de validación, los Puntos Aeroméxico Rewards se verán reflejados en la Cuenta Corporativa de la Empresa dentro de un período de 14 días hábiles después de la fecha de registro de la factura. 	</li>
                        </ol>
                        <p>Recuerda que para ingresar la factura a la Cuenta Corporativa debes de ser el Representante Empresarial o Contacto Autorizado de tu Empresa.</p>
                        <p>Es importante tener presente que no existe acumulación retroactiva para vuelos en el Programa Corporativo.</p>
                    </div>
                </div>
              </div>
            </div>
            {/* Accordion #14 */}
            <div className="accordion" id="cpr-stl-faq-acr-14">
              <div className="card card-collapseSecondary">
                <h6 className="mb-0 card-header collapsed" data-toggle="collapse" data-target="#cpr-stl-faq-faqCollapse14" aria-expanded="false" aria-controls="cpr-stl-faq-faqCollapse14"> ¿Dónde pueden atender mis dudas y comentarios acerca de Aeroméxico Rewards Business?
                  <i className="fas fa-lg fa-angle-down ml-auto"></i>
                </h6>
                <div id="cpr-stl-faq-faqCollapse14" className="collapse" aria-labelledby="headingOne14" data-parent="#cpr-stl-faq-acr">
                    <div className="card-body">
                        <p>Vía e-mail a: <a target="_blank" href="mailto:programa.corporativo@clubpremier.com">programa.corporativo@clubpremier.com</a></p>
                        <p>En el Chat: <a target="_blank" href="https://www.clubpremiercorporativo.com">www.clubpremiercorporativo.com</a></p>
                        <p>En el Centro de Atención Telefónica Aeroméxico Rewards Business, a los números de teléfono:</p>
                        <ul>
                          <li>En la Ciudad de México: (55) 1519 4040</li>
                          <li>En un horario de atención de lunes a viernes de 09:00 a 19:00 horas. (Horario de la Ciudad de México).</li>
                        </ul>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}
export default Faq;
