import React from 'react';
import SideBar from './sidebar';

class Upgrades extends React.Component {

  render() {
    return (    
      <div className="bg-alabaster">
        <div className="row upgrades -dashboard">
          <div className="urlInputs d-none">
					  <input type="hidden" id="dashboardUrl" value="http://localhost:3030/api/v0/dashboardUrl"/>
					  <input type="hidden" id="getReservationInfoUrl" value="https://ezmock.herokuapp.com/api/6092f84be0585d0015db27cd"/>
          </div>
          <div className="templates d-none">
            {/* Loader Template */}
            <div className="loader -template d-flex justify-content-center align-items-center d-none">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
            {/* .dashboard-card template */}
            <div className="dashboard-card -template d-none">
                <div className="image"><img src=""/></div>
                <h4 className="title"></h4>
                <p className="text mb-40"></p>
                <a href="#" className="button btn btn-secondary"><i className="fas fa-arrow-right"></i>
                </a>
              </div>
            {/* .button-dashboard template */}
            <div className="nav dashboard-button -template d-none">
              <button className="btn btn-outline-primary mb-0" data-target="#carousel-upgrades" data-slide-to="0">
                <span></span>
              </button>
            </div>
          </div>
					<SideBar />
          <div className="upgrades-container col-lg-9 p-0">
            
            <div className="group-steps">
              <div id="step0" className="step0">
                {/* Dashboard - Header */}
                <div className="mt-30"> 
                  <h2 className="d-inline">
                  <i className="fas fa-arrow-left icon-dashboard d-lg-none"></i>
                    Venta de Ancillaries
                  </h2>
                  <p className="mt-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
                
                {/* Dashboard - Grid Desktop */}
                <div id="dashboard-grid-desktop" className="dashboard-grid -desktop d-none d-md-block mt-40 has-loader">
                  <div className="loader -template d-flex justify-content-center align-items-center">
                    <i className="fa fa-spinner fa-spin"></i>
                  </div>
                  <div className="grid-container"></div>
                </div>
                {/* Dashboard - Grid Mobile */}
                <div id="dashboard-grid-mobile" className="dashboard-grid -mobile d-md-none">
                  <div className="loader -template d-flex justify-content-center align-items-center">
                    <i className="fa fa-spinner fa-spin"></i>
                  </div>
                  <div>
                    {/* buttons on top of carousel */}
                    <div className="grid-controls d-flex"></div>


                    {/* actual carousel */}
                    <div id="carousel-upgrades" className="carousel slide" data-ride="carousel" data-interval="false">
                      <div className="carousel-inner"></div>                        

                      <a id="cpr-dup-dbm-lnk-pre" className="carousel-control-prev" href="#carousel-upgrades" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a id="cpr-dup-dbm-lnk-nex" className="carousel-control-next" href="#carousel-upgrades" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              {/* Ends Step0 */}
              </div>

            {/* End Group steps */}
            </div>


            {/* Terms and conditions */}
            <div className="terms py-60">
              <div className="d-flex content_collapse justify-content-center">
                <div className="col-11 col-sm-8 p-0">
                  <div className="accordion" id="accordion-tc30">
                    <div className="card card-collapsePrimary  ">
                      <div>
                        <h4 id="cpr-dup-trm-lnk" className="mb-0 card-header" data-toggle="collapse" data-target="#collapse30" aria-expanded="false" aria-controls="collapseOne">
                          <p>Consulta aquí términos y condiciones</p>
                          <i className="fas fa-lg fa-angle-down ml-auto"></i>
                        </h4>
                      </div>
                      <div id="collapse30" className="collapse" aria-labelledby="headingOne" data-parent="#accordion-tc30">
                        <hr className="dividerCollapse"/>
                        <div className="card-body">

                          <p><b>Adquisición de Ascensos a Clase Premier</b></p>
                          <p><b>Términos y condiciones</b></p>
                          <p>Usted declara reconocer y aceptar los presentes “Términos y Condiciones de adquisición de Ascensos a Clase Premier”.</p>
                          <p><b>Uso</b></p>
                          <p>Los Socios Aeroméxico Rewards (los “Socios” o el “Socio”) podrán adquirir un Ascenso del servicio de Clase Turista comprada originalmente para viajar con Aeroméxico o Aeroméxico Connect, a la Clase Premier ("Ascenso") con Puntos Aeroméxico Rewards conforme a lo establecido en los presentes Términos y Condiciones.</p>
                          <p>Para poder adquirir el ascenso con Puntos Aeroméxico Rewards se deberá:</p>
                          <ul>
                            <li>Ser Socio Aeroméxico Rewards de no serlo se puede inscribir gratis en <a href="https://member.clubpremier.com/registro">https://member.clubpremier.com/registro</a></li>
                            <li>Haber adquirido un vuelo con Aeroméxico o Aeroméxico Connect en Clase Turista (Tarifas participantes) con dinero o con Puntos Aeroméxico Rewards (Boleto Premio)</li>
                            <li>Asociar tu correo electrónico a tu Cuenta Aeroméxico Rewards</li>
                            <li>Adquirir el Ascenso redimiendo los Puntos Aeroméxico Rewards en <a href="https://member.clubpremier.com/ascensos">https://member.clubpremier.com/ascensos</a></li>
                            <li>Conservar copia de los boletos de avión para posibles aclaraciones.</li>
                          </ul>
                          <p><b>Vigencia</b></p>
                          <p>La vigencia de los presentes Términos y Condiciones es indefinida. Aeroméxico Rewards tiene la facultad de cancelar la presente promoción en cualquier momento y sin previo aviso.</p>
                          <p><b>Restricciones</b></p>
                          <ul>
                            <li>El Ascenso no aplicará a vuelos de código compartido o para vuelos operados por una aerolínea diferente de Aeroméxico.</li>
                            <li>El ascenso deberá adquirirse con Puntos Aeroméxico Rewards hasta 4 (cuatro) horas antes del vuelo y siempre y cuando haya disponibilidad en Cabina Premier para el vuelo.</li>
                          </ul>
                          <ul>
                            <li>El Ascenso es sobre clase F sujeto a disponibilidad.</li>
                            <li>Para los siguientes boletos no aplica la redención del Ascenso: 
                              <ul> a. Boletos en clases de servicio P, G, X. <br />b. Recompensa por afectación. <br />c. Boletos aéreos gratuitos (donaciones, sin cargo y otros). <br />d. Boletos de prestación para empleados, Pases o Unitickets. <br /> </ul>
                            </li>
                            <li>Si adquieres un Ascenso a la Clase Premier, tendrás el derecho a usar los siguientes servicios y beneficios: <ul> a. Mayor espacio y comodidad en su asiento. <br />b. Documentación en los mostradores SkyPriority. <br />c. Prioridad en ascenso y descenso del vuelo. <br />d. Prioridad en el manejo del equipaje. <br />e. Franquicia de equipaje de Clase Premier. <br />f. Alimentos de mayor calidad (no aplica en todas las rutas). <br /> </ul>
                            </li>
                            <li>Si adquieres un Ascenso, las condiciones del boleto aéreo original seguirán teniendo vigencia, con excepción de la franquicia permitida de equipaje. Esto incluye condiciones de: estadías mínimas y máximas, cancelación, penalidades y cambios.</li>
                            <li>En caso de que aplique acumulación de Puntos Aeroméxico Rewards, los Puntos Aeroméxico Rewards se acumularán conforme a la clase original y no conforme a la clase a la que se ascendió, sujeto a los Términos y Condiciones de dicho programa.</li>
                            <li>Al adquirir un Ascenso, Aeroméxico no puede garantizar un asiento específico en la Clase Premier. Su número de asiento dependerá de la disponibilidad de espacio.</li>
                            <li>Una vez realizada la redención de Puntos Aeroméxico Rewards por el Ascenso estos son: no reembolsables, no transferible y no endosable.</li>
                            <li>En caso de que Aeroméxico cancele su vuelo, se hará todo lo posible por brindarle espacio en Clase Premier en el vuelo en que se le dé protección para la misma ruta (sujeto a disponibilidad). Si no es posible ofrecerte espacio en la Clase Premier, el pasajero viajará en la clase comprada originalmente y el Ascenso será reembolsable comunicándose al call center de Aeroméxico Rewards.</li>
                            <li>Si el pasajero cambia, cancela o pierde su vuelo por causas ajenas a la aerolínea, o por caso fortuito o fuerza mayor, no habrá reembolso por el cobro de su Ascenso, aun cuando su boleto aéreo original sea reembolsable. Este Ascenso no se puede transferir a otro vuelo u otro pasajero.</li>
                            <li>No aplica el acceso a Salón Premier.</li>
                            <li>Si hay varios pasajeros en tu reservación, deberás adquirir el Ascenso para todos los pasajeros en la reservación por segmento.</li>
                          </ul>
                          <p><b>Fraudes</b></p>
                          <p>Aeroméxico Rewards se reserva el derecho a llevar a cabo acciones que considere pertinentes en caso de detectar fraudes, conductas fraudulentas o malas prácticas por parte de los Socios en las Cuentas Aeroméxico Rewards.</p>
                          <p><b>Términos y Condiciones Generales</b></p>
                          <p>Los Puntos Aeroméxico Rewards, estarán sujetos a las reglas del Programa Aeroméxico Rewards, mismos que se encuentran disponibles en “La Página”.</p>
                          <p>Aeroméxico Rewards se reserva el derecho de modificar los Planes, el monto, así como los Beneficios en cualquier momento, sin previo aviso.</p>
                          <p>La inscripción a Aeroméxico Rewards no supone garantía de disponibilidad de ningún premio del Programa Aeroméxico Rewards, no siendo responsable Aeroméxico Rewards de que el Socio pueda o no utilizar, canjear o redimir los Puntos Aeroméxico Rewards acumulados por la inscripción a Aeroméxico Rewards.</p>
                          <p>En caso de que cualquier inscripción a Aeroméxico Rewards sea utilizada por los Socios para fines impropios, u obtenida de forma indebida de una Tarjeta Bancaria, fraude o cualquier otro medio ilegal, Aeroméxico Rewards podrá, según su criterio, cancelar, anular o denegar la entrega y/o confiscar tales, así como perseguir todos y cada uno de los derechos y recursos que pueda haber disponibles.</p>
                          <p>El Socio acuerda cooperar con todas las solicitudes razonables de Aeroméxico Rewards encaminadas a la investigación o interposición de acciones judiciales contra cualquier implicado o sospechoso de estarlo en la utilización indebida o fraude con respecto a la inscripción al Programa Aeroméxico Rewards, así como a conductas fraudulentas o malas prácticas por parte de los Socios en las Cuentas Aeroméxico Rewards.</p>
                          <p>El Socio acepta que el premio y utilización de los Puntos Aeroméxico Rewards, están sujetos a los términos, condiciones, exclusiones y limitaciones del Programa Aeroméxico Rewards.</p>
                          <p>Los Puntos Aeroméxico Rewards referidos en el Programa Aeroméxico Rewards no son Puntos físicos reales sino representación de créditos en el Programa Aeroméxico Rewards. Dichos Puntos no son equivalentes ni convertibles a moneda corriente en efectivo.</p>
                          <p><b>Datos Personales</b></p>
                          <p>Todo tratamiento de datos personales realizado por Aeroméxico Rewards, así como el ejercicio de los Derechos ARCO por parte de los Socios, será efectuado conforme a la Ley y Reglamento aplicable en los Estados Unidos Mexicanos, así como en sujeción a lo establecido en el Aviso de Privacidad de Aeroméxico Rewards, el cual usted aceptó previo a su registro en Aeroméxico Rewards (para consulta del Aviso de Privacidad por favor ingresar a <a href="http://www.clubpremier.com">www.clubpremier.com</a>)</p>
                          <p>Atendiendo a lo señalado en la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita y derivado de ciertas actividades realizadas por los Socios, las cuales sean consideradas por la Ley como actividades vulnerables, Aeroméxico Rewards compartirá sus Datos Personales con las autoridades correspondientes.</p>
                          <p><b>Lavado de Dinero</b></p>
                          <p>Al aceptar y participar en la presente, usted otorga expresamente autorización para que Aeroméxico Rewards transfiera sus datos de contacto (nombre, número de socio, número telefónico, dirección de correo electrónico) a Aerovías de México, S.A. de C.V., así como a terceros subcontratados por Aeroméxico Rewards a efecto de otorgar los beneficios de la presente promoción, mismos datos que también podrán ser transferidos a las empresas que para tal efecto determine Aeroméxico Rewards (incluyendo, sin limitar, cadenas hoteleras, aerolíneas, servicios de transportación terrestre y similares).</p>
                          <p>La información que derive de los Avisos que se presenten ante las autoridades competentes será utilizada exclusivamente para la prevención, identificación, investigación y sanción de operaciones con recursos de procedencia ilícita y demás delitos relacionados con éstas. Los datos personales antes mencionados se mantendrán en resguardado absoluto, en los términos de la Constitución Política de los Estados Unidos Mexicanos.</p>
                          <p><b>Jurisdicción</b></p>
                          <p>Los presentes Términos y Condiciones, en relación a su interpretación o cumplimiento, se regirán por la Ley y jurisdicción de los Tribunales competentes de la Ciudad de México, renunciando desde ahora a cualquier otro fuero que, por razón de materia o domicilio, presente o futuro, pudiera corresponderles.</p>
                          <p>En caso de existir alguna duda o aclaración respecto a los presentes términos y condiciones, por favor contáctanos a través Centro de Atención Telefónica de Aeroméxico Rewards (55 1519 4040) o al correo electrónico: <a href="mailto:soporte@clubpremier.com">soporte@clubpremier.com</a></p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* END Terms and conditions */}
            </div>

          {/* End .upgrades-container */}
          </div> 
        {/* End view */}
        </div>
      </div>  
    );
  }
}
export default Upgrades;