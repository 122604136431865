import React from 'react';
import SideBar from './sidebar';

class RetroactiveAccreditations extends React.Component {

  render() {
    return (    
      <div className="bg-alabaster">
        <div className="row pl-15 pr-15 retroactive-accreditations" data-text="ES" data-type-account="AR" data-account="3333333" data-account-text="Cuenta Aeroméxico Rewards" data-text-delete-file="Eliminar" data-textbutton-add="Registrar" data-textbutton-update="Guardar" data-textbutton-remove="Si, Eliminar" data-text-error-general="Porfavor, intenta nuevamente." data-text-error-file="Archivo no permitido o peso superado intentalo de nuevo" data-msg-table-empty="No hay datos registrados" data-text-search-accred="Consultar" data-text-not-record="No hay conicidencias">
					<input className="base-url" type="hidden" id="cpr-rhr-prp-inp-bsu" value="https://9d45a089-ab93-4b4d-a6f7-4a72754754f0.mock.pstmn.io"/>
					<input className="image-error" type="hidden" id="cpr-rhr-prp-inp-ier" value="https://clubpremierstyleguide.s3.amazonaws.com/corporativoinfo/assets/i-sorry.png"/>
					<input className="image-success-add" type="hidden" id="cpr-rhr-prp-inp-isa" value="https://clubpremierstyleguide.s3.amazonaws.com/corporativoinfo/assets/success-register.png"/>
					<SideBar />
          <div className="col-12 col-lg-9 container-body p-0 mb-15">
            <div className="col-12 mt-30"> 
              <h2 className="d-inline">
                <i className="fas fa-arrow-left icon-dashboard"></i>
                Acreditaciones retroactivas
              </h2>
            </div>
            <p className="subtitle p-15">
              Acumula Puntos Aeroméxico Rewards para tu empresa al hospedarte en los hoteles afiliados o al rentar autos.
              Registrar tu factura es muy sencillo, sólo selecciona el hotel donde te hospedaste o la arrendadora
              donde rentaste un auto.
            </p>

            <div className="row">
              <div className="col-12 col-md-12">
                <nav>
                  <div className="nav nav-tabs" id="cpr-rhr-prp-div-nvt" role="tablist">
                    <a className="nav-item nav-link active" id="cpr-rhr-prp-hyp-nht" data-toggle="tab" href="#cpr-rhr-prp-div-nvh" role="tab" aria-controls="cpr-rhr-prp-div-nvh" aria-selected="true">Registrar una factura</a>
                    <a className="nav-item nav-link" id="cpr-rhr-prp-hyp-npt" data-toggle="tab" href="#cpr-rhr-prp-div-nvp" role="tab" aria-controls="cpr-rhr-prp-div-nvp" aria-selected="false">Estatus de mis facturas</a>
                  </div>
                </nav>
                <div className="tab-content" id="cpr-rhr-prp-div-ntc">
                  <div className="tab-pane fade show active px-0" id="cpr-rhr-prp-div-nvh" role="tabpanel" aria-labelledby="cpr-rhr-prp-hyp-nht">
                    <div className="bg-white card-white">
                      <div className="col-12">
                        <h4 className="p-15">Registrar una factura</h4>
                        <div className="alert alert-danger mx-15 d-none" role="alert">
                          <div className="row row-basic">
                              <i className='fas fa-times-circle'/>
                              <h5> Ha ocurrido un error</h5>
                              <i className='fas fa-times'/>
                          </div>
                          <div className="row row-basic">
                              <p className="text-error">Lo sentimos, en este momento existe un error del sistema, por favor intenta más tarde.</p>
                          </div>
                        </div>

                        {/* Formulario de factura */}
                        <form id="cpr-rhr-rin-frm-rin" className="register-factura-form">
                          <h5 className="px-15">Tipo de acreditación</h5>
                          <div className="row">
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-acr">
                              <div className="input-group">
                                <label className="select-label">Tipo de acreditación</label>                         
                                <select
                                  name="accreditationType"
                                  type="text"
                                  className="input-type-accreditation custom-select mb-0"
                                  defaultValue={'DEFAULT'}
                                  required
                                  data-parsley-errors-container="#group-accreditationType"
                                  data-parsley-trigger="input focus"
                                  data-parsley-required-message="Campo requerido"
                                >
                                  <option value="DEFAULT" disabled>Tipo de acreditación</option>
                                  <option value="ACRARR">Acreditación de Arrendadora</option>
                                  <option value="ACRHTL">Acreditación de Hoteles</option>
                                </select>
                                <div className="input-group-append">
                                  <span className="input-group-text inputBasic pl-0">
                                    <i className="fas fa-lg fa-angle-down"/>
                                  </span>
                                </div>                        
                              </div>
                            </div>

                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-cad">
                              <div className="input-group">
                                <label className="select-label">Cadena</label>                         
                                <select
                                  name="cadenaType"
                                  type="text"
                                  className="input-type-cadena custom-select mb-0"
                                  defaultValue={'DEFAULT'}
                                  required
                                  data-parsley-errors-container="#group-cadena"
                                  data-parsley-trigger="input focus"
                                  data-parsley-required-message="Campo requerido"
                                >
                                  <option value="DEFAULT" disabled>Cadena</option>
                                </select>
                                <div className="input-group-append">
                                  <span className="input-group-text inputBasic pl-0">
                                    <i className="fas fa-lg fa-angle-down"/>
                                  </span>
                                </div>                        
                              </div>
                            </div>

                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-mar">
                              <div className="input-group">
                                <label className="select-label">Marca</label>                         
                                <select
                                  name="brandType"
                                  type="text"
                                  className="input-type-brand custom-select mb-0"
                                  defaultValue={'DEFAULT'}
                                  required
                                  data-parsley-errors-container="#group-brand"
                                  data-parsley-trigger="input focus"
                                  data-parsley-required-message="Campo requerido"
                                >
                                  <option value="DEFAULT" disabled>Marca</option>
                                </select>
                                <div className="input-group-append">
                                  <span className="input-group-text inputBasic pl-0">
                                    <i className="fas fa-lg fa-angle-down"/>
                                  </span>
                                </div>                        
                              </div>
                            </div>
                          </div>

                          <h5 className="px-15">Datos de factura</h5>
                          
                          <div className="row">
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-ccp">
                              <div className="input-group" >
                                <label>Cuenta Aeroméxico Rewards</label>
                                <input 
                                  className="form-control input-account"
                                  name="accountNumber"
                                  data-parsley-errors-container="#group-accountNumber"
                                  data-parsley-trigger="input focus"
                                  placeholder="Cuenta Aeroméxico Rewards"
                                  disabled="disabled"
                                  required
                                  data-parsley-required-message="Campo requerido"
                                  minLength="2"
                                  maxLength="11"
                                  data-parsley-length-message="Máximo 11 dígitos"
                                  data-parsley-luhn
                                  data-parsley-luhn-message="No. de cuenta inválido"
                                  data-parsley-type="digits"
                                  data-parsley-type-message="Sólo dígitos"
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text inputBasic">
                                    <i className="fas fa-lock-alt"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-nom">
                              <div className="input-group" >
                                <label>Nombre de la Empresa</label>
                                <input 
                                  className="form-control input-name-company"
                                  name="nameCompany"
                                  data-parsley-errors-container="#group-nameCompany"
                                  data-parsley-trigger="input focus"
                                  placeholder="Nombre de la Empresa"
                                  disabled="disabled"
                                  required
                                  data-parsley-required-message="Campo requerido"
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text inputBasic">
                                    <i className="fas fa-lock-alt"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-dir">
                              <div className="input-group" >
                                <label>Dirección del Hotel o Arrendadora</label>
                                <input 
                                  className="form-control input-address-company"
                                  name="addressCompany"
                                  data-parsley-errors-container="#group-addressCompany"
                                  data-parsley-trigger="input focus"
                                  placeholder="Dirección del Hotel o Arrendadora"
                                  required
                                  data-parsley-required-message="Campo requerido"
                                  data-parsley-pattern="/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/"
                                  data-parsley-pattern-message="No se permiten carateres especiales"
                                />
                                <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Tooltip text">
                                  <span className="input-group-text inputBasic">
                                    <i className="fas fa-question-circle"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-npr">
                              <div className="input-group" >
                                <label>Número de Programa de Lealtad</label>
                                <input 
                                  className="form-control input-number-program"
                                  name="numberProgram"
                                  data-parsley-errors-container="#group-numberProgram"
                                  data-parsley-trigger="input focus"
                                  placeholder="Número de Programa de Lealtad"
                                  required
                                  data-parsley-required-message="Campo requerido"
                                  data-parsley-pattern="/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/"
                                  data-parsley-pattern-message="No se permiten carateres especiales"
                                />
                                <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Tooltip text">
                                  <span className="input-group-text inputBasic">
                                    <i className="fas fa-question-circle"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-fen">
                              <div className="input-group">
                                <label>Fecha de Entrada</label>
                                <input
                                  name="startDay"
                                  className="form-control input-start-day input-month-validate input-validate-two-dates"
                                  data-parsley-errors-container="#group-startDay"
                                  data-parsley-trigger="input focus"
                                  data-month-min= "6"
                                  placeholder="dd/mm/aaaa"
                                  disabled
                                  required
                                  data-parsley-required-message="Campo requerido"
                                  minLength="10"
                                  maxLength="10"
                                  data-date-start=""
                                  data-parsley-length-message="Ingresa la fecha con formato dd/mm/aaaa"
                                  data-parsley-valid-date=""
                                  data-parsley-valid-date-message="No es una fecha válida"
                                  data-parsley-is-future=""
                                  data-parsley-is-future-message="Es una fecha futura"
                                  data-parsley-not-next="6"
                                  data-parsley-not-next-message="La fecha de entrada no debe ser anterior a 6 meses"
                                  data-parsley-not-date=""
                                  data-parsley-not-date-message="La fecha de salida debe ser mayor a la fecha de entrada hotelera"
                                  autoComplete="off"
                                  />
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-fsa">
                              <div className="input-group">
                                <label>Fecha de Salida</label>
                                <input 
                                  name="endDay"
                                  className="form-control input-end-day input-validate-two-dates"
                                  data-parsley-errors-container="#group-endDay"
                                  data-parsley-trigger="input focus"
                                  placeholder="dd/mm/aaaa"
                                  disabled
                                  required
                                  data-parsley-required-message="Campo requerido"
                                  minLength="10"
                                  maxLength="10"
                                  data-date-start=""
                                  data-parsley-length-message="Ingresa la fecha con formato dd/mm/aaaa"
                                  data-parsley-valid-date=""
                                  data-parsley-valid-date-message="No es una fecha válida"
                                  data-parsley-is-future=""
                                  data-parsley-is-future-message="Es una fecha futura"
                                  data-parsley-not-date=""
                                  data-parsley-not-date-message="La fecha de salida debe ser mayor a la fecha de entrada"
                                  autoComplete="off"
                                  />
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-fol">
                              <div className="input-group" >
                                <label>Folio</label>
                                <input 
                                  className="form-control input-folio-number"
                                  name="folioNumber"
                                  data-parsley-errors-container="#group-folioNumber"
                                  data-parsley-trigger="input focus"
                                  maxLength="16"
                                  placeholder="Folio"
                                  required
                                  data-parsley-required-message="Campo requerido"
                                  data-parsley-type="alphanum"
                                  data-parsley-type-message="Sólo números y letras"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-fac">
                              <div className="input-group" >
                                <label>Monto de Factura</label>
                                <input 
                                  className="form-control input-amount-factura"
                                  name="amountFactura"
                                  data-parsley-errors-container="#group-amountFactura"
                                  data-parsley-trigger="input focus"
                                  placeholder="Monto de Factura"
                                  required
                                  data-parsley-required-message="Campo requerido"
                                  data-parsley-pattern="^[0-9]\d*(\.\d+)?$"
                                  data-parsley-pattern-message="Sólo números (Positivos, enteros y decimales)"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-mon">
                              <div className="input-group">
                                <label className="select-label">Moneda</label>                         
                                <select
                                  name="moneyType"
                                  type="text"
                                  className="input-type-money custom-select"
                                  defaultValue={'DEFAULT'}
                                  required
                                  data-parsley-errors-container="#group-moneyType"
                                  data-parsley-trigger="input focus"
                                  data-parsley-required-message="Campo requerido"
                                >
                                  <option value="DEFAULT" disabled>Moneda</option>
                                  <option value="MXN">MXN</option>
                                  <option value="USD">USD</option>
                                  <option value="EUR">EUR</option>
                                </select>
                                <div className="input-group-append">
                                  <span className="input-group-text inputBasic pl-0">
                                    <i className="fas fa-lg fa-angle-down"/>
                                  </span>
                                </div>                        
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 text-center" id="cpr-rhr-fin-div-arc">
                              <div className="row uploader-files-container" data-url="#">
                                <div className='upload-dropzone'>
                                  <p>Adjunta una imagen de tu factura  (.pdf, .doc, .jpg)</p>
                                  <input
                                    type="file"
                                    className= "input-file d-none"
                                    accept="image/png, image/jpeg,.pdf, .doc, .docx"
                                    required
                                    data-parsley-required-message=""
                                  />
                                  <button className='btn btn-secondary select-files-accreditation'>Selecciona archivos</button>
                                  <div className='file-requirements'>
                                    <p>Tamaño máximo de archivo: 2 MB</p>
                                  </div>
                                </div>
                                <div className="col-12 text-right">
                                  <small>*Es importante que guardes tu ticket o factura para cualquier aclaración</small>
                                </div>
                                <div className='files-list'>
                                  <ul>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 text-right p-15 buttons-actions">
                              <a className="btn btn-secondary m-1 cancel-form" href="#close" type="button" data-dismiss="modal" aria-label="Close" id="cpr-rhr-fin-hyp-can">
                                Cancelar
                              </a>
                              <button className="btn btn-primary m-1 register-accreditation" disabled="disabled" id="cpr-rhr-fin-hyp-reg">
                                Registrar
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade px-0" id="cpr-rhr-prp-div-nvp" role="tabpanel" aria-labelledby="cpr-rhr-prp-hyp-npt">
                    <div className="bg-white card-white">
                      <div className="col-12">
                        <h4 className="p-15">Estatus de mis facturas</h4>
                      </div>
                      <div className="col-12">
                        <form id="cpr-rhr-fin-frm-fin" className="fetch-factura-form">
                          <h5 className="px-15">Tipo de acreditación</h5>
                          <div className="row">
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-cre">
                              <div className="input-group">
                                <label className="select-label">Acreditación</label>                         
                                <select
                                  name="accreditationType"
                                  type="text"
                                  className="input-type-accreditation custom-select mb-0"
                                  defaultValue={'DEFAULT'}
                                  data-parsley-errors-container="#group-accreditationType"
                                  data-parsley-trigger="input focus"
                                  data-parsley-required-message="Campo requerido">
                                  <option value="DEFAULT" disabled>Tipo de acreditación</option>
                                  <option value="ACRARR">Acreditación de Arrendadora</option>
                                  <option value="ACRHTL">Acreditación de Hoteles</option>
                                </select>
                                <div className="input-group-append">
                                  <span className="input-group-text inputBasic pl-0">
                                    <i className="fas fa-lg fa-angle-down"/>
                                  </span>
                                </div>                        
                              </div>
                            </div>

                            <div className="col-12 col-md-12 col-lg-3 col-xl-3" id="cpr-rhr-fin-div-fas">
                              <div className="input-group">
                                <label className="select-label">Cadena</label>                         
                                <select
                                  name="cadenaType"
                                  type="text"
                                  className="input-type-cadena custom-select mb-0"
                                  defaultValue={'DEFAULT'}
                                  data-parsley-errors-container="#group-cadena"
                                  data-parsley-trigger="input focus"
                                  data-parsley-required-message="Campo requerido"
                                >
                                  <option value="DEFAULT" disabled>Cadena</option>
                                </select>
                                <div className="input-group-append">
                                  <span className="input-group-text inputBasic pl-0">
                                    <i className="fas fa-lg fa-angle-down"/>
                                  </span>
                                </div>                        
                              </div>
                            </div>

                            <div className="col-12 col-md-12 col-lg-2 col-xl-2" id="cpr-rhr-fin-div-mfa">
                              <div className="input-group">
                                <label className="select-label">Marca</label>                         
                                <select
                                  name="brandType"
                                  type="text"
                                  className="input-type-brand custom-select mb-0"
                                  defaultValue={'DEFAULT'}
                                  data-parsley-errors-container="#group-brand"
                                  data-parsley-trigger="input focus"
                                  data-parsley-required-message="Campo requerido"
                                >
                                  <option value="DEFAULT" disabled>Marca</option>
                                </select>
                                <div className="input-group-append">
                                  <span className="input-group-text inputBasic pl-0">
                                    <i className="fas fa-lg fa-angle-down"/>
                                  </span>
                                </div>                        
                              </div>
                            </div>

                            <div className="col-12 col-md-12 col-lg-2 col-xl-2" id="cpr-rhr-fin-div-fde">
                              <div className="input-group">
                                <label>Desde</label>
                                <input
                                  name="startDay"
                                  className="form-control input-start-day-2 input-validate-previus"
                                  data-parsley-errors-container="#group-startDay"
                                  data-parsley-trigger="input focus"
                                  placeholder="dd/mm/aaaa"
                                  required
                                  data-parsley-required-message="Campo requerido"
                                  minLength="10"
                                  maxLength="10"
                                  data-date-end=""
                                  data-parsley-length-message="Ingresa la fecha con formato dd/mm/aaaa"
                                  data-parsley-valid-date=""
                                  data-parsley-valid-date-message="No es una fecha válida"
                                  data-parsley-is-future=""
                                  data-parsley-is-future-message="Es una fecha futura"
                                  data-parsley-not-previus-date=""
                                  data-parsley-not-previus-date-message="La fecha de entrada debe ser menor a la fecha de salida"
                                  autoComplete="off"
                                  />
                              </div>
                            </div>

                            <div className="col-12 col-md-12 col-lg-2 col-xl-2" id="cpr-rhr-fin-div-fha">
                              <div className="input-group">
                                <label>Hasta</label>
                                <input 
                                  name="endDay"
                                  className="form-control input-end-day-2 input-validate-two-dates-2"
                                  data-parsley-errors-container="#group-endDay"
                                  data-parsley-trigger="input focus"
                                  placeholder="dd/mm/aaaa"
                                  required
                                  data-parsley-required-message="Campo requerido"
                                  minLength="10"
                                  maxLength="10"
                                  data-date-start=""
                                  data-parsley-length-message="Ingresa la fecha con formato dd/mm/aaaa"
                                  data-parsley-valid-date=""
                                  data-parsley-valid-date-message="No es una fecha válida"
                                  data-parsley-is-future=""
                                  data-parsley-is-future-message="Es una fecha futura"
                                  data-parsley-not-date-two=""
                                  data-parsley-not-date-two-message="La fecha de salida debe ser mayor a la fecha de entrada"
                                  autoComplete="off"
                                  />
                              </div>
                            </div>

                            <div className="col-12 text-right py-15">
                              <a className="btn btn-secondary m-1 clear-button-table" href="#clean" type="button" data-dismiss="modal" aria-label="Close" id="cpr-rhr-fin-hyp-lim">
                                Limpiar
                              </a>
                              <button className="btn btn-primary m-1 search-accreditation" disabled="disabled" id="cpr-rhr-fin-btn-con">
                                Consultar
                              </button>
                            </div>

                          </div>
                        </form> 
                      </div>
                    </div>

                    <div className="bg-white card-white my-15 py-15 table-accreditation-display d-none">
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-2 col-xl-2 my-15">
                          <div class="custom-control custom-checkbox">
                            <input id="cpr-rhr-fin-inp-all" class="custom-control-input" type="checkbox" value=""/>
                            <label for="cpr-rhr-fin-inp-all" class="custom-control-label">Ver todas</label>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-2 col-xl-2 my-15">
                          <div class="custom-control custom-checkbox">
                            <input id="cpr-rhr-fin-inp-dda" class="custom-control-input" type="checkbox" value="Dada de alta"/>
                            <label for="cpr-rhr-fin-inp-dda" class="custom-control-label">Dada de alta</label>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-2 col-xl-2 my-15">
                          <div class="custom-control custom-checkbox">
                            <input id="cpr-rhr-fin-inp-vda" class="custom-control-input" type="checkbox" value="Validación del afiliado"/>
                            <label for="cpr-rhr-fin-inp-vda" class="custom-control-label">Validación del afiliado</label>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-2 col-xl-2 my-15">
                          <div class="custom-control custom-checkbox">
                            <input id="cpr-rhr-fin-inp-vpd" class="custom-control-input" type="checkbox" value="Validación posible duplicidad"/>
                            <label for="cpr-rhr-fin-inp-vpd" class="custom-control-label">Validación posible duplicidad</label>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-2 col-xl-2 my-15">
                          <div class="custom-control custom-checkbox">
                            <input id="cpr-rhr-fin-inp-acr" class="custom-control-input" type="checkbox" value="Acreditado"/>
                            <label for="cpr-rhr-fin-inp-acr" class="custom-control-label">Acreditado</label>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-2 col-xl-2 my-15">
                          <div class="custom-control custom-checkbox">
                            <input id="cpr-rhr-fin-inp-rec" class="custom-control-input" type="checkbox" value="Rechazado"/>
                            <label for="cpr-rhr-fin-inp-rec" class="custom-control-label">Rechazado</label>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-12 my-15 tableAcreditationHTML"
                        data-title-1="Fecha de alta"
                        data-title-2="Tipo de Acreditación"
                        data-title-3="Cadena"
                        data-title-4="Marca"
                        data-title-5="Fecha de actividad"
                        data-title-6="Fecha de fin de actividad"
                        data-title-7="Folio de Factura"
                        data-title-8="Monto"
                        data-title-9="Estatus"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* modal messages */}
            <div className="modal info modal-data" id="cpr-rhr-prp-div-mdd" data-keyboard="false" data-backdrop="static" tab-index="-1" role="dialog" aria-labelledby="cpr-rhr-prp-txt-mdt" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title text-center m-auto" id="cpr-rhr-prp-txt-mdt">Vacio</h4>
                    </div>
                    <div className="modal-body text-center">
                      <img src="" alt="i_sorry" className="image-error"/>
                      <p className="text-center text-alternative"></p>
                      <p className="text-center text-alternative-two d-none"></p>
                      <p className="text-center text-alternative-success d-none">
                        Para conocer el estado de tu trámite ingresa 
                        <a href="#/"> aquí. </a>
                        Cualquier duda por favor comunícate a nuestro 
                        <a href="#/"> Centro de Atención Telefónica Aeroméxico Rewards.</a>
                      </p>
                      <button className="btn btn-secondary" type="button" data-dismiss="modal" aria-label="Close" id="cpr-rhr-prp-btn-clo">
                          Aceptar
                      </button>
                      <button className="btn btn-secondary success-add d-none" type="button" id="cpr-rhr-prp-btn-ace">
                          Aceptar
                      </button>
                    </div>
                  </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="accordion" id="cpr-rhr-prp-div-atc">
                  <div className="card card-collapsePrimary">
                    <div>
                      <h6 className="mb-0 card-header" data-toggle="collapse" data-target="#cpr-rhr-prp-div-clo" aria-expanded="false" aria-controls="cpr-rhr-prp-div-clo">
                        Consulta aquí términos y condiciones
                        <i className="fas fa-lg fa-angle-down ml-auto"></i>
                      </h6>
                    </div>
                    <div id="cpr-rhr-prp-div-clo" className="collapse" aria-labelledby="headingOne" data-parent="#cpr-rhr-prp-div-atc">
                      <hr className="dividerCollapse"/>
                      <div className="card-body">
                        <ul>
                          <li class="bullet-basic">
                            * Es importante identificar la siguiente dirección programa.corporativo@clubpremier.com como remitente seguro para evitar que éste sea catalogado como “spam” y dirigido a tu bandeja de eliminados.
                          </li>
                          <li class="bullet-basic">
                            * El Socio deberá conservar copia del contrato de renta o factura del hotel para cualquier aclaración.
                          </li>
                          <li class="bullet-basic">
                            * Para llevar a cabo la acreditación retroactiva El Socio deberá ingresar la información solicitada en la presente página de registro.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>  
    );
  }
}
export default RetroactiveAccreditations;