import React from 'react';
class Main extends React.Component {
  constructor(props) {
    super();
  }
    changePage(idComponent){
        document.getElementById(idComponent).click();
    }
  render() {
    return (
      <div className="style-color" style={{background: '#E5E5E5', paddingBottom: '40px'}}>
        
    <div className="jumbotron mt-0">
        <h1 style={{color: '#fff'}}>Guía de estilos</h1>
    </div>
    <div className="container">
        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Branding</h5>
        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
            <div className="alert alert-view col-12 p-30">
                <div className="row" style={{margin: '0', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "color_palette")} style={{height: 'auto'}}>Paleta de color</button>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "title_text")}>Titulos y texto</button>
                    </div>
                  </div>
                  <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "ilustrations")}>Ilustraciones</button>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "logos")}>Logos</button>
                    </div>
                </div>
            </div>
        </div>
        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Componentes</h5>
        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
            <div className="alert alert-view col-12 p-30">
                <div className='row' style={{margin: '0', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "grid")}>Grid</button>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "buttons")}>Botones</button>
                    </div>
                  </div>
                  <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "forms")}>Forms</button>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "cards")}>Cards</button>
                    </div>
                  </div>
                  <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "alerts_and_tooltips")}>Alerts & tooltips</button>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "modals_carrousel")}>Modals & carrousel</button>
                    </div>
                </div>
                  <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "tables_collapse")}>Tables & collapse</button>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "navbar")}>Header & navbar</button>
                    </div>
                </div>
                  <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "footer")}>Footer</button>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "progress_indicators")}>Progress indicators</button>
                  </div>
                </div>
                  <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "dividers")}>Dividers</button>
                    </div>
                </div>
            </div>
        </div>
        <h5 className="h5-etiqueta" style={{marginBottom: '-40px'}}>Vistas</h5>
        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
            <div className="alert alert-view col-12 p-30">
                <div className='row' style={{margin: '0', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "login_cp")}>Login CP</button>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "enrollment_init")}>Enrollment</button>
                    </div>
                </div>  
                <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "enrollment_form")}>Enrollment Form</button>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "home_member")}>Home Corporativo</button>
                    </div>
                </div>  
                <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <a className='btn btn-info btn-main' href="/header-footer">Header & Footer - default</a>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <button className='btn btn-info btn-main' onClick = {this.changePage.bind(this, "corporate_profile")}>Perfil Corporativo</button>
                    </div>
                </div>  
                <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <a className='btn btn-info btn-main' href="/header-footer-logged">Header & Footer - logged</a>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <a className='btn btn-info btn-main' href="/account_statement">Estado de Cuenta</a>
                    </div>
                </div>      
                <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <a className='btn btn-info btn-main' href="/represent-and-contacts">Representantes y Contactos</a>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <a className='btn btn-info btn-main' href="/cards-and-memberships">Tarjetas y Membresías</a>
                    </div>
                </div>      
                <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <a className='btn btn-info btn-main' href="/retroactive-accreditations">Acreditaciones retroactivas</a>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <a className='btn btn-info btn-main' href="/buy-points">Compra de Puntos Aeroméxico Rewards</a>
                    </div>
                </div>      
                <div className='row' style={{margin: '0', marginTop: '10px', padding: '0'}}>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <a className='btn btn-info btn-main' href="/recovery-points">Recupera tus Puntos Aeroméxico Rewards</a>
                    </div>
                    <div className='col-12 col-sm-6' style={{padding: '0', paddingLeft: '5px', paddingRight: '5px'}}>
                        <a className='btn btn-info btn-main' href="/faq">Preguntas Frecuentes</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    );
  }
}
export default Main;