import React from "react";
class Forms extends React.Component {
  constructor(props) {
    super();
  }
    prueba (e) {
        document.getElementById('fgd').value=e;
    }
  render() {
      return (
        <div className="container-fluid container-p">
            <div className="alert alert-titulo mb-0 col-12">
                <h1>FORMS</h1>
                <div className="alert alert-texto mb-0">
                    <h4>
                    Los forms son elementos que esperan recibir un input por parte del usuario. A continuación se enlistan los atributos de los diferentes forms que usaremos
                    </h4>
                </div>
            </div>
            <div className="alert mb-0">
                <div className="alert alert-subtitulo mb-0">
                    <h3>Checkbox</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                        Un checkbox es un elemento de interacción de la interfaz gráfica del usuario que le permite al mismo seleccionar una o más opciones.
                        </h4>
                        <h5 className="h5-etiqueta">Normal</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-3 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row row-basic">
                                    <div className="custom-control custom-checkbox">
                                        <input id="custom-check-1" className="custom-control-input" type="checkbox"/>
                                        <label htmlFor="custom-check-1" className="custom-control-label">Normal check button</label>
                                    </div>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-9 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="custom-control custom-checkbox"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;input id="custom-check-1" class="custom-control-input" type="checkbox"/&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;label for="custom-check-1" class="custom-control-label"&gt;Normal check button&lt;/label&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>                  
                            </div>                  
                        </div> 
                        <h5 className="h5-etiqueta">Hover</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-3 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row row-basic">
                                    <div className="custom-control hover custom-checkbox hover">
                                        <input id="custom-check-3" className="custom-control-input" type="checkbox"/>
                                        <label htmlFor="custom-check-3" className="custom-control-label">Hover check button</label>
                                    </div>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-9 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="custom-control hover custom-checkbox"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;input id="custom-check-1" class="custom-control-input" type="checkbox"/&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;label for="custom-check-1" class="custom-control-label"&gt;Hover check button&lt;/label&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>                  
                            </div>                  
                        </div> 
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3>Radio button</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                            Un radio button es un elemento que permite al usuario elegir una de un conjunto predefinido de opciones, generalmente son utilizados como una lista de opciones.
                        </h4>
                        <h5 className="h5-etiqueta">Normal</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-3 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row row-basic">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" className="custom-radio-input" id="customRadio" name="example1"/>
                                        <label className="custom-radio-label" htmlFor="customRadio">Normal radio button</label>
                                    </div>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-9 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="custom-control custom-radio"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;input type="radio" class="custom-radio-input" id="customRadio2" name="example2"/&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;label class="custom-radio-label" for="customRadio2"&gt;Normal radio button&lt;/label&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>                  
                            </div>                  
                        </div> 
                        <h5 className="h5-etiqueta">Hover</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-3 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row row-basic">
                                    <div className="custom-control hover custom-radio">
                                        <input type="radio" className="custom-radio-input" id="customRadio2" name="example2"/>
                                        <label className="custom-radio-label" htmlFor="customRadio2">Hover radio button</label>
                                    </div>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-9 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="custom-control hover custom-radio"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;input type="radio" class="custom-radio-input" id="customRadio2" name="example2"/&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;label class="custom-radio-label" for="customRadio2"&gt;Hover radio button&lt;/label&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>                  
                            </div>                  
                        </div> 
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3>Toggle button</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                            Un toggle button es un tipo de botón que tiene la opción de permanecer en dos estados posibles, pulsado o no pulsado. Por esta razón, le permite al usuario cambiar entre dos estados.
                        </h4>
                        <h5 className="h5-etiqueta">Normal</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-3 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row row-basic">
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" className="custom-control-input" id="customSwitches"/>
                                        <label className="custom-control-label" htmlFor="customSwitches">Normal toggle button</label>
                                    </div>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-9 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="custom-control custom-switch"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;input type="checkbox" class="custom-control-input" id="customSwitches"/&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;label class="custom-control-label" for="customSwitches"&gt;Normal toggle button&lt;/label&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>                  
                            </div>                  
                        </div> 
                        <h5 className="h5-etiqueta">Hover</h5>
                        <div className="row p-30-guia">
                            <div className="alert alert-view col-12 col-md-3 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <div className="row row-basic">
                                    <div className="custom-control hover custom-switch">
                                        <input type="checkbox" className="custom-control-input" id="customSwitches1"/>
                                        <label className="custom-control-label" htmlFor="customSwitches1">Hover toggle button</label>
                                    </div>
                                </div>    
                            </div>
                            <div className="alert alert-view-code col-12 col-md-9 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="custom-control hover custom-switch"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;input type="checkbox" class="custom-control-input" id="customSwitches"/&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;label class="custom-control-label" for="customSwitches"&gt;Hover toggle button&lt;/label&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code>                  
                            </div>                  
                        </div> 
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3>Input fields & dropdrown</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                            El uso de formularios permite al usuario ingresar data para uso del aplicativo o configurar diferentes opciones. Los formularios también proveen al usuario de información y son una guía en el uso del aplicativo. Diseñar formularios implica también el proveer al usuario de ayuda y retroalimentación puntual.
                        </h4>
                        <div className="alert alert-subtitulo mb-0 alert-mobile">
                            <h3 style={{color: '#658197'}}>Descripción de estados de un input</h3>
                                <div className="alert alert-texto mb-0 alert-mobile">
                                <h4>
                                    Los inputs o entrada de texto permite al usuario interactuar con la interfaz y “agregar” datos. Este tipo de componentes puede ser usado para entradas de longitud corta o larga a nivel de caracteres.
                                </h4>
                                <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                                    <div className="alert alert-view col-12 p-30-guia">
                                        <div className="row row-basic">
                                            <div className="col-12 col-md-6">
                                                <h4>Normal</h4>
                                                <div className="input-group input-basic">
                                                    <label>Label</label>
                                                    <input className="form-control" id="example-2" placeholder="Input Text" required/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text inputBasic">
                                                            <i className='fas fa-check-circle'/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 row-inputs">
                                                <h4>
                                                    Este es el estado de normal de un input, se muestra de primera instancia previo a que el usuario de clic para editar la información.
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                    <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;div class="input-group input-basic"&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;label&gt;Label&lt;/label&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;input class="form-control" id="example-2" placeholder="Input Text" required/&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;div class="input-group-append"&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="input-group-text inputBasic"&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class="fas fa-question-circle"&gt;&lt;/i&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                            &lt;/div&gt;<br/>
                                        </code>            
                                    </div> 
                                </div> 
                                <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                                    <div className="alert alert-view col-12 p-30-guia">
                                        <div className="row row-basic">
                                            <div className="col-12 col-md-6">
                                                <h4>Activo</h4>
                                                <div className="input-group">
                                                    <label>Label</label>
                                                    <input className="form-control" id="example-2" placeholder="Input Text" required/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 row-inputs">
                                                <h4>
                                                    Al hacer clic en usuario sobre el input, notará un cambio de color en todos los elementos. El ícono desaparecerá hasta validar la información proporcionada. 
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                    <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;div class="input-group"&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;label&gt;Label&lt;/label&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;input class="form-control" id="example-2" placeholder="Input Text" required/&gt;<br/>
                                            &lt;/div&gt;<br/>
                                        </code>            
                                    </div> 
                                </div> 
                                <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                                    <div className="alert alert-view col-12 p-30-guia">
                                        <div className="row row-basic">
                                            <div className="col-12 col-md-6">
                                                <h4>Validación verdadera</h4>
                                                <div className="input-group">
                                                    <label>Label</label>
                                                    <input className="form-control inputTrue" id="example-2" placeholder="Input Text" required/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text inputTrue">
                                                            <i className='fas fa-check-circle'/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 row-inputs">
                                                <h4>
                                                    Si la información solicitada al usuario corresponde a las validaciones del sistema, el borde inferior y el ícono serán de color verde. El ícono que aparecerá será una “palomita”.
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                    <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;div class="input-group"&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;label&gt;Label&lt;/label&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;input class="form-control inputTrue" id="example-2" placeholder="Input Text" required/&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;div class="input-group-append"&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="input-group-text inputTrue"&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class="fas fa-question-circle"&gt;&lt;/i&gt; <br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                            &lt;/div&gt;<br/>
                                        </code> 
                                    </div> 
                                </div> 
                                <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                                    <div className="alert alert-view col-12 p-30-guia">
                                        <div className="row row-basic">
                                            <div className="col-12 col-md-6">
                                                <h4>Validación falsa</h4>
                                                <div className="input-group">
                                                    <label>Label</label>
                                                    <input className="form-control inputFalse" id="example-2" placeholder="Input Text" required/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text inputFalse">
                                                            <i className='fas fa-times-circle'/>
                                                        </span>
                                                    </div>
                                                    <label className="msnError">Error message</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 row-inputs">
                                                <h4>
                                                    Si la información solicitada al usuario no corresponde a las validaciones del sistema, el borde inferior y el ícono serán de color rojo. El ícono que aparecerá será una “tache”.
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                    <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                        <code>
                                            &lt;div class="input-group"&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;label&gt;Label&lt;/label&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;input class="form-control inputFalse" id="example-2" placeholder="Input Text" required/&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;div class="input-group-append"&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span class="input-group-text inputFalse"&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class="fas fa-question-circle"&gt;&lt;/i&gt; <br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/span&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                            &lt;/div&gt;
                                        </code> 
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alert alert-subtitulo mb-0">
                    <h3>Dropdown list</h3>
                    <div className="alert alert-texto mb-0">
                        <h4>
                            Un dropdown list es un elemento de control que le permite al usuario elegir un valor de la lista.
                        </h4>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '30px'}}>
                            <div className="alert alert-view col-12 p-30-guia">
                                <div className="row row-basic">
                                    <div className="form-group form-group-dropdown mb-0">
                                        <div className="input-group-dropdown p-10"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <label>Dropdown Menu</label>
                                            <input type="text" autoComplete="off"  id="fgd" className="form-control border-0 custom-select" placeholder="Select one option"/>
                                            <i className="fas fa-lg fa-angle-down p-0"/>
                                        </div>
                                        <div className="dropdown-menu menu-basic">
                                            <a className="dropdown-item dropdown-item-basic" onClick={this.prueba.bind(this, 'Select one option A')} href="#/">Select one option A</a>
                                            <a className="dropdown-item dropdown-item-basic" onClick={this.prueba.bind(this, 'Select one option B')} href="#/">Select one option B</a>
                                            <a className="dropdown-item dropdown-item-basic" onClick={this.prueba.bind(this, 'Select one option C')} href="#/">Select one option C</a>
                                            <a className="dropdown-item dropdown-item-basic" onClick={this.prueba.bind(this, 'Select one option D')} href="#/">Select one option D</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="alert alert-view-code col-12 mb-0 p-30-guia" style={{display: 'flex', alignItems: 'center'}}>
                                <code>
                                    &lt;div class="form-group form-group-dropdown mb-0"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;div class="input-group-dropdown p-10"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;label&gt;Dropdown Menu&lt;/label&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;input type="text" autocomplete="off" class="form-control border-0 custom-select" placeholder="Select one option"/&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;i class="fas fa-lg fa-angle-down p-0"/&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;div class="dropdown-menu menu-basic"&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a class="dropdown-item dropdown-item-basic" href="#"&gt;TEXT&lt;/a&gt;<br/>
                                    &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br/>
                                    &lt;/div&gt;<br/>
                                </code> 
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default Forms;