import React from 'react';
class Header extends React.Component {
  constructor(props) {
    super();
  }
    changePage(idComponent){
        console.log(idComponent);
        if(idComponent !== ""){
            document.getElementById(idComponent).click();
            }
        
    }
  render() {
      
    return (
        <div>
    <nav className="navbar navbar-dark bg-dark" role="navigation">
        <a className="navbar-brand" href="/" onClick = {this.changePage.bind(this, "main")} style={{color: '#ffffff'}}>Guía de estilos</a>
        <div className="container container-nav">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <li className="dropdown">
                    <p className=" navbar-brand dropdown-toggle" data-toggle="dropdown" role="button">Branding</p>
                    <ul className="dropdown-menu multi-column columns-3 bg-dark" role="menu">
                        <div className="row row-basic">
                            <div className="col-sm-6" style={{paddingLeft: '0'}}>
                                <ul className="multi-column-dropdown">
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "color_palette")}>Paleta de color</a></li>
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "ilustrations")}>Ilustraciones</a></li>
                                </ul>
                            </div>
                            <div className="col-sm-6">
                                <ul className="multi-column-dropdown">
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "title_text")}>Títulos y texto</a></li>
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "logos")}>Logos</a></li>
                                </ul>
                            </div>
                        </div>
                    </ul>
                </li>
                <li className="dropdown">
                    <p className=" navbar-brand dropdown-toggle" data-toggle="dropdown" role="button">Componentes</p>
                    <ul className="dropdown-menu multi-column columns-3 bg-dark" role="menu">
                        <div className="row row-basic">
                            <div className="col-md-4" style={{paddingLeft: '0'}}>
                                <ul className="multi-column-dropdown">
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "forms")}>Forms</a></li>
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "iconos")}>Íconos</a></li>
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "buttons")}>Botones</a></li>
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "modals_carrousel")}>Modals and carrousel</a></li>  
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul className="multi-column-dropdown">
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "progress_indicators")}>Progress indicators</a></li>
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "grid")}>Grid</a></li>
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "alerts_and_tooltips")}>Alerts & tooltips</a></li>
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "cards")}>Cards</a></li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul className="multi-column-dropdown">
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "dividers")}>Dividers</a></li>
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "footer")}>Footer</a></li>
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "navbar")}>Header & navbar</a></li>
                                    <li><a className="dropdown-item guia" href="/" onClick = {this.changePage.bind(this, "tables_collapse")}>Tables & collapse</a></li>
                                </ul>
                            </div>
                        </div>
                    </ul>
                </li>
            </div>
        </div>
    </nav>
</div>

    );
  }
}
export default Header;