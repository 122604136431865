import React, {Component} from 'react';

class HeaderFoterContainerLogged extends Component {
  render() {
    
    const style = {
      height: "800px",
      background: "#ccc"
    };

    return (
      <div>
        <div className="header-wrapper">
          <div className="container header-corporate -logged">
            <div className="header-group logo">
              <div className="cp-corporate-logo">
                <a href="/"><img src="https://firebasestorage.googleapis.com/v0/b/imagenes-de4b6.appspot.com/o/Group.svg?alt=media&token=c3bcdad1-4ee4-4fce-a80f-d6c13bf2c473" alt=""/></a>
              </div>
              <div className="header-partners">
                <a href="/" target="_blank"><img src="https://s3media.clubpremier.info/clubpremierstyleguide/cpstyleguideinfo/images/delta.svg" alt="" /></a>
                <a href="/" target="_blank"><img src="https://s3media.clubpremier.info/clubpremierstyleguide/cpstyleguideinfo/images/airfrance.svg" alt=""/></a>
                <a href="/" target="_blank"><img src="https://s3media.clubpremier.info/clubpremierstyleguide/cpstyleguideinfo/images/klm.svg" alt=""/></a>
              </div>
            </div>
            <div className="header-group logged-in">
              <div className="userinfo">
                <h5>Hello Jael!</h5>
                <p className="small">No. de cuenta: 814925251 | Saldo: 100,000 Puntos Aeroméxico Rewards</p>
              </div>
            </div>
            <div className="header-group login">
              <div><a className="btn-secondary login-link" href="/">Cerrar sesión</a></div>
            </div>
            <div className="header-group language">
              <h6><a href="https://example.org/es">ES</a><span></span><a href="https://example.org/en">EN</a></h6>
            </div>
          </div>
        </div>

        <div className="navbar-menu bg-corporate">
          <nav className="container-fluid">
            <ul className="navbar">
              <li className="item level-one">
                <a className="link h6" href="#/">Descubre</a>
                <ul className="submenu d-none">
                  <li className="item">
                    <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/">
                      <span>Productos de vuelo</span>
                    </a>
                  </li>
                  <li className="item">
                    <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/">
                      <span>Salones premier</span>
                    </a>
                  </li>
                  <li className="item">
                    <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/">
                      <span>Hoteles en Linea</span>
                    </a>
                  </li>
                  <li className="item">
                    <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/">
                      <span>Autos</span>
                    </a>
                  </li>
                  <li className="item">
                    <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/">
                      <span>Actividades</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="item level-one"><a className="link h6" href="#/">Acumula</a></li>
              <li className="item level-one">
                <a className="h6" href="#/">Utiliza</a>
                <ul className="submenu d-none">
                  <li className="item level-two">
                    <a className="h6" href="#/">
                      <span>Sub Menu nivel 2</span>
                    </a>
                    <ul className="subnivel d-none">
                      <li className="item">
                        <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/"><span>Lorem ipsum dolor sit</span></a>
                      </li>
                    </ul>
                  </li>
                  <li className="item level-two">
                    <a className="h6" href="#/">
                      <span>Sub Menu nivel 2</span>
                    </a>
                    <ul className="subnivel d-none">
                      <li className="item">
                        <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/"><span>Lorem ipsum dolor sit</span></a>
                      </li>
                      <li className="item">
                        <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/"><span>Lorem ipsum dolor sit</span></a>
                      </li>
                      <li className="item">
                        <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/"><span>Lorem ipsum dolor sit</span></a>
                      </li>
                    </ul>
                  </li>
                  <li className="item level-two">
                    <a className="h6" href="#/">
                      <span>Sub Menu nivel 2</span>
                    </a>
                    <ul className="subnivel d-none">
                      <li className="item">
                        <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/"><span>Lorem ipsum dolor sit</span></a>
                      </li>
                      <li className="item">
                        <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/"><span>Lorem ipsum dolor sit</span></a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="item level-one"><a className="link h6" href="#/">Promociones</a></li>
              <li className="item level-one">
                <a className="link h6" href="#/">Cuenta Corporativa</a>
                <ul className="submenu d-none">
                  <li className="item">
                    <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/">
                      <span>Contactos Autorizados</span>
                    </a>
                  </li>
                  <li className="item">
                    <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/">
                      <span>Administración de tarjetas</span>
                    </a>
                  </li>
                  <li className="item">
                    <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/">
                      <span>Estado de cuenta</span>
                    </a>
                  </li>
                  <li className="item">
                    <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/">
                      <span>Perfil Corporativo</span>
                    </a>
                  </li>
                  <li className="item">
                    <a className="sub-link h6" href="https://www.clubpremier.com/mx/bienvenido/">
                      <span>Acreditaciones Retroactivas</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>

        <div className="navbar-menu-responsive">
          <div className="main-bar">
            <div>
              <a className="menu-toggle" href="#nav-menu-responsive" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-responsive"><span className="fas fa-bars"></span></a>
            </div>
            <div>
              <a className="btn-secondary-dark" href="https://member.clubpremier.com/login">Cerrar sesión</a>
            </div>
          </div>
          <div className="menu-container collapse" id="nav-menu-responsive">
            <div className="menu-content level-1">
              <div className="menu-item">
                <div className="menu-item-label icon-times">
                  <a className="menu-toggle" href="#nav-menu-responsive" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-responsive"><span className="fas fa-times"></span></a>
                </div>
              </div>
              <div className="menu-item">
                <div className="menu-item-label"><a href="#nav-menu-1" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-1">Descubre</a></div>
                <div className="menu-content level-2 collapse" id="nav-menu-1">
                  <div className="menu-item">
                    <div className="menu-item-label">
                      <a href="#nav-menu-1-1" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-1-1">
                        Aerolíneas
                      </a>
                    </div>
                    <div className="menu-content level-3 collapse" id="nav-menu-1-1">
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/aerolineas/aeromexico/">Aeroméxico</a><a className="menu-item" href="https://www.clubpremier.com/mx/acumula/aerolineas/skyteam/">SkyTeam</a>
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/aerolineas/otras-aerolineas/">Otras aerolíneas</a>
                    </div>
                  </div>
                  <a className="menu-item" href="https://hotelesenlinea.clubpremier.com/c/home/es-MX/?t=1535481498889">
                    Hoteles en línea
                  </a>
                  <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/transferencias/">
                    Transferencia de puntos
                  </a>
                  <div className="menu-item">
                    <div className="menu-item-label">
                      <a href="#nav-menu-1-4" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-1-4">
                        Servicios de viaje
                      </a>
                    </div>
                    <div className="menu-content level-3 collapse" id="nav-menu-1-4">
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/servicios-de-viaje/hoteles/">Cadenas hoteleras</a>
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/servicios-de-viaje/transporte/">Transporte</a>
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/servicios-de-viaje/asistencias/?_ga=2.190470889.391399519.1535381102-1254890378.1533910713">Asistencias de viaje</a>
                    </div>
                  </div>
                  <div className="menu-item">
                    <div className="menu-item-label">
                      <a href="#nav-menu-1-5" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-1-5">
                        Servicios financieros
                      </a>
                    </div>
                    <div className="menu-content level-3 collapse" id="nav-menu-1-5">
                      <a className="menu-item" href="https://www.americanexpressaeromexico.clubpremier.com/clubpremier/?_ga=2.228203487.391399519.1535381102-1254890378.1533910713">Las Tarjetas American Express® Aeroméxico</a>
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/bancos/tarjetas/">Tarjetas de Marca Compartida</a><a className="menu-item" href="https://www.clubpremier.com/mx/acumula/bancos/globo-cambio/">Casas de cambio</a>
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/bancos/afore-xxi-banorte/">Afore</a>
                    </div>
                  </div>
                  <div className="menu-item">
                    <div className="menu-item-label">
                      <a href="#nav-menu-1-6" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-1-6">
                        Entretenimiento
                      </a>
                    </div>
                    <div className="menu-content level-3 collapse" id="nav-menu-1-6"><a className="menu-item" href="https://www.clubpremier.com/mx/acumula/ocesa/">Ocesa</a></div>
                  </div>
                  <div className="menu-item">
                    <div className="menu-item-label">
                      <a href="#nav-menu-1-7" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-1-7">
                        Tiendas y Más
                      </a>
                    </div>
                    <div className="menu-content level-3 collapse" id="nav-menu-1-7">
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/tiendas-y-mas/tiendas/">Tiendas</a>
                      <a className="menu-item" href="https://mall.clubpremier.com/?_ga=2.118117067.391399519.1535381102-1254890378.1533910713">Mall Premier</a>
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/tiendas-y-mas/sports-world/">Club Deportivo</a><a className="menu-item" href="https://www.clubpremier.com/mx/acumula/tiendas-y-mas/gasolineras/">Gasolineras</a>
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/tiendas-y-mas/otros/">Otros</a>
                    </div>
                  </div>
                  <div className="menu-item">
                    <div className="menu-item-label">
                      <a href="#nav-menu-1-8" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-1-8">
                        Compra Puntos Aeroméxico Rewards
                      </a>
                    </div>
                    <div className="menu-content level-3 collapse" id="nav-menu-1-8">
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/compra/multiplica-puntos-premier/">Multiplica Premier</a>
                      <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/compra/adquiere-puntos-premier/">Adquiere Puntos Aeroméxico Rewards</a>
                    </div>
                  </div>
                  <a className="menu-item" href="https://www.clubpremier.com/mx/acumula/recupera-puntos-premier/">
                    Recupera Puntos Aeroméxico Rewards
                  </a>
                </div>
              </div>
              <div className="menu-item">
                <div className="menu-item-label"><a href="#nav-menu-2" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-2">Acumula</a></div>
                <div className="menu-content level-2 collapse" id="nav-menu-2">
                  <div className="menu-item">
                    <div className="menu-item-label">
                      <a href="#nav-menu-2-1" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-2-1">
                        Adquiere tu viaje
                      </a>
                    </div>
                    <div className="menu-content level-3 collapse" id="nav-menu-2-1">
                      <a className="menu-item" href="https://vuelaconpuntos.clubpremier.com/?_ga=2.79966293.391399519.1535381102-1254890378.1533910713">Vuelos</a>
                      <a className="menu-item" href="https://www.clubpremier.com/mx/utiliza/productos-de-vuelo/">Productos de Vuelo</a>
                      <a className="menu-item" href="https://reservations.clubpremier.com/travel/arc.cfm?nav=nonairES&amp;tab=c&amp;_ga=2.79966293.391399519.1535381102-1254890378.1533910713">Autos</a>
                      <a className="menu-item" href="https://reservations.clubpremier.com/travel/arc.cfm?nav=activityES&amp;tab=act&amp;_ga=2.79966293.391399519.1535381102-1254890378.1533910713">Actividades</a>
                      <a className="menu-item" href="https://reservations.clubpremier.com/profiles/itineraries.cfm?_ga=2.79966293.391399519.1535381102-1254890378.1533910713">Mis Reservaciones</a>
                    </div>
                  </div>
                  <a className="menu-item" href="https://hotelesenlinea.clubpremier.com/c/home/es-MX/?mode=burn&amp;t=1535482624622">
                    Hoteles en Línea
                  </a>
                  <a className="menu-item" href="https://tienda.clubpremier.com/?_ga=2.179902054.391399519.1535381102-1254890378.1533910713">
                    Tienda en Línea
                  </a>
                  <div className="menu-item">
                    <div className="menu-item-label">
                      <a href="#nav-menu-2-4" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-2-4">
                        Entretenimiento
                      </a>
                    </div>
                    <div className="menu-content level-3 collapse" id="nav-menu-2-4">
                      <a className="menu-item" href="https://www.clubpremier.com/mx/utiliza/cinepolis/">Cine</a><a className="menu-item" href="https://www.clubpremier.com/mx/utiliza/ocesa/">Ocesa</a>
                    </div>
                  </div>
                  <a className="menu-item" href="https://www.clubpremier.com/mx/utiliza/tiendasymas/?_ga=2.155327481.391399519.1535381102-1254890378.1533910713">
                    Tiendas y Más
                  </a>
                  <a className="menu-item" href="https://subastas.clubpremier.com/?_ga=2.155327481.391399519.1535381102-1254890378.1533910713">
                    Subastas Premier
                  </a>
                  <a className="menu-item" href="https://experiencias.clubpremier.com/?_ga=2.83629908.391399519.1535381102-1254890378.1533910713">
                    Experiencias Premier
                  </a>
                  <a className="menu-item" href="https://www.clubpremier.com/mx/utiliza/comparte-puntos-premier/">
                    Comparte Premier
                  </a>
                </div>
              </div>
              <div className="menu-item">
                <div className="menu-item-label"><a href="#nav-menu-3" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-3">Utiliza</a></div>
                <div className="menu-content level-2 collapse" id="nav-menu-3">
                  <a className="menu-item" href="https://www.clubpremier.com/mx/promociones/aerolineas/">
                    Aerolíneas
                  </a>
                  <div className="menu-item">
                    <div className="menu-item-label">
                      <a href="#nav-menu-3-2" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-3-2">
                        Servicios de Viaje
                      </a>
                    </div>
                    <div className="menu-content level-3 collapse" id="nav-menu-3-2">
                      <a className="menu-item" href="https://www.clubpremier.com/mx/promociones/hoteles/">Cadenas Hoteleras</a><a className="menu-item" href="https://www.clubpremier.com/mx/promociones/transporte/">Transporte</a>
                    </div>
                  </div>
                  <a className="menu-item" href="https://www.clubpremier.com/mx/promociones/instituciones-financieras/">
                    Bancos
                  </a>
                  <a className="menu-item" href="https://www.clubpremier.com/mx/promociones/otras-promociones/">
                    Tiendas y Más
                  </a>
                  <a className="menu-item" href="https://www.clubpremier.com/mx/invita-a-un-amigo/">Invita a un amigo</a><a className="menu-item" href="https://member.clubpremier.com/promociones">Mis Promociones</a>
                </div>
              </div>
              <div className="menu-item">
                <div className="menu-item-label"><a href="#nav-menu-4" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-4">Promociones</a></div>
                <div className="menu-content level-2 collapse" id="nav-menu-4">
                  <a className="menu-item" href="https://www.clubpremier.com/mx/conocenos/descubre/">Descubre Aeroméxico Rewards</a>
                  <div className="menu-item">
                    <div className="menu-item-label"><a href="#nav-menu-4-2" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-4-2">Tiendas Aeroméxico Rewards</a></div>
                    <div className="menu-content level-3 collapse" id="nav-menu-4-2">
                      <a className="menu-item" href="https://www.clubpremier.com/mx/conocenos/tiendas/perisur/">Perisur</a><a className="menu-item" href="https://www.clubpremier.com/mx/conocenos/tiendas/santa-fe/">Santa Fe</a>
                    </div>
                  </div>
                  <a className="menu-item" href="https://www.clubpremier.com/mx/conocenos/boletos-ascensos/">Boletos Premio y Ascensos</a><a className="menu-item" href="https://www.clubpremier.com/mx/conocenos/niveles/">Niveles</a>
                  <a className="menu-item" href="https://www.clubpremier.com/mx/conocenos/programas/familiar/">Grupo Familiar</a><a className="menu-item" href="https://www.clubpremier.com/mx/conocenos/comunicados/">Comunicados</a>
                </div>
              </div>
              <div className="menu-item">
                <div className="menu-item-label"><a href="#nav-menu-5" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-5">Mi Cuenta Corporativa</a></div>
                <div className="menu-content level-2 collapse" id="nav-menu-5">
                  <a className="menu-item" href="https://member.clubpremier.com/individual/movimientos-por-fecha?_ga=2.150213620.391399519.1535381102-1254890378.1533910713">Estado de Cuenta</a>
                  <a className="menu-item" href="https://member.clubpremier.com/individual/mis-datos">Mi Perfil</a><a className="menu-item" href="https://member.clubpremier.com/recuperar-nip">Recuperar NIP y Contraseña</a>
                  <a className="menu-item" href="https://member.clubpremier.com/individual/reposicion-tarjeta">Reposición de Tarjetas</a>
                  <div className="menu-item">
                    <div className="menu-item-label"><a href="#nav-menu-5-5" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-5-5">Acumulaciones Retroactivas</a></div>
                    <div className="menu-content level-3 collapse" id="nav-menu-5-5">
                      <a className="menu-item" href="https://member.clubpremier.com/individual/acreditacion-retroactiva-aeromexico">Aeroméxico</a>
                      <a className="menu-item" href="https://member.clubpremier.com/individual/acreditacion-retroactiva-otras-aerolineas">SkyTeam y Otras Aerolíneas</a>
                      <a className="menu-item" href="https://member.clubpremier.com/htlArrRegister/register">Hoteles y Arrendadoras</a><a className="menu-item" href="https://member.clubpremier.com/acreditacion-retroactiva-tiendas">Tiendas y Más</a>
                    </div>
                  </div>
                  <div className="menu-item">
                    <div className="menu-item-label"><a href="#nav-menu-5-6" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="nav-menu-5-6">Grupo Familiar</a></div>
                    <div className="menu-content level-3 collapse" id="nav-menu-5-6">
                      <a className="menu-item" href="https://member.clubpremier.com/individual/programa-familiar">Mi Grupo</a><a className="menu-item" href="https://member.clubpremier.com/individual/transferir-puntos">Transfiere Puntos Aeroméxico Rewards</a>
                    </div>
                  </div>
                  <a className="menu-item" href="https://member.clubpremier.com/beneficiosSantander">Beneficios Santander</a><a className="menu-item" href="https://member.clubpremier.com/multiplica">Multiplica Premier</a>
                  <a className="menu-item" href="https://member.clubpremier.com/invita-a-un-amigo">Invita a un amigo</a>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="container-fluid text-center py-30" style={style}>
          <h2>Contenido muestra</h2>
          <p>(Altura artificial para demostrar scroll)</p>
        </div>


        <div className="container-fluid footer-wrapper">
          <div className="row">
              <div className="container site-footer">
                  <div className="row">
                      <div className="col-12 col-lg-3 contact-info">
                          <h6 id="footer-contact-info">Atención al cliente</h6>
                          <div className="row">
                              <div className="col-6 col-md-12 col-lg-7 col-xl-6"><p>República Mexicana</p></div>
                              <div className="col-6 col-md-12 col-lg-5 col-xl-6 text-right pl-0"><a href="tel:525515194040">(52 55) 1519 4040</a></div>
                          </div>
                          <div className="row">
                              <div className="col-5 col-md-12 col-lg-7 col-xl-6"><p>Estados Unidos</p></div>
                              <div className="col-7 col-md-12 col-lg-5 col-xl-6 text-right"><a href="tel:18554122650">(1) 855 412 2650</a></div>
                          </div>
                          <div className="row">
                              <div className="col-5 col-md-12 col-lg-7 col-xl-6"><p>Reino Unido</p></div>
                              <div className="col-7 col-md-12 col-lg-5 col-xl-6 text-right"><a href="tel:08009775533">(0) 800 977 5533</a></div>
                          </div>
                          <div className="row">
                              <div className="col-5 col-md-12 col-lg-7 col-xl-6"><p>Irlanda</p></div>
                              <div className="col-7 col-md-12 col-lg-5 col-xl-6 text-right"><a href="tel:1800855474">1 800 855 474</a></div>
                          </div>
                          <div className="row">
                              <div className="col-5 col-md-12 col-lg-7 col-xl-6"><p>España</p></div>
                              <div className="col-7 col-md-12 col-lg-5 col-xl-6 text-right"><a href="tel:900995282">900 995 282</a></div>
                          </div>
                          <div className="row">
                              <div className="col-5 col-md-12 col-lg-7 col-xl-6"><p>Resto del mundo</p></div>
                              <div className="col-7 col-md-12 col-lg-5 col-xl-6 text-right"><a href="tel:80080056800">800 800 56800</a></div>
                          </div>
                      </div>
                      <div className="col-12 col-lg-3 text-md-left other-info">
                          <h6>Envíanos un correo</h6>
                          <p><a href="mailto:programa.corporativo@clubpremier.com">programa.corporativo@clubpremier.com</a></p>
                          <p>Horarios de atención de lunes a viernes, de 9:00 a 19:00 horario local de cada país, para el resto del mundo horario local de la Ciudad de México.</p>
                      </div>
                      <div className="col-12 col-lg-3 other-info">
                        <h6>Descarga nuestra app</h6>
                        <p>Descarga o actualiza la aplicación de Aeroméxico Rewards en tu dispositivo móvil y disfruta de experiencias memorables.</p>
                        <div className="row justify-content-center">
                          <div className="col-auto col-md-12 col-lg-6 text-center">
                            <p>
                              <a href="https://play.google.com/store/apps/details?id=com.clubpremier.loyalty.android">
                                <img src="https://s3media.clubpremier.com/club_premier/img/footer/google-play-badge.svg" alt="Google Play" rel="noopener nofollow" target="_blank"/>
                              </a>
                            </p>
                          </div>
                          <div className="col-auto col-md-12 col-lg-6 text-center text-lg-right">
                            <p>
                              <a href="https://itunes.apple.com/mx/app/club-premier/id592240448?mt=8">
                                <img src="https://s3media.clubpremier.com/club_premier/img/footer/app-store-badge.svg" alt="App Store" rel="noopener nofollow" target="_blank"/>
                              </a>
                            </p>
                          </div>
                        </div>
                        <h6 className="mt-40">h6 - Síguenos</h6>
                        <div className="social-buttons">
                            <a href="https://www.facebook.com/clubpremiermexico" target="_blank" class="social-link menu-item menu-item-type-custom menu-item-object-custom"><span class="fab fa-facebook-f"></span></a>
                            <a href="https://www.linkedin.com/company/premier-loyalty-&-marketing/" target="_blank" class="social-link menu-item menu-item-type-custom menu-item-object-custom"><span class="fab fa fa-linkedin"></span></a>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3 text-center text-md-left other-links">
                          <p><a href="https://corporativo.clubpremier.info/mx/corporativo/acerca-de-club-premier-corporativo?lang=es">Acerca de Aeroméxico Rewards Business</a></p>
                          <p><a href="https://corporativo.clubpremier.info/mx/corporativo/preguntas-frecuentes?lang=es">Preguntas Frecuentes</a></p>
                          <p><a href="https://corporativo.clubpremier.info/mx/corporativo/administracion?lang=es">Administración</a></p>
                          <p><a href="https://amfacturacion.aeromexico.com/">Facturación</a></p>
                          <p><a href="https://corporativo.clubpremier.info/mx/corporativo/comunicados?lang=es">Comunicados</a></p>
                      </div>
                  </div>
              </div>
          </div>
        </div>

        <div className="container-fluid legal-wrapper">
          <div className="row">
            <div className="container site-legal">
              <div className="row">
                <div className="col-12 col-md-6 text-center text-md-left"><p>© 2020 Aeroméxico Rewards. Todos los Derechos Reservados.</p></div>
                <div className="col-12 col-md-6 text-center text-md-right">
                  <p>
                    <a href="https://www.clubpremier.com/mx/acerca-de-club-premier/terminos-y-condiciones/">Términos y condiciones</a> | <a href="https://www.clubpremier.com/mx/acerca-de-club-premier/aviso-de-privacidad/">Aviso de Privacidad</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    ); 
  }
};

export default HeaderFoterContainerLogged;