import React from 'react';

class LoginCp extends React.Component {

  render() {
    return (    
      <div className="container-fluid container-login py-0">
        <div className="row d-flex flex-row-reverse">    
          <div className="col-12 col-md-6 bg-gradient container-form" >                
            <div className="card col-md-8 mt-60 mb-30">               
              <a href="/"><i className='fas fa-times redirect-icon'/></a>        
              <div className="card-body p-30">
                <div className="alert alert-danger" role="alert">
                  <div className="row row-basic pl-15">
                    <i className='fas fa-times-circle'/>
                    <h5>Información incorrecta</h5>
                    <i className="close fas fa-times" data-dismiss="alert"/>    
                  </div>
                  <div className="row row-basic pl-15 alert-text">
                    <p>El número de cuenta y/o contraseña proporcionados, son incorrectos</p>
                  </div>
                </div>
                <h4 className="card-title text-center m-o">Inicia Sesión en Aeroméxico Rewards Business</h4>
                <p className="card-text text-center">Bienvenido de nuevo, ingresa los siguientes datos:</p>
                <form className="form-corp" id="cpr-lcp-pda-frm-fcp" data-parsley-validate="" noValidate="" action="#">    
                  <div className="form-group">
                    <div id="cpr-lcp-pda-div-gan">
                      <div className="input-group input-basic floating-label py-15" >
                        
                        <input 
                        className="form-control"
                        data-parsley-errors-container="#cpr-lcp-pda-div-gan"
                        data-parsley-trigger="input"
                        placeholder="No de Cuenta Corporativo"
                        required
                        data-parsley-required-message="Campo requerido"
                        minLength="2"
                        maxLength="11"
                        data-parsley-length-message="Máximo 11 dígitos"
                        data-parsley-luhn
                        data-parsley-luhn-message="No. de cuenta inválido"
                        data-parsley-type="digits"
                        data-parsley-type-message="Sólo dígitos"
                        id="cpr-lcp-pda-inp-cpc"
                        />
                        <label htmlFor="cpr-lcp-pda-inp-cpc">No. de Cuenta Corporativo</label>
                            
                      </div>
                      <div className="input-group-append">
                          <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                          <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                        </div> 
                    </div>
                
                    <div id="cpr-lcp-pda-div-gac">
                      <div className="input-group input-basic py-15">
                        <label>No. de Cuenta Aeroméxico Rewards</label>
                        <input className="form-control"
                        data-parsley-errors-container="#cpr-lcp-pda-div-gac"
                        data-parsley-trigger="input"
                        placeholder="123456789"
                        required
                        data-parsley-required-message="Campo requerido"
                        minLength="2"
                        maxLength="11"
                        data-parsley-length-message="Máximo 11 dígitos"
                        data-parsley-luhn
                        data-parsley-luhn-message="No. de cuenta inválido"
                        data-parsley-type="digits"
                        data-parsley-type-message="Sólo dígitos"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                          <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                        </div>                   
                      </div>
                    </div>
                    <div id="cpr-lcp-pda-div-pas">
                      <div className="input-group input-basic py-15" >
                        <label>Contraseña</label>
                        <input className="form-control"
                        type="password"
                        data-parsley-errors-container="#cpr-lcp-pda-div-pas"
                        data-parsley-trigger="input"
                        required
                        data-parsley-required-message="Campo requerido"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text valid"><i className="fas fa-check-circle"></i></span>
                          <span className="input-group-text invalid"><i className="fas fa-times-circle"></i></span>
                        </div>
                      </div>
                    </div>
                    <div className="form group text-center py-30">
                      <button className="btn btn-primary next-step mt-15"
                        type="submit"
                        disabled
                        id="cpr-lcp-pda-btn-lgn"
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </form>
              <div className="text-left">
                <a href="/" id="cpr-lcp-pda-hyp-rac">Recuperar número de cuenta</a>
              </div>
              </div>
            </div>          
          </div> 
          <div className="col-12 col-md-6 mb-0 py-15">
            <article className="col-8 text-center m-center pb-30">
              <h2 className="my-30">
                Descubre Aeroméxico Rewards Business
              </h2>
              <p className="body-text pl-15 pr-15">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna 
              aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
              </p>
              <a className="btn btn-primary" href="/" id="cpr-lcp-ida-hyp-kmr">Saber más<i className="fas fa-arrow-right"/></a>   
            </article>
            <section className="section-corporativo auto">
              <div className="row grid-descubre section-corp">
                <div className="col-md-12">
                  <div className="row h-100">
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-big-stone">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo_Aeromexico.png" alt= ""/>
                      </div>
                    </div>
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-chathams-blue">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo_delta.png" alt= ""/>
                      </div>
                    </div>
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-cardinal">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo-Airfrance.png" alt= ""/>
                      </div>
                    </div>
                    <div className="col-md-6 h-md-50 p-0">
                      <div className="grid-images bg-cerulean">
                        <img className="img-fluid" src="https://corpstylo.aeromexicorewards.com/images/logo-KLM.png" alt= ""/>
                      </div>
                      
                    </div>
                  </div>
                </div>     
              </div>   
            </section>  
          </div>
        </div> 
        <div className="modal info modal-login-as-corporate" id="cpr-lcp-pda-mdl-lac" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-modal="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="cpr-lcp-pda-mdl-mlt">Número de Cuenta o Contraseña incorrectos
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <i className="fas fa-times"></i>
                      </button>
                    </h5>
                </div>
                <div className="modal-body">
                  <p>¡Lo sentimos! Excediste el número máximo de intentos permitidos. Tu cuenta ha sido bloqueada por 20 minutos, por favor inténtalo más tarde</p>
                </div>
              </div>
          </div>
        </div>     
      </div>    
    );
  }
}
export default LoginCp;